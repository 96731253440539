import { allPieceTypes, directions } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class GoatMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes.filter(
    (pieceType) => pieceType !== PieceType.Bear && pieceType !== PieceType.Wolf
  );

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    if (piece.isFainted) {
      return this.getValidFaintedMoves(piece, position, boardState);
    }

    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    const traverseMoves = this.getValidTraverseMoves(
      piece,
      position,
      boardState
    );
    const faintMove = this.getValidFaintMove(piece, position, boardState);

    console.log("Regular Moves:", regularMoves);
    console.log("Traverse Moves:", traverseMoves);
    console.log("Faint Move:", faintMove);

    // Filter out traverse moves that fall on the same squares as regular moves
    const filteredTraverseMoves = traverseMoves.filter(
      (traverseMove) =>
        !regularMoves.some(
          (regularMove) =>
            regularMove.position.col === traverseMove.position.col &&
            regularMove.position.row === traverseMove.position.row
        )
    );

    // Concatenate the arrays in the desired order
    return [...regularMoves, ...filteredTraverseMoves, ...faintMove];
  }

  private getValidRegularMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const newPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, newPosition)) {
        continue;
      }

      const newSquare = boardState[newPosition.row][newPosition.col];

      // Check if the target square has multiple pieces (potentially a tree housing another piece)
      if (newSquare.Pieces.length > 1) {
        // Check if one of the pieces is a tree
        const hasTree = newSquare.Pieces.some((p) => p.type === PieceType.Tree);

        if (hasTree) {
          // Find the non-tree piece in the square
          const housedPiece = newSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );

          if (housedPiece) {
            if (housedPiece.isLight === piece.isLight) {
              // If the housed piece is friendly, skip this move
              continue;
            } else if (this.capturablePieceTypes.includes(housedPiece.type)) {
              // If the housed piece is an enemy and capturable by goat, create a capture move
              possibleMoves.push(
                new Move(newPosition, MoveType.capture, housedPiece)
              );
              continue;
            } else {
              // If the housed piece is not capturable by goat (like a bear), skip
              continue;
            }
          }
        }
      }

      const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          newPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private getValidTraverseMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const validMoves: IMove[] = [];
    const visited: Set<string> = new Set();

    const isTraversablePiece = (p: IPiece | null): boolean => {
      return (
        p !== null &&
        !(p.type === PieceType.Goat && p.isFainted) &&
        p.type !== PieceType.Virus
      );
    };

    const traverseHelper = (currentPosition: Position): void => {
      const key = `${currentPosition.col},${currentPosition.row}`;
      if (visited.has(key)) return;
      visited.add(key);

      for (const direction of directions) {
        const newPosition = {
          col: currentPosition.col + direction[0],
          row: currentPosition.row + direction[1],
        };
        if (!this.onBoard(boardState, newPosition)) {
          continue;
        }

        const newSquare = boardState[newPosition.row][newPosition.col];

        // Check if the target square has multiple pieces (potentially a tree housing another piece)
        if (newSquare.Pieces.length > 1) {
          // Check if one of the pieces is a tree
          const hasTree = newSquare.Pieces.some(
            (p) => p.type === PieceType.Tree
          );

          if (hasTree) {
            // Find the non-tree piece in the square
            const housedPiece = newSquare.Pieces.find(
              (p) => p.type !== PieceType.Tree
            );

            if (housedPiece) {
              // For traversal, allow traversing through trees with pieces (but don't add as valid move)
              // This lets the goat use trees with pieces as stepping stones without landing on them
              traverseHelper(newPosition);
              // Skip adding this as a valid landing point
              continue;
            }
          }
        }

        const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

        if (squareInfo.basicSquareInfo) {
          if (squareInfo.basicSquareInfo.isEmpty) {
            validMoves.push(
              new Move(
                newPosition,
                MoveType.traverse,
                newSquare.Pieces[0] || null
              )
            );
          } else if (
            squareInfo.basicSquareInfo.piece?.type === PieceType.Tree &&
            // Only allow traversing to empty trees or friendly trees as actual moves
            squareInfo.basicSquareInfo.isAlly
          ) {
            // Allow traversing onto a Tree square and continue traversing from it
            validMoves.push(
              new Move(
                newPosition,
                MoveType.house,
                squareInfo.basicSquareInfo.piece
              )
            );
            traverseHelper(newPosition);
          } else if (isTraversablePiece(squareInfo.basicSquareInfo.piece)) {
            // Continue traversal from this square but don't add as a valid move
            traverseHelper(newPosition);
          }
        }
      }
    };

    traverseHelper(position);

    return validMoves;
  }

  private getValidFaintMove(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const currentSquare = boardState[position.row][position.col];
    const squareInfo = this.getSquareInfo(currentSquare, piece.isLight);

    console.log("Inside getValidFaintMove");
    console.log("Piece:", piece);
    console.log("Position:", position);
    console.log("Square Info:", squareInfo);

    if (piece.isFainted) {
      console.log("Goat is already fainted, returning empty array");
      return []; // Goat cannot faint if already fainted
    }

    const faintMove: IMove = new Move(position, MoveType.faint, null);
    console.log("Faint Move:", faintMove);

    return [faintMove]; // Goat can faint in its current position
  }

  private getValidFaintedMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    //const currentSquare = boardState[position.row][position.col];
    //const squareInfo = this.getSquareInfo(currentSquare, piece.isLight);

    // Check if there is another piece on top of the fainted Goat
    //if (squareInfo.stoodGoatSquareInfo?.piece !== piece) {
    //  return []; // Goat cannot move when another piece is on top of it
    //}

    // If no piece is on top of the fainted Goat, allow it to make a regular move or traverse move
    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    const traverseMoves = this.getValidTraverseMoves(
      piece,
      position,
      boardState
    );

    return [...regularMoves, ...traverseMoves];
  }
}
