import { HeuristicType } from "../zodiacEngine/heuristics/HeuristicInterface";

// Local storage keys
const STORAGE_KEY_PREFIX = "zodiac_options_";
const THINKING_MODE_KEY = `${STORAGE_KEY_PREFIX}thinking_mode`;
const HEURISTIC_TYPE_KEY = `${STORAGE_KEY_PREFIX}heuristic_type`;

// Type definition for thinking mode
export type ThinkingMode = "Fast" | "Normal" | "Deep";

/**
 * Default options values
 */
const DEFAULT_OPTIONS = {
  thinkingMode: "Fast" as ThinkingMode,
  heuristicType: HeuristicType.Random,
};

/**
 * Service for managing persistent game options
 */
export class OptionsService {
  /**
   * Save thinking mode to local storage
   * @param mode The thinking mode to save
   */
  public static saveThinkingMode(mode: ThinkingMode): void {
    localStorage.setItem(THINKING_MODE_KEY, mode);
  }

  /**
   * Load thinking mode from local storage
   * @returns The saved thinking mode or default
   */
  public static loadThinkingMode(): ThinkingMode {
    const savedMode = localStorage.getItem(
      THINKING_MODE_KEY
    ) as ThinkingMode | null;
    return savedMode || DEFAULT_OPTIONS.thinkingMode;
  }

  /**
   * Save heuristic type to local storage
   * @param heuristicType The heuristic type to save
   */
  public static saveHeuristicType(heuristicType: HeuristicType): void {
    localStorage.setItem(HEURISTIC_TYPE_KEY, heuristicType);
  }

  /**
   * Load heuristic type from local storage
   * @returns The saved heuristic type or default
   */
  public static loadHeuristicType(): HeuristicType {
    const savedHeuristic = localStorage.getItem(
      HEURISTIC_TYPE_KEY
    ) as HeuristicType | null;
    return savedHeuristic || DEFAULT_OPTIONS.heuristicType;
  }
}

// Export a singleton instance
export default OptionsService;
