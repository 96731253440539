import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GameState,
  IMove,
  IPiece,
  ISideEffect,
  Mode,
  Position,
  State,
} from "../../services/zodiacEngine/types";
import ZodiacEngineService from "../ZodiacEngineService";
import { AuthContext } from "./AuthContext";

interface SelectedPiece {
  piece: IPiece;
  position: Position;
}

export interface GameContextType {
  gameState: GameState;
  updateGameState: (gameState: GameState) => void;

  selectedPiece?: SelectedPiece;
  updateSelectedPiece: (selectedPiece?: SelectedPiece) => void;

  potentialMoves: IMove[];
  updatePotentialMoves: (potentialMoves: IMove[]) => void;

  potentialSideEffects: ISideEffect[];
  // there is not update for this as it is derived from potentialMoves

  isThisPlayersTurn: () => boolean;
  isLightPlayer: () => boolean;

  // AI States
  isAIThinking: boolean;
  isNNTestMode: boolean;
  setIsAIThinking: (isThinking: boolean) => void;
  setIsNNTestMode: (isNNTestMode: boolean) => void;
}

export const GameContext = createContext<GameContextType>({
  gameState: ZodiacEngineService.zodiacEngine.getGameState(),
  selectedPiece: undefined,
  potentialMoves: [],
  potentialSideEffects: [],
  isLightPlayer: () => true,
  isThisPlayersTurn: () => true,
  isAIThinking: false,
  isNNTestMode: false,
  updateGameState: () => {},
  updateSelectedPiece: () => {},
  updatePotentialMoves: () => {},
  setIsAIThinking: () => {},
  setIsNNTestMode: () => {},
});

interface GameContextProviderProps {
  children: ReactNode;
}

export function GameContextProvider(props: GameContextProviderProps) {
  const [gameState, setGameState] = useState<GameState>(
    ZodiacEngineService.zodiacEngine.getGameState()
  );
  const [selectedPiece, setSelectedPiece] = useState<SelectedPiece | undefined>(
    undefined
  );
  const [potentialMoves, setPotentialMoves] = useState<IMove[]>([]);
  const [potentialSideEffects, setPotentialSideEffects] = useState<
    ISideEffect[]
  >([]);

  const [isAIThinking, setIsAIThinking] = useState<boolean>(false);
  const [isNNTestMode, setIsNNTestMode] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  // Game state tracking for continuous AI play
  const [previousTurn, setPreviousTurn] = useState<boolean | null>(null);
  const [retryCount, setRetryCount] = useState<number>(0);
  const MAX_RETRIES = 3;

  useEffect(() => {
    // Game state has been updated; register a listener
    const updateUiState = () => {
      // Get the current game state when notified of a change
      setGameState(ZodiacEngineService.zodiacEngine.getGameState());
    };

    // Subscribe to game state changes
    ZodiacEngineService.zodiacEngine.subscribeToGameStateChanges(updateUiState);

    // Cleanup subscription on unmount
    return () => {
      ZodiacEngineService.zodiacEngine.unsubscribeToGameStateChanges(
        updateUiState
      );
    };
  }, []);

  // Add effect to detect game end and auto-restart for NN Test mode
  useEffect(() => {
    // Check if game has ended and we're in NN test mode
    if (
      isNNTestMode &&
      gameState.mode === Mode.AI &&
      gameState.state === State.PostGame
    ) {
      console.log("Game ended in NN test mode, auto-restarting...");
      // Wait a short moment to see the result, then restart
      setTimeout(() => {
        // Reset game and start a new NN test
        ZodiacEngineService.zodiacEngine.resetAndStartNewNNTest();
        // Update game state after resetting
        setGameState(ZodiacEngineService.zodiacEngine.getGameState());
        // Reset previous turn tracking
        setPreviousTurn(null);
        // Reset retry counter
        setRetryCount(0);
      }, 100); // 100ms for near-instant restart
    }
  }, [gameState.state, gameState.mode, isNNTestMode]);

  // Effect to detect when we're stuck in a retry loop and force restart
  useEffect(() => {
    if (retryCount >= MAX_RETRIES && isNNTestMode) {
      console.log(
        `GameContext: Hit max retries (${MAX_RETRIES}), forcing game restart`
      );
      // Force reset the game
      ZodiacEngineService.zodiacEngine.resetAndStartNewNNTest();
      // Update game state after resetting
      setGameState(ZodiacEngineService.zodiacEngine.getGameState());
      // Reset previous turn tracking
      setPreviousTurn(null);
      // Reset retry counter
      setRetryCount(0);
    }
  }, [retryCount, isNNTestMode]);

  // Effect to detect turn changes and make AI moves when needed - NN TEST MODE ONLY
  useEffect(() => {
    // Skip if not in NN test mode
    if (!isNNTestMode) {
      return;
    }

    // Only run in Playing state with AI mode
    if (gameState.state !== State.Playing || gameState.mode !== Mode.AI) {
      return;
    }

    // Current turn - true for Light, false for Dark
    const currentTurn = gameState.isLightTurn;

    // For logging
    const turnColor = currentTurn ? "Light" : "Dark";
    
    console.log(
      `GameContext: Turn changed - ${turnColor}'s turn (NN Test mode)`
    );
    console.log(
      `GameContext: Current retry count: ${retryCount}/${MAX_RETRIES}`
    );

    // In NN Test mode, AI plays both sides
    const shouldAIMakeMove =
      !isAIThinking && // Not already thinking
      (previousTurn !== currentTurn || retryCount > 0); // Turn has changed OR we're retrying

    // Log decision factors
    console.log(
      `GameContext: AI thinking: ${isAIThinking}, Previous turn: ${
        previousTurn === null ? "null" : previousTurn ? "Light" : "Dark"
      }`
    );
    console.log(`GameContext: Should AI make a move? ${shouldAIMakeMove}`);

    // Make an AI move if needed
    if (shouldAIMakeMove) {
      console.log(
        `GameContext: Making AI move as ${turnColor} player... (Attempt ${
          retryCount + 1
        })`
      );

      // Set AI thinking state
      setIsAIThinking(true);

      // Execute move after a small delay to ensure UI state is updated
      setTimeout(async () => {
        try {
          console.log(`GameContext: Executing ${turnColor} AI move`);
          const moveResult =
            await ZodiacEngineService.zodiacEngine.makeAIMove();
          console.log(
            `GameContext: AI move result: ${moveResult ? "SUCCESS" : "FAILED"}`
          );

          // Only update previous turn if the move was successful
          if (moveResult) {
            setPreviousTurn(currentTurn);
            setRetryCount(0); // Reset retry counter on success
          } else {
            // If move failed, increment retry counter
            const newRetryCount = retryCount + 1;
            setRetryCount(newRetryCount);
            console.log(
              `GameContext: Move failed, retry count now ${newRetryCount}/${MAX_RETRIES}`
            );

            // If we haven't hit max retries yet, set up for retry
            if (newRetryCount < MAX_RETRIES) {
              console.log(`GameContext: Will retry soon...`);
              // No need to explicitly retry - the useEffect will trigger again due to retryCount change
            }
          }
        } catch (error) {
          console.error("Error during AI move:", error);
          // Also increment retry on error
          setRetryCount(retryCount + 1);
        } finally {
          // Clear AI thinking state
          setIsAIThinking(false);
          console.log("GameContext: AI thinking state cleared");
        }
      }, 10); // Small delay for UI updates
    }
  }, [
    gameState.isLightTurn,
    gameState.mode,
    gameState.state,
    isAIThinking,
    previousTurn,
    isNNTestMode,
    retryCount,
  ]);

  // NEW EFFECT: Play vs AI mode - Only handles dark moves
  useEffect(() => {
    // Skip if in NN test mode (handled by other effect)
    if (isNNTestMode) {
      console.log("GameContext (AI vs Human): Skipping - in NN test mode");
      return;
    }

    // Only run in Playing state with AI mode
    if (gameState.state !== State.Playing || gameState.mode !== Mode.AI) {
      console.log(`GameContext (AI vs Human): Skipping - not in AI mode or Playing state. Mode: ${gameState.mode}, State: ${gameState.state}`);
      return;
    }

    // Create a timer to periodically check for AI turn when we're in AI mode
    const aiCheckIntervalId = setInterval(() => {
      // Only attempt to make move if it's dark's turn and AI isn't already thinking
      if (!gameState.isLightTurn && !isAIThinking) {
        console.log(`GameContext (AI vs Human): Dark's turn detected by interval - initiating AI move`);
        
        // Set AI thinking state
        setIsAIThinking(true);
        
        // Execute dark's move
        setTimeout(async () => {
          try {
            console.log(`GameContext (AI vs Human): Executing Dark AI move`);
            const moveResult = await ZodiacEngineService.zodiacEngine.makeAIMove();
            console.log(
              `GameContext (AI vs Human): AI move result: ${moveResult ? "SUCCESS" : "FAILED"}`
            );
            
            if (!moveResult) {
              console.log("GameContext (AI vs Human): AI move failed - will retry on next interval");
            }
          } catch (error) {
            console.error("GameContext (AI vs Human): Error during AI move:", error);
          } finally {
            // Clear AI thinking state
            setIsAIThinking(false);
            console.log("GameContext (AI vs Human): AI thinking state cleared");
          }
        }, 10);
      }
    }, 500); // Check every 500ms
    
    // Also respond to immediate turn changes
    if (!gameState.isLightTurn && !isAIThinking) {
      console.log(`GameContext (AI vs Human): Dark's turn in Play vs AI mode - AI will make a move`);
      console.log(`GameContext (AI vs Human): Current turn: ${gameState.turnCount}`);

      // Set AI thinking state
      setIsAIThinking(true);

      // Execute dark's move after a small delay
      setTimeout(async () => {
        try {
          console.log(`GameContext (AI vs Human): Executing Dark AI move`);
          const moveResult = await ZodiacEngineService.zodiacEngine.makeAIMove();
          console.log(
            `GameContext (AI vs Human): AI move result: ${moveResult ? "SUCCESS" : "FAILED"}`
          );
          
          if (!moveResult) {
            console.log("GameContext (AI vs Human): AI move failed - will try again when state changes");
          }
        } catch (error) {
          console.error("GameContext (AI vs Human): Error during AI move:", error);
        } finally {
          // Clear AI thinking state
          setIsAIThinking(false);
          console.log("GameContext (AI vs Human): AI thinking state cleared");
        }
      }, 10); // Small delay for UI updates
    }
    
    // Clean up the interval when component unmounts or dependencies change
    return () => {
      clearInterval(aiCheckIntervalId);
      console.log("GameContext (AI vs Human): Cleared AI check interval");
    };
  }, [
    gameState.isLightTurn,
    gameState.mode,
    gameState.state,
    gameState.turnCount,
    isAIThinking,
    isNNTestMode,
  ]);

  // Method should look through all of the potential moves, find any which contain side effects that are not
  // in the same square as the move, make a list of them and return them
  function findSideEffectsNotInMoves(moves: IMove[]): ISideEffect[] {
    var sideEffectsNotInMoves: ISideEffect[] = [];
    moves.forEach((move) => {
      move.sideEffects.forEach((sideEffect) => {
        if (
          sideEffect.position.col !== move.position.col ||
          sideEffect.position.row !== move.position.row
        ) {
          sideEffectsNotInMoves.push(sideEffect);
        }
      });
    });
    return sideEffectsNotInMoves;
  }

  const isThisPlayersTurn = (): boolean => {
    if (gameState.mode === Mode.Dev) return true;
    // In AI mode, the player is always the light player
    if (gameState.mode === Mode.AI) return gameState.isLightTurn;
    return (
      user!.uid ===
      (gameState.isLightTurn ? gameState.lightPlayer : gameState.darkPlayer)
    );
  };

  const isLightPlayer = (): boolean => {
    if (gameState.mode === Mode.Dev) return true;
    // In AI mode, the player is always the light player
    if (gameState.mode === Mode.AI) return true;
    return user!.uid === gameState.lightPlayer;
  };

  return (
    <GameContext.Provider
      value={{
        gameState,
        selectedPiece,
        potentialMoves,
        potentialSideEffects,
        isAIThinking,
        isNNTestMode,
        isLightPlayer,
        isThisPlayersTurn,
        updateGameState: (newState: GameState) => {
          setGameState(newState);
        },
        updateSelectedPiece: (newSelectedPiece: SelectedPiece | undefined) => {
          setSelectedPiece(newSelectedPiece);
        },
        updatePotentialMoves: (newMoves: IMove[]) => {
          setPotentialMoves(newMoves);
          setPotentialSideEffects(findSideEffectsNotInMoves(newMoves));
        },
        setIsAIThinking: (isThinking: boolean) => {
          setIsAIThinking(isThinking);
        },
        setIsNNTestMode: (isNNTest: boolean) => {
          setIsNNTestMode(isNNTest);
        },
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
}
