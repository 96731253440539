import { allPieceTypes } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  IMove,
  PieceType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class WolfMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    const isLone = this.isLoneWolf(piece, boardState);

    const directions = isLone
      ? [
          { col: -1, row: -3 }, // Up-Left
          { col: 1, row: -3 }, // Up-Right
          { col: -1, row: 3 }, // Down-Left
          { col: 1, row: 3 }, // Down-Right
          { col: -3, row: -1 }, // Left-Up
          { col: -3, row: 1 }, // Left-Down
          { col: 3, row: -1 }, // Right-Up
          { col: 3, row: 1 }, // Right-Down
        ]
      : [
          { col: -1, row: -2 }, // Up-Left
          { col: 1, row: -2 }, // Up-Right
          { col: -1, row: 2 }, // Down-Left
          { col: 1, row: 2 }, // Down-Right
          { col: -2, row: -1 }, // Left-Up
          { col: -2, row: 1 }, // Left-Down
          { col: 2, row: -1 }, // Right-Up
          { col: 2, row: 1 }, // Right-Down
        ];

    for (const direction of directions) {
      const toPosition = {
        col: position.col + direction.col,
        row: position.row + direction.row,
      };

      if (!this.onBoard(boardState, toPosition)) continue;

      const toSquare = boardState[toPosition.row][toPosition.col];

      // Wolves can't climb trees at all, so check and skip any tree squares
      if (toSquare.Pieces.some((p) => p.type === PieceType.Tree)) {
        continue;
      }

      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          toPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private isLoneWolf(piece: IPiece, boardState: ISquare[][]): boolean {
    const otherPieces: IPiece[] = boardState.flatMap((row) =>
      row.flatMap((square) =>
        square.Pieces.filter((boardPiece) => boardPiece !== piece)
      )
    );

    const allyNonFaintedPieceOnBoard = otherPieces.some(
      (boardPiece) =>
        boardPiece.isLight === piece.isLight && // <-- Compare equality
        !boardPiece.isFainted
    );

    const allyNotVirus = otherPieces.some(
      (boardPiece) =>
        boardPiece.isLight === piece.isLight && // <-- Compare equality
        boardPiece.type !== PieceType.Virus
    );

    if (allyNonFaintedPieceOnBoard && allyNotVirus) {
      return false;
    }

    return true;
  }
}
