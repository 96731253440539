import { allPieceTypes } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  PieceType,
  Position,
  IMove,
  ISquare,
  Effect,
  MoveType,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class TreeMovement extends BaseMovement implements PieceMovement {
  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][] // Ensure to return IMove[] or undefined from all paths
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    if (piece.isMeadowed) {
      return possibleMoves; // If the tree is meadowed, it cannot complete any moves
    }

    console.log("piece =", piece);
    console.log("basic info", this.getBasicInfo);
    console.log(
      `Number of pieces on the square: ${
        boardState[position.row][position.col].Pieces.length
      }`
    );

    // Regular movement 1: One square forward
    const forwardDirection = piece.isLight ? 1 : -1;
    const forwardPosition = {
      col: position.col,
      row: position.row + forwardDirection,
    };
    if (
      this.onBoard(boardState, forwardPosition) &&
      !this.fungiMoveLimit(forwardPosition, boardState, piece.isLight)
    ) {
      const toSquare = boardState[forwardPosition.row][forwardPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          forwardPosition,
          squareInfo.basicSquareInfo,
          piece,
          allPieceTypes
        );

        if (move) {
          if (boardState[position.row][position.col].Pieces.length === 2) {
            // Retrieve both pieces from the square
            const piecesToMove = boardState[position.row][position.col].Pieces;

            // Add the side effect with both pieces
            move.addSideEffect(forwardPosition, piecesToMove, Effect.housemove);
          }
          possibleMoves.push(move);
        }
      }
    }

    // Regular movement 2: Left or right as far as the player chooses
    const leftPosition = { col: position.col - 1, row: position.row };
    const rightPosition = { col: position.col + 1, row: position.row };

    let currentLeftPosition = leftPosition;
    while (
      this.onBoard(boardState, currentLeftPosition) &&
      !this.fungiMoveLimit(currentLeftPosition, boardState, piece.isLight)
    ) {
      const toSquare =
        boardState[currentLeftPosition.row][currentLeftPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        // Check if there's a piece on the square
        const pieceOnSquare = toSquare.Pieces.length > 0;

        // Only create a move if the square is empty or if it's an enemy piece
        if (!pieceOnSquare || squareInfo.basicSquareInfo.isEnemyOccupied) {
          // Use getMove to determine the correct move type (regular, capture, etc.)
          const move = this.getMove(
            position,
            currentLeftPosition,
            squareInfo.basicSquareInfo,
            piece,
            allPieceTypes
          );

          if (move) {
            // Retrieve all pieces from the current square
            const piecesToMove = boardState[position.row][position.col].Pieces;

            // Add the side effect with all pieces
            move.addSideEffect(
              currentLeftPosition,
              piecesToMove,
              Effect.housemove
            );
            possibleMoves.push(move);
          }
        }

        // If there's any piece on the square (capturable or not), stop further movement
        if (pieceOnSquare) {
          break;
        }
      }

      currentLeftPosition = {
        col: currentLeftPosition.col - 1,
        row: position.row,
      };
    }

    let currentRightPosition = rightPosition;
    while (
      this.onBoard(boardState, currentRightPosition) &&
      !this.fungiMoveLimit(currentRightPosition, boardState, piece.isLight)
    ) {
      const toSquare =
        boardState[currentRightPosition.row][currentRightPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        // Check if there's a piece on the square
        const pieceOnSquare = toSquare.Pieces.length > 0;

        // Only create a move if the square is empty or if it's an enemy piece
        if (!pieceOnSquare || squareInfo.basicSquareInfo.isEnemyOccupied) {
          // Use getMove to determine the correct move type (regular, capture, etc.)
          const move = this.getMove(
            position,
            currentRightPosition,
            squareInfo.basicSquareInfo,
            piece,
            allPieceTypes
          );

          if (move) {
            // Retrieve all pieces from the current square
            const piecesToMove = boardState[position.row][position.col].Pieces;

            // Add the side effect with all pieces
            move.addSideEffect(
              currentRightPosition,
              piecesToMove,
              Effect.housemove
            );
            possibleMoves.push(move);
          }
        }

        // If there's any piece on the square (capturable or not), stop further movement
        if (pieceOnSquare) {
          break;
        }
      }

      currentRightPosition = {
        col: currentRightPosition.col + 1,
        row: position.row,
      };
    }

    // Ensure to return possibleMoves at the end
    return possibleMoves;
  }

  private fungiMoveLimit(
    position: Position,
    boardState: ISquare[][],
    isLight: boolean
  ): boolean {
    for (let row = position.row - 1; row <= position.row + 1; row++) {
      for (let col = position.col - 1; col <= position.col + 1; col++) {
        if (this.onBoard(boardState, { row, col })) {
          const square = boardState[row][col];
          const piece = square.Pieces[0];
          if (
            piece &&
            piece.type === PieceType.Fungi &&
            piece.isLight !== isLight
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
