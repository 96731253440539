import React from 'react';
import { AIStats as AIStatsType } from '../services/zodiacEngine/AIController';

interface AIStatsProps {
  aiStats: AIStatsType | null;
}

/**
 * Component to display AI performance statistics
 */
const AIStats: React.FC<AIStatsProps> = ({ aiStats }) => {
  if (!aiStats) {
    return <div className="ai-stats">No AI statistics available</div>;
  }

  // Convert Map to array of [depth, count] entries for rendering
  const depthEntries = Array.from(aiStats.nodesByDepth.entries())
    .sort((a, b) => a[0] - b[0]); // Sort by depth

  // Calculate the max depth reached
  const maxDepth = depthEntries.length > 0 ? depthEntries[depthEntries.length - 1][0] : 0;

  // Get the deepest few entries where there are significant nodes
  const significantDepthEntries = depthEntries.filter(([_, count]) => count > 10);
  const deepestSignificantDepth = significantDepthEntries.length > 0 ? 
    significantDepthEntries[significantDepthEntries.length - 1][0] : 0;

  return (
    <div className="ai-stats" style={{ fontSize: '0.8rem', marginTop: '10px' }}>
      <h3>AI Statistics</h3>
      
      <div>
        <strong>Time:</strong> {aiStats.executionTimeMs}ms
      </div>
      <div>
        <strong>Total Nodes:</strong> {aiStats.totalNodesVisited.toLocaleString()}
      </div>
      <div>
        <strong>Max Depth:</strong> {maxDepth} (Significant: {deepestSignificantDepth})
      </div>
      <div>
        <strong>Heuristic:</strong> {aiStats.heuristicUsed || "None"}
      </div>
      
      <div style={{ marginTop: '5px' }}>
        <strong>Nodes by Depth:</strong>
        <div style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ddd', padding: '5px' }}>
          <table style={{ width: '100%', fontSize: '0.7rem', borderCollapse: 'collapse' }}>
            <thead style={{ position: 'sticky', top: 0, background: '#f5f5f5' }}>
              <tr>
                <th style={{ padding: '3px', borderBottom: '1px solid #ccc' }}>Depth</th>
                <th style={{ padding: '3px', borderBottom: '1px solid #ccc' }}>Nodes</th>
                <th style={{ padding: '3px', borderBottom: '1px solid #ccc' }}>%</th>
                <th style={{ padding: '3px', borderBottom: '1px solid #ccc' }}>Distribution</th>
              </tr>
            </thead>
            <tbody>
              {depthEntries.map(([depth, count]) => {
                const percentage = aiStats.totalNodesVisited 
                  ? (count / aiStats.totalNodesVisited) * 100
                  : 0;
                
                // Color gradient - deeper levels get a more intense color
                const depthColor = depth < 3 ? '#e6f7ff' : 
                                 depth < 5 ? '#ccefff' : 
                                 depth < 7 ? '#99dfff' : 
                                 depth < 9 ? '#66cfff' : '#33bfff';
                
                return (
                  <tr key={depth} style={{ 
                    backgroundColor: count > 0 ? depthColor : 'inherit',
                    fontWeight: depth > 5 ? 'bold' : 'normal'
                  }}>
                    <td style={{ padding: '3px', borderBottom: '1px solid #eee' }}>{depth}</td>
                    <td style={{ padding: '3px', borderBottom: '1px solid #eee' }}>{count.toLocaleString()}</td>
                    <td style={{ padding: '3px', borderBottom: '1px solid #eee' }}>
                      {percentage.toFixed(1)}%
                    </td>
                    <td style={{ padding: '3px', borderBottom: '1px solid #eee' }}>
                      <div style={{ 
                        width: `${Math.max(percentage * 2, 2)}%`, 
                        height: '8px', 
                        backgroundColor: '#007bff',
                        borderRadius: '2px'
                      }} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AIStats; 