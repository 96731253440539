import { Square as GameSquare } from "../services/zodiacEngine/Square";
import React, { useContext } from "react";
import styles from "./Square.module.css";
import globalStyles from "../App.module.css";
import {
  IMove,
  IPiece,
  ISideEffect,
  ISquare,
  Mode,
  MoveType,
  Position,
} from "../services/zodiacEngine/types";
import SquareRenderer from "./SquareRenderer"; // Import the renderer
import ZodiacEngineService from "./ZodiacEngineService";
import { GameContext } from "./Providers/GameContext";

interface SquareProps {
  square: GameSquare;
  position: Position;
  move?: IMove;
  moves: IMove[];
  sideEffects: ISideEffect[];
  //highlightedSquares?: Position[];
  //onHover: (move?: IMove) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>, position: Position) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
}

function Square(squareProps: SquareProps) {
  const {
    gameState,
    selectedPiece,
    isThisPlayersTurn,
    updatePotentialMoves,
    updateSelectedPiece,
    isLightPlayer,
    isNNTestMode,
  } = useContext(GameContext)!;

  const handleMultiPieceClick = (piece: IPiece) => {
    squareClickHandler(
      squareProps.square,
      squareProps.position,
      squareProps.move,
      piece
    );
  };

  const handleSinglePieceClick = () => {
    if (squareProps.square.Pieces.length < 2) {
      squareClickHandler(
        squareProps.square,
        squareProps.position,
        squareProps.move,
        squareProps.square.Pieces[0]
      );
    }
    // else do nothing, there is a bug to fix here, but it currently doesn't cause issues
  };

  function squareClickHandler(
    square: ISquare,
    position: Position,
    move?: IMove,
    pieceClicked?: IPiece
  ) {
    if (move) {
      movePiece(move);
      return;
    }

    if (square.Pieces.length === 0) {
      deselectPiece();
      return;
    }

    if (!pieceClicked) return;

    getValidMoves(position, pieceClicked);
  }

  function getValidMoves(position: Position, pieceClicked: IPiece) {
    updateSelectedPiece({ piece: pieceClicked, position: position });
    updatePotentialMoves(
      ZodiacEngineService.zodiacEngine.getValidMoves(position, pieceClicked)
    );
  }

  function deselectPiece() {
    updatePotentialMoves([]);
    updateSelectedPiece(undefined);
  }

  function movePiece(move: IMove) {
    const moveSuccessful = ZodiacEngineService.zodiacEngine.makeMove(
      selectedPiece!.position,
      move.position,
      selectedPiece!.piece,
      move.toPiece,
      move.moveType,
      move.sideEffects
    );
    if (moveSuccessful) {
      updatePotentialMoves([]);
    } else {
      console.log(`Attempted move: ${move}`);
      throw new Error(`Move failed, move is likely invalid`);
    }
  }

  function isClickable(): boolean {
    // Block all interaction in NN test mode
    if (gameState.mode === Mode.AI && isNNTestMode) return false;

    if (gameState.mode === Mode.Dev) return true;

    const isMoveSquare: boolean = squareProps.move !== undefined;
    const isThisPlayersPiece =
      squareProps.square.Pieces.length === 1 &&
      squareProps.square.Pieces[0].isLight === isLightPlayer();
    const isEmpty = squareProps.square.Pieces.length === 0;

    // Make empty squares clickable if there's a selected piece (to allow deselection)
    return (
      (isThisPlayersTurn() && (isMoveSquare || isThisPlayersPiece)) ||
      (isEmpty && selectedPiece !== undefined)
    );
  }

  const getStyle = (): string => {
    let style = globalStyles.square;

    // Apply style if the square is in highlightedSquares and move is not splitentangle
    // if (
    //   squareProps.move?.moveType !== MoveType.splitentangle &&
    //   squareProps.highlightedSquares &&
    //   squareProps.highlightedSquares.some(
    //     (pos) =>
    //       pos.row === squareProps.position.row &&
    //       pos.col === squareProps.position.col
    //   )
    // ) {
    //   style += ` ${styles.highlightedSideEffect}`;
    // }

    const move = squareProps.move;

    if (!move) return style; // No move, return default style

    // Handle different move types
    switch (move.moveType) {
      case MoveType.trample:
        if (squareProps.square.Pieces.length === 0) {
          // Empty square, apply trampleEmpty (green)
          style += ` ${styles.potentialMoveTrampleEmpty}`;
        } else {
          // Occupied square, apply capture highlight for trample (red)
          style += ` ${styles.potentialMoveTrample} ${styles.potentialMoveCapture}`;
        }
        break;
      case MoveType.capture:
        // Regular capture, apply capture highlight (red)
        style += ` ${styles.potentialMoveCapture}`;
        break;
      case MoveType.regular:
        // Regular move, apply regular highlight (green)
        style += ` ${styles.potentialMoveRegular}`;
        break;
      case MoveType.leap:
        // Regular move, apply regular highlight (green)
        style += ` ${styles.potentialMoveRegular}`;
        break;
      case MoveType.faint:
        // Faint move, apply faint highlight (custom style)
        style += ` ${styles.potentialMoveFaint}`;
        break;
      case MoveType.lane:
        // Lane move, apply lane highlight (grey)
        style += ` ${styles.potentialMoveLane}`;
        break;
      case MoveType.house:
        // House move, apply house highlight
        style += ` ${styles.potentialMoveHouse}`;
        break;
      case MoveType.infect:
        // Infect move, apply infect highlight
        style += ` ${styles.potentialMoveInfect}`;
        break;
      case MoveType.traverse:
        // Traverse move, apply traverse highlight (yellow)
        style += ` ${styles.potentialMoveTraverse}`;
        break;
      //case MoveType.simpleentangle:
      case MoveType.moveentangle:
      case MoveType.splitentangle:
        // Entangle move, apply entangle highlight (purple)
        style += ` ${styles.potentialMoveEntangle}`;
        break;
      default:
        // No specific move type, return default style
        return style;
    }

    return style;
  };

  return (
    <div
      className={getStyle()}
      onClick={isClickable() ? handleSinglePieceClick : undefined}
      onDrop={(event) => squareProps.onDrop(event, squareProps.position)}
      onDragOver={squareProps.onDragOver}
      //onMouseEnter={() => squareProps.onHover(squareProps.move)}
      //onMouseLeave={() => squareProps.onHover(undefined)}
    >
      {/* {squareProps.sideEffects.map((sideEffect) => (
        <div>{sideEffect.effect.toString()}</div>
      ))} */}
      <SquareRenderer
        pieces={squareProps.square.Pieces}
        handleSelect={handleMultiPieceClick}
      />
    </div>
  );
}

export default Square;
