import { ZodiacEngine } from "./zodiacEngine";
import { MCTS } from "./MCTS";
import { IMove, Position } from "./types";
import { HeuristicInterface, HeuristicType, getHeuristic } from "./heuristics";

/**
 * Interface for AI performance statistics
 */
export interface AIStats {
  nodesByDepth: Map<number, number>;
  totalNodesVisited: number;
  executionTimeMs: number;
  heuristicUsed: string;
}

/**
 * Provides AI functionality to the Zodiac game
 *
 * This controller uses the Monte Carlo Tree Search algorithm
 * to find the best move for the AI player.
 */
export class AIController {
  private mcts: MCTS;
  private searchIterations: number = 500;
  private lastMoveStats: AIStats | null = null;
  private verboseLogging: boolean = false;
  private heuristic: HeuristicInterface;

  /**
   * Creates a new AI controller
   * @param zodiacEngine Reference to the main game engine
   */
  constructor(private zodiacEngine: ZodiacEngine) {
    this.mcts = new MCTS(zodiacEngine);
    // Set default heuristic
    this.heuristic = getHeuristic(HeuristicType.Random);
    // Pass the heuristic to MCTS
    this.mcts.setHeuristic(this.heuristic);
  }

  /**
   * Set the heuristic to use for evaluating moves
   * @param type The type of heuristic to use
   */
  setHeuristic(type: HeuristicType): void {
    this.heuristic = getHeuristic(type);
    // Also update the MCTS heuristic
    this.mcts.setHeuristic(this.heuristic);
    if (this.verboseLogging) {
      console.log(`AI heuristic set to: ${this.heuristic.name}`);
    }
  }

  /**
   * Get the current heuristic
   * @returns The current heuristic
   */
  getHeuristic(): HeuristicInterface {
    return this.heuristic;
  }

  /**
   * Get the current heuristic type
   * @returns The current heuristic type
   */
  getHeuristicType(): HeuristicType {
    return this.heuristic.name as HeuristicType;
  }

  /**
   * Enable or disable verbose logging
   * @param isVerbose Whether to enable verbose logging
   */
  setVerboseLogging(isVerbose: boolean): void {
    this.verboseLogging = isVerbose;
    this.mcts.setVerboseLogging(isVerbose);
  }

  /**
   * Get the last AI move statistics
   * @returns Statistics about the last AI move
   */
  getLastMoveStats(): AIStats | null {
    return this.lastMoveStats;
  }

  /**
   * Set the number of search iterations for the MCTS algorithm
   * @param iterations Number of iterations to perform
   */
  setSearchIterations(iterations: number): void {
    this.searchIterations = iterations;
    if (this.verboseLogging) {
      console.log(`AI search iterations set to: ${iterations}`);
    }
  }

  /**
   * Get the number of search iterations
   * @returns Current iteration count
   */
  getSearchIterations(): number {
    return this.searchIterations;
  }

  /**
   * Set the exploration parameter for the MCTS algorithm
   * Higher values encourage exploration of new moves
   * Lower values encourage exploitation of known good moves
   * @param parameter The exploration parameter value (default is Math.sqrt(2))
   */
  setExplorationParameter(parameter: number): void {
    this.mcts.setExplorationParameter(parameter);
    if (this.verboseLogging) {
      console.log(`MCTS exploration parameter set to: ${parameter}`);
    }
  }

  /**
   * Enable or disable debug mode for specific heuristics
   * @param enable Whether to enable debug mode
   */
  setHeuristicDebug(enable: boolean): void {
    if (
      this.heuristic.name === "Rhino" &&
      typeof (this.heuristic as any).setDebugging === "function"
    ) {
      (this.heuristic as any).setDebugging(enable);
      if (this.verboseLogging) {
        console.log(
          `Set debugging mode to ${enable} for ${this.heuristic.name} heuristic`
        );
      }
    }
  }

  /**
   * Make a move for the AI player
   * @returns True if a move was made, false otherwise
   */
  makeMove(): boolean {
    // Reset the stats before starting a new move
    this.lastMoveStats = {
      nodesByDepth: new Map<number, number>(),
      totalNodesVisited: 0,
      executionTimeMs: 0,
      heuristicUsed: this.heuristic.name,
    };

    const startTime = performance.now();

    // Get the best move using MCTS
    const bestMove = this.getBestMove();

    console.log("AIController: bestMove result:", bestMove);

    // Track execution time
    const endTime = performance.now();
    if (this.lastMoveStats) {
      this.lastMoveStats.executionTimeMs = Math.round(endTime - startTime);
    }

    if (!bestMove) {
      if (this.verboseLogging) {
        console.log("AI: No valid move found");
      }
      console.log("AIController: No valid move found");
      return false;
    }

    // Get the pieces at the source position
    const pieces = this.zodiacEngine.getPiecesAt(bestMove.fromPosition);
    console.log("AIController: Pieces at source position:", pieces);

    if (pieces.length === 0) {
      if (this.verboseLogging) {
        console.log("AI: No piece found at source position");
      }
      console.log(
        "AIController: No piece found at source position",
        bestMove.fromPosition
      );
      return false;
    }

    // Execute the move in the game engine with all required parameters
    const isMoveMade = this.zodiacEngine.makeMove(
      bestMove.fromPosition,
      bestMove.move.position,
      pieces[0],
      bestMove.move.toPiece,
      bestMove.move.moveType,
      bestMove.move.sideEffects
    );

    console.log("AIController: Move execution result:", isMoveMade);

    return isMoveMade;
  }

  /**
   * Find the best move for the AI player
   * @returns The best move found, or null if no valid move is available
   */
  private getBestMove(): { fromPosition: Position; move: IMove } | null {
    // Set a time limit to prevent excessive computation
    const timeLimit = 10000; // 10 second limit for thinking (increased from 5000)

    // Use appropriate iteration count based on strategy
    const reasonableIterations = Math.min(2000, this.searchIterations * 3);

    if (this.verboseLogging) {
      console.log(
        `MCTS search starting with ${reasonableIterations} iterations and ${this.heuristic.name} heuristic`
      );
    }

    try {
      // Start the search with appropriate iterations
      const bestMove = this.mcts.findBestMove(reasonableIterations);

      // For Rhino heuristic, double-check the result is a strictly forward move
      if (this.heuristic.name === "Rhino" && bestMove) {
        const rowDifference =
          bestMove.move.position.row - bestMove.fromPosition.row;
        const colDifference =
          bestMove.move.position.col - bestMove.fromPosition.col;

        // For dark pieces (AI), forward means decreasing row
        // Also ensure no sideways movement for strictness
        const isForwardMove = rowDifference < 0 && colDifference === 0;

        if (!isForwardMove) {
          console.warn(
            "FORCING FORWARD MOVEMENT: MCTS returned a non-forward move - searching for alternative"
          );

          // Last-resort search for any available strictly forward move
          const allPieces = this.zodiacEngine.getPiecesForCurrentPlayer();

          for (const pieceInfo of allPieces) {
            const fromPosition = pieceInfo.position;
            const piece = this.zodiacEngine.getPiecesAt(fromPosition)[0];

            if (!piece) continue;

            const validMoves = piece.getValidMovesForPiece(
              this.zodiacEngine.getGameState().board,
              fromPosition
            );

            // Find a strictly forward move
            for (const move of validMoves) {
              const moveDiffRow = move.position.row - fromPosition.row;
              const moveDiffCol = move.position.col - fromPosition.col;

              // Check for strictly forward movement with no sideways component
              if (moveDiffRow < 0 && moveDiffCol === 0) {
                console.log("Found alternative strict forward-only move");
                return {
                  fromPosition,
                  move,
                };
              }
            }
          }

          // If we get here and have no strictly forward moves, log a warning
          console.warn(
            "STRICT FORWARD MOVEMENT: No strictly forward moves available - using original move"
          );
        }
      }

      // Get node statistics after search
      if (this.lastMoveStats) {
        const stats = this.mcts.getNodeStatistics();
        this.lastMoveStats.nodesByDepth = stats.nodesByDepth;
        this.lastMoveStats.totalNodesVisited = stats.totalNodesVisited;
      }

      return bestMove;
    } catch (error) {
      console.error("Error in MCTS search:", error);
      return null;
    }
  }
}
