import styles from "./Square.module.css";
import { Mode, PieceType } from "../services/zodiacEngine/types";
import { IPiece } from "../services/zodiacEngine/types";
import { useContext } from "react";
import { GameContext } from "./Providers/GameContext";

type SquareRendererProps = {
  pieces: IPiece[];
  handleSelect: (piece: IPiece) => void;
};

export default function SquareRenderer(props: SquareRendererProps) {
  const {
    gameState,
    //selectedPiece,
    isThisPlayersTurn,
    isLightPlayer,
    isNNTestMode,
    //updatePotentialMoves,
    //updateSelectedPiece,
  } = useContext(GameContext)!;

  // Check if we should hide dark king in AI mode
  // Only hide when in AI mode, not in NN test mode, and not during NN training
  const shouldHideDarkKing = () => {
    return gameState.mode === Mode.AI && 
           isLightPlayer() && 
           !isNNTestMode &&
           !window.location.pathname.includes('aitraining');
  };

  // Helper function to determine CSS classes for a piece
  const getPieceClasses = (piece: IPiece, baseClass: string): string[] => {
    const pieceClasses = [baseClass];
    if (piece.isMeadowed) {
      pieceClasses.push(styles.meadowedPiece);
    }
    if (piece.isPoisoned) {
      pieceClasses.push(styles.poisonedPiece);
    }
    if (piece.isZombified) {
      pieceClasses.push(styles.zombifiedPiece);
    }
    if (piece.isEntangled) {
      pieceClasses.push(styles.entangledPiece);
    }
    if (piece.isInfected) {
      pieceClasses.push(styles.infectedPiece);
    }
    if (piece.isFainted) {
      pieceClasses.push(
        piece.isLight ? styles.lightFaintedPiece : styles.darkFaintedPiece
      );
    }
    if (piece.type === PieceType.Virus) {
      pieceClasses.push(styles.virusPiece);
    }
    return pieceClasses;
  };

  // Helper function to render the piece image
  const renderPieceImage = (
    piece: IPiece,
    pieceClasses: string[],
    extraProps: React.ImgHTMLAttributes<HTMLImageElement> = {}
  ): JSX.Element => {
    // Check if this is a dark king that should be hidden
    const isDarkKing = !piece.isLight && piece.isKing;
    const hideKingStatus = isDarkKing && shouldHideDarkKing();
    
    // If we're hiding the king status, create a copy of the piece without king status for rendering
    const displayPiece = hideKingStatus ? { ...piece, isKing: false } : piece;
    
    let pieceImagePath = `../icons/${displayPiece.type}-${
      displayPiece.isLight ? "light" : "dark"
    }.png`;

    // Fringe case: If the piece is a goat and has fainted, use the HG images
    if (displayPiece.type === PieceType.Goat && displayPiece.isFainted) {
      pieceImagePath = `../icons/HG-${displayPiece.isLight ? "light" : "dark"}.png`;
    }

    // Fringe case: If the piece is a Frog and hasn't moved, use the lily images
    else if (displayPiece.type === PieceType.Frog && !displayPiece.hasMoved) {
      pieceImagePath = `../icons/${
        displayPiece.isLight ? "lightlily2" : "darklily2"
      }.png`;
    }

    // Fringe case: If the piece is a snake that has used Shed, it gets a colorful new skin
    else if (displayPiece.type === PieceType.Snake && displayPiece.hasShed) {
      pieceImagePath = `../icons/${
        displayPiece.isLight ? "Snake-light-shead" : "Snake-dark-shead"
      }.png`;
    }

    return (
      <img
        className={pieceClasses.join(" ")}
        src={pieceImagePath}
        alt={displayPiece.type}
        {...extraProps}
      />
    );
  };

  const isPieceClickable = (piece: IPiece): boolean => {
    if (gameState.mode === Mode.Dev) return true;

    const isThisPlayersPiece = piece.isLight === isLightPlayer();

    return isThisPlayersTurn() && isThisPlayersPiece;
  };

  const getDefaultElement = (): JSX.Element => {
    // Find the tree and virus pieces on the square
    const treePiece = props.pieces.find(
      (piece) => piece.type === PieceType.Tree
    );
    const virusPiece = props.pieces.find(
      (piece) => piece.type === PieceType.Virus
    );

    // If there are multiple pieces including a tree or virus, show floating icons
    if (props.pieces.length > 1) {
      // Find other pieces that are not the tree or virus
      const otherPieces = props.pieces.filter(
        (piece: IPiece) =>
          piece.type !== PieceType.Tree && piece.type !== PieceType.Virus
      );

      // Prepare an array to hold the floating pieces
      const floatingPieces: IPiece[] = [];

      // Add the tree or virus piece to the floating pieces
      if (treePiece) {
        floatingPieces.push(treePiece);
      } else if (virusPiece) {
        floatingPieces.push(virusPiece);
      }

      // Add the other pieces to the floating pieces
      floatingPieces.push(...otherPieces);

      // Show floating icons for both tree/virus and the other piece
      return (
        <div className={styles.floatingIcons}>
          {floatingPieces.map((piece: IPiece, index: number) => {
            const pieceClasses = getPieceClasses(piece, styles.floatingIcon);
            const isDarkKing = !piece.isLight && piece.isKing;
            const hideKingStatus = isDarkKing && shouldHideDarkKing();
            
            // Create a copy of the piece with king status hidden if needed
            const displayPiece = hideKingStatus ? { ...piece, isKing: false } : piece;

            return (
              <img
                key={index}
                className={pieceClasses.join(" ")}
                src={`../icons/${displayPiece.type}-${
                  displayPiece.isLight ? "light" : "dark"
                }.png`}
                alt={displayPiece.type}
                onClick={
                  isPieceClickable(piece)
                    ? () => props.handleSelect(piece)
                    : undefined
                }
              />
            );
          })}
        </div>
      );
    }
    // Else if there is a tree or virus piece
    else if (treePiece || virusPiece) {
      const piece = treePiece || virusPiece;
      if (!piece) {
        return <></>; // or handle the undefined case appropriately
      }
      const pieceClasses = getPieceClasses(piece, styles.squareicon);

      return renderPieceImage(piece, pieceClasses);
    }
    // Else, if there's a single piece that's not a tree or virus
    else if (props.pieces.length === 1) {
      const piece = props.pieces[0];
      const pieceClasses = getPieceClasses(piece, styles.squareicon);

      return renderPieceImage(piece, pieceClasses);
    } else {
      return <></>; // Return empty if no pieces are present
    }
  };

  return <>{getDefaultElement()}</>;
}
