import { UUID } from "crypto";
import { Position, IPiece, Effect } from "./types";

export class SideEffect implements SideEffect {
  id: UUID;
  moveId: UUID;
  position: Position;
  extrapositions?: Position[] | undefined;
  pieces: IPiece[];
  effect: Effect;

  constructor(
    moveId: UUID,
    position: Position,
    pieces: IPiece[],
    effect: Effect,
    extrapositions?: Position[]
  ) {
    this.id = crypto.randomUUID() as UUID;
    this.moveId = moveId;
    this.position = position;
    this.pieces = pieces;
    this.effect = effect;
    this.extrapositions = extrapositions;
  }
}
