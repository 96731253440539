import { IMove, Position, GameState, MoveType } from "../types";
import { ZodiacEngine } from "../zodiacEngine";
import { HeuristicInterface } from "./HeuristicInterface";

/**
 * Rhino heuristic implementation
 * This allows moves that go forward, sideways, or diagonally forward,
 * but disallows backward movement
 */
export class RhinoHeuristic implements HeuristicInterface {
  name = "Rhino";
  private debugging: boolean = false;

  /**
   * Enable or disable debug logging
   * @param debug Whether to enable debug logging
   */
  setDebugging(debug: boolean): void {
    this.debugging = debug;
  }

  /**
   * Policy filter for Rhino heuristic
   * Prunes only backward moves, allowing forward, sideways, and diagonal moves
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param legalMoves Array of legal moves to filter
   * @returns Filtered array of legal moves that pass the policy
   */
  policyFilter(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    legalMoves: IMove[]
  ): IMove[] {
    // Whether we're playing as light or dark
    const isLight = gameState.isLightTurn;

    // Filter out problematic move types and only backward moves
    return legalMoves.filter((move) => {
      // Filter out rotate moves which are not implemented correctly
      if (move.moveType === MoveType.rotate) {
        return false;
      }

      // Skip goat faint moves for the AI (dark player)
      if (move.moveType === MoveType.faint && !isLight) {
        return false;
      }

      // Calculate row difference
      const rowDifference = move.position.row - fromPosition.row;

      // For light pieces, forward is decreasing row (moving up)
      // For dark pieces, forward is increasing row (moving down)
      const forwardMovement = isLight ? -rowDifference : rowDifference;

      // Only prune strictly backward moves (forwardMovement < 0)
      // Allow sideways (forwardMovement = 0) and forward (forwardMovement > 0)
      if (forwardMovement < 0) {
        if (this.debugging) {
          console.log(
            `RhinoHeuristic policy: PRUNED backward move from [${fromPosition.row},${fromPosition.col}] to [${move.position.row},${move.position.col}]`
          );
        }
        return false;
      }

      if (this.debugging) {
        console.log(
          `RhinoHeuristic policy: ALLOWED move from [${fromPosition.row},${fromPosition.col}] to [${move.position.row},${move.position.col}]`
        );
      }

      return true;
    });
  }

  /**
   * Evaluates a move with a preference for forward movement and captures
   * Adds randomization to encourage exploring different pieces
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param move The potential move to evaluate
   * @returns Score based on movement type and strategic value
   */
  evaluateMove(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    move: IMove
  ): number {
    // Whether we're playing as light or dark
    const isLight = gameState.isLightTurn;

    // Calculate row and column differences
    const rowDifference = move.position.row - fromPosition.row;
    const colDifference = move.position.col - fromPosition.col;

    // For light pieces, forward is decreasing row (moving up)
    // For dark pieces, forward is increasing row (moving down)
    const forwardMovement = isLight ? -rowDifference : rowDifference;

    // Base score starts with significant randomization to encourage variety
    let score = 100 + Math.random() * 50;

    // Add score based on movement type (with preference for forward)
    if (forwardMovement > 0) {
      // Bonus for forward movement (higher for moves that go further forward)
      score += forwardMovement * 20;

      // Additional bonus for diagonal forward moves (showing more complex behavior)
      if (colDifference !== 0) {
        score += 10;
      }
    } else if (forwardMovement === 0) {
      // Small bonus for sideways moves
      score += 5;
    }

    // Significant bonus for captures (AI should seek to capture when possible)
    if (move.toPiece !== null) {
      score += 100;
    }

    // Proximity bonus to encourage moving different pieces across the board
    // This helps prevent single-piece fixation
    const targetRow = isLight ? 0 : 7; // Target row is opponent's back rank
    const distanceToTarget = Math.abs(move.position.row - targetRow);
    score += (7 - distanceToTarget) * 5; // More points for being closer to target

    if (this.debugging) {
      console.log(
        `RhinoHeuristic: Evaluated move from [${fromPosition.row},${fromPosition.col}] to [${move.position.row},${move.position.col}] - score: ${score}`
      );
    }

    // Return the final score
    return score;
  }
}
