import React, { useContext, useEffect, useState } from "react";
import styles from "./BoardContainer.module.css";
import Board from "./Board";
import {
  Position,
  Mode,
  State,
  PieceType,
} from "../services/zodiacEngine/types";
import { Piece } from "../services/zodiacEngine/Piece";
import PieceDrag from "./PieceDrag";
import { GameContext } from "./Providers/GameContext";
import ZodiacEngineService from "./ZodiacEngineService";
import { AuthContext } from "./Providers/AuthContext";
import KingSelectionModal from "./KingSelection/KingSelectionModal";
import { useLocation, useParams } from "react-router-dom";
import TurnPopover from "./TurnPopover/TurnPopover";
import GameEndPopover from "./GameEndPopover/GameEndPopover";
import { allPieceTypes } from "../services/zodiacEngine/const";
import AIThinking from "./MCTSStats/AIThinking";
import AIStats from "./AIStats";
import { AIStats as AIStatsType } from "../services/zodiacEngine/AIController";
import FENHistory from "./FENHistory/FENHistory";
import { HeuristicType } from "../services/zodiacEngine/heuristics/HeuristicInterface";
import OptionsService, { ThinkingMode } from "../services/options/OptionsService";



// Placeholder for removed types
export interface NeatMCTSIntegration {
  setSearchIterations: (iterations: number) => void;
  setExplorationParameter: (param: number) => void;
  setVerbose: (verbose: boolean) => void;
  getBestMove: () => Promise<any>;
}

export interface NeatNetwork {
  // Empty placeholder
}

function BoardContainer() {
  const { navGameId } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (navGameId) {
      checkUser();
      ZodiacEngineService.zodiacEngine.joinGame(navGameId, user!.uid);
      setGameId(navGameId);
    }
  }, [navGameId]);

  const {
    gameState,
    potentialMoves,
    potentialSideEffects,
    isLightPlayer,
    isThisPlayersTurn,
    updateGameState,
    isAIThinking,
    updateSelectedPiece,
    updatePotentialMoves,
    setIsNNTestMode,
    isNNTestMode,
  } = useContext(GameContext)!;

  const { user } = useContext(AuthContext);

  const [gameId, setGameId] = useState(
    ZodiacEngineService.zodiacEngine.getGameId()
  );
  const [joinId, setJoinId] = useState("");
  const [copied, setCopied] = useState(false);
  const [isDeepThinking, setIsDeepThinking] = useState(false);
  const [thinkingMode, setThinkingMode] = useState<ThinkingMode>(
    OptionsService.loadThinkingMode()
  );
  const [aiStats, setAiStats] = useState<AIStatsType | null>(null);
  
  const [testingResults, setTestingResults] = useState<any>(null);
  const [neatMctsIntegration, setNeatMctsIntegration] =
    useState<NeatMCTSIntegration | null>(null);
  const [neatNetwork, setNeatNetwork] = useState<NeatNetwork | null>(null);
  const [nnTrainingEnabled, setNnTrainingEnabled] = useState<boolean>(true);
  const [trainingDataCount, setTrainingDataCount] = useState<number>(0);
  const [heuristicType, setHeuristicType] = useState<HeuristicType>(
    OptionsService.loadHeuristicType()
  );


  useEffect(() => {
    if (
      gameState.mode === Mode.AI &&
      ZodiacEngineService.zodiacEngine.aiController
    ) {
      updateAIStats();

      const intervalId = setInterval(updateAIStats, 1000);

      return () => clearInterval(intervalId);
    }
  }, [gameState.mode]);

  const updateAIStats = () => {
    if (ZodiacEngineService.zodiacEngine.aiController) {
      const stats = ZodiacEngineService.zodiacEngine.getAIStats();
      if (stats) {
        setAiStats(stats);
      }
    }
  };

  const handleJoinIdChange = (event: any) => {
    setJoinId(event.target.value);
  };

  function checkUser() {
    if (!user) {
      throw new Error("User has no id for some strange reason");
    }
  }

  async function liveMode() {
    checkUser();
    var gameId = await ZodiacEngineService.zodiacEngine.switchToLiveMove(
      user!.uid
    );
    setGameId(gameId);
  }

  function joinGame() {
    checkUser();
    ZodiacEngineService.zodiacEngine.joinGame(joinId, user!.uid);
  }

  function devMode() {
    // Switch to dev mode using the ZodiacEngine service
    ZodiacEngineService.zodiacEngine.switchToDevMove();

    // Deselect any selected piece and remove all highlights
    updateSelectedPiece(undefined);
    updatePotentialMoves([]);
  }

  async function aiMode() {
    checkUser();

    // Make sure any previous game state is fully reset
    // Use a different approach than directly accessing private properties
    ZodiacEngineService.zodiacEngine.switchToDevMove(); // First switch to dev mode

    const eligibleKings = allPieceTypes.filter(
      (piece) =>
        piece !== PieceType.Virus &&
        piece !== PieceType.Penguin &&
        piece !== PieceType.Octopus
    );

    const randomLightKingIndex = Math.floor(
      Math.random() * eligibleKings.length
    );
    const lightKing = eligibleKings[randomLightKingIndex];

    const randomDarkKingIndex = Math.floor(
      Math.random() * eligibleKings.length
    );
    const darkKing = eligibleKings[randomDarkKingIndex];

    console.log("Starting AI mode with kings:", lightKing, darkKing);

    // Set isNNTestMode to false for regular AI mode
    setIsNNTestMode(false);

    // Force reset AI controller to ensure fresh state
    if (ZodiacEngineService.zodiacEngine.aiController) {
      ZodiacEngineService.zodiacEngine.aiController = null;
    }
    // Neural network functionality has been removed

    // Call switchToAIMode with the useNeuralNetwork parameter set to false
    ZodiacEngineService.zodiacEngine.switchToAIMode(
      user!.uid,
      lightKing,
      darkKing,
      false // Neural network functionality has been removed
    );
    
    // Load saved options
    const savedHeuristic = OptionsService.loadHeuristicType();
    const savedThinkingMode = OptionsService.loadThinkingMode();
    
    // Apply saved options
    setHeuristicType(savedHeuristic);
    setThinkingMode(savedThinkingMode);
    
    // Set the selected heuristic
    ZodiacEngineService.zodiacEngine.setAIHeuristic(savedHeuristic);

    const newGameState = ZodiacEngineService.zodiacEngine.getGameState();
    setGameId(ZodiacEngineService.zodiacEngine.getGameId());
    updateGameState(newGameState);

    updateAIThinkingIterations();
  }

  async function nnTestMode() {
    console.log("Starting NN Test mode...");
    checkUser();

    const eligibleKings = allPieceTypes.filter(
      (piece) =>
        piece !== PieceType.Virus &&
        piece !== PieceType.Penguin &&
        piece !== PieceType.Octopus
    );

    const randomLightKingIndex = Math.floor(
      Math.random() * eligibleKings.length
    );
    const lightKing = eligibleKings[randomLightKingIndex];

    const randomDarkKingIndex = Math.floor(
      Math.random() * eligibleKings.length
    );
    const darkKing = eligibleKings[randomDarkKingIndex];

    console.log("Starting NN Test mode with kings:", lightKing, darkKing);

    // Set isNNTestMode to true for NN test mode
    setIsNNTestMode(true);

    // Reset controllers to ensure clean state
    ZodiacEngineService.zodiacEngine.aiController = null;
    ZodiacEngineService.zodiacEngine.neatAIController = null;

    // Call switchToAIMode with the useNeuralNetwork parameter set to true
    ZodiacEngineService.zodiacEngine.switchToAIMode(
      user!.uid,
      lightKing,
      darkKing,
      true // Use neural network enhanced AI
    );

  
    const newGameState = ZodiacEngineService.zodiacEngine.getGameState();
    setGameId(ZodiacEngineService.zodiacEngine.getGameId());
    updateGameState(newGameState);

    // Make sure we update state properly
    console.log("NN Test mode initialized, current state:", newGameState);

    updateAIThinkingIterations();
  }

  function handleDrop(
    event: React.DragEvent<HTMLDivElement>,
    position: Position
  ) {
    // Block all user interactions when in NN test mode
    if (isNNTestMode) {
      console.log("User interaction blocked in NN test mode");
      event.preventDefault();
      return;
    }

    event.preventDefault();
    const pieceData = event.dataTransfer.getData("piece");

    if (!pieceData) return;
    const { type, isLight } = JSON.parse(pieceData);

    if (gameState.mode === Mode.Dev) {
      ZodiacEngineService.zodiacEngine.placePiece(
        new Piece(type as PieceType, isLight),
        position
      );
      return;
    }

    var isDroppedInStartingRow =
      (isLightPlayer() && position.row === 0) ||
      (!isLightPlayer() && position.row === 7);

    if (
      isThisPlayersTurn() &&
      isDroppedInStartingRow &&
      gameState.mode === Mode.Live &&
      gameState.state === State.PieceSelection
    ) {
      ZodiacEngineService.zodiacEngine.setStartingPiece(
        type as PieceType,
        isLightPlayer(),
        position.col
      );
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    // Block all user interactions when in NN test mode
    if (isNNTestMode) {
      return;
    }

    event.preventDefault();
  }

  const copyGameIdToClipboard = () => {
    if (gameId) copyToClipboard(gameId);
  };

  const copyLinkToClipboard = async () => {
    if (!gameId) return;
    var path = window.location.origin + location.pathname + gameId;
    console.log(path);
    copyToClipboard(path);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text!);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      alert("Failed to copy!");
      console.error("Failed to copy!", err);
    }
  };

  function isPieceSelectionOpen(): boolean {
    return gameState.state === State.KingSelection;
  }

  const toggleThinkingMode = () => {
    // Get the current thinking mode
    const currentMode = thinkingMode;

    // Cycle through the modes: fast -> deep -> ultra -> fast
    let newMode: ThinkingMode;
    if (currentMode === "Fast") {
      newMode = "Normal";
    } else if (currentMode === "Normal") {
      newMode = "Deep";
    } else {
      newMode = "Fast";
    }

    // Set the new thinking mode
    setThinkingMode(newMode);
    
    // Save thinking mode to persistent storage
    OptionsService.saveThinkingMode(newMode);

    // Update AI thinking iterations based on mode
    if (ZodiacEngineService.zodiacEngine.aiController) {
      if (newMode === "Fast") {
        ZodiacEngineService.zodiacEngine.aiController.setSearchIterations(
          500
        );
      } else if (newMode === "Normal") {
        ZodiacEngineService.zodiacEngine.aiController.setSearchIterations(
          3000
        );
      } else if (newMode === "Deep") {
        ZodiacEngineService.zodiacEngine.aiController.setSearchIterations(
          10000
        );
      }
    }

    console.log(`Thinking mode set to: ${newMode}`);
  };

  const updateAIThinkingIterations = () => {
    // Use the current thinking mode from state
    const currentThinkingMode = thinkingMode;
    
    if (currentThinkingMode === "Deep") {
      ZodiacEngineService.zodiacEngine.setAISearchIterations(10000);
      ZodiacEngineService.zodiacEngine.setAIExplorationParameter(2.0);
    } else if (currentThinkingMode === "Normal") {
      ZodiacEngineService.zodiacEngine.setAISearchIterations(3000);
      ZodiacEngineService.zodiacEngine.setAIExplorationParameter(1.414);
    } else {
      ZodiacEngineService.zodiacEngine.setAISearchIterations(200);
      ZodiacEngineService.zodiacEngine.setAIExplorationParameter(1.0);
    }
  };


  // Add a new effect to periodically update the training data count from persistent storage
  useEffect(() => {
    if (
      gameState.mode === Mode.AI &&
      ZodiacEngineService.zodiacEngine.isUsingNeuralNetwork()
    ) {
      // Update training data count from both in-memory and persistent storage
      const updateTrainingDataCount = async () => {
        // Neural network functionality has been removed
        setTrainingDataCount(0);
      };

      updateTrainingDataCount();
      const intervalId = setInterval(updateTrainingDataCount, 3000);

      return () => clearInterval(intervalId);
    }
  }, [gameState.mode]);

  const toggleNnTraining = async () => {
    try {
      setNnTrainingEnabled(!nnTrainingEnabled);
      // Neural network functionality has been removed
      console.log(`NN training ${!nnTrainingEnabled ? 'enabled' : 'disabled'}`);
      await updateTrainingCount();
    } catch (error) {
      console.error("Error toggling NN training:", error);
    }
  };

  const saveTrainingData = async () => {
    console.log("Neural network functionality has been removed");
    alert("Neural network functionality has been removed");
  };

  const trainNeuralNetwork = async () => {
    console.log("Neural network functionality has been removed");
    alert("Neural network functionality has been removed");
  };

  const updateTrainingCount = async () => {
    try {
      // Neural network functionality has been removed
      setTrainingDataCount(0);
    } catch (error) {
      console.error("Error updating training count:", error);
    }
  };

  const handleHeuristicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newHeuristic = event.target.value as HeuristicType;
    setHeuristicType(newHeuristic);
    ZodiacEngineService.zodiacEngine.setAIHeuristic(newHeuristic);
    
    // Save heuristic type to persistent storage
    OptionsService.saveHeuristicType(newHeuristic);
    
    console.log(`AI heuristic set to: ${newHeuristic}`);
  };

  // Update AI thinking iterations whenever the thinking mode changes
  useEffect(() => {
    if (gameState.mode === Mode.AI && ZodiacEngineService.zodiacEngine.aiController) {
      updateAIThinkingIterations();
    }
  }, [thinkingMode]);

  return (
    <div className={styles.container}>
      {gameState.mode !== Mode.AI && (
        <TurnPopover
          isThisPlayersTurn={gameState.isLightTurn === isLightPlayer()}
          state={gameState.state}
          mode={gameState.mode}
          duration={2000}
        />
      )}
      <GameEndPopover
        isGameOver={gameState.state === State.PostGame}
        hasWon={
          gameState.lightWon !== undefined &&
          gameState.lightWon === isLightPlayer()
        }
        mode={gameState.mode}
        isNNTestMode={isNNTestMode}
      />
      <KingSelectionModal
        isOpen={isPieceSelectionOpen() && gameState.mode !== Mode.AI}
        isLight={isLightPlayer()}
      />
      <AIThinking
        isVisible={isAIThinking}
        mode={gameState.mode}
        state={gameState.state}
        isDeepThinking={isDeepThinking}
      />
      <div className={styles.gameControls}>
        <h1>Zodiac</h1>
        <button onClick={liveMode} disabled={gameState.mode === Mode.Live}>
          Create Game
        </button>
        <button onClick={copyLinkToClipboard}>
          {copied ? "Copied!" : "Copy link"}
        </button>

        <p />
        <button onClick={joinGame}>Join Game</button>
        <input type="text" value={joinId} onChange={handleJoinIdChange} />
        <p />
        <button onClick={devMode}>Return to Dev Mode</button>
        <p />
        <a href="/how-to-play" className={styles.howToPlayLink}>
          How to Play
        </a>
        <p />
        <button
          onClick={aiMode}
          className={styles.howToPlayLink}
          style={{ backgroundColor: "#1E90FF" }}
        >
          Play vs AI
        </button>

        {/* Heuristic dropdown */}
        {gameState.mode === Mode.AI && (
          <>
            <p />
            <div>
              <label htmlFor="heuristicSelect">AI Heuristic: </label>
              <select 
                id="heuristicSelect" 
                value={heuristicType}
                onChange={handleHeuristicChange}
                style={{
                  padding: '5px',
                  marginLeft: '5px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              >
                <option value={HeuristicType.Random}>Random</option>
                <option value={HeuristicType.Rhino}>Rhino</option>
                <option value={HeuristicType.Virus}>Virus</option>
              </select>
            </div>
          </>
        )}

        {/* Only show thinking mode button in AI or NN test mode */}
        {gameState.mode === Mode.AI && (
          <>
            <p />
            <button
              onClick={toggleThinkingMode}
              className={styles.howToPlayLink}
              style={{ backgroundColor: "#888888" }}
            >
              <span
                role="img"
                aria-label="lightning bolt"
                style={{ marginRight: "5px" }}
              >
                ⚡
              </span>
              Thinking:{" "}
              {thinkingMode === "Fast"
                ? "Fast"
                : thinkingMode === "Normal"
                ? "Normal"
                : "Deep"}
            </button>
          </>
        )}
      </div>
      <div className={styles.gameContent}>
        <div className={styles.boardWrapper}>
          {(gameState.state === State.PieceSelection ||
            gameState.mode === Mode.Dev) && (
            <div className={styles.pieceSelection}>
              <PieceDrag
                isDevMode={gameState.mode === Mode.Dev}
                isLight={isLightPlayer()}
              />
            </div>
          )}
          <Board
            gameState={gameState}
            uiState={{
              potentialMoves: potentialMoves,
              potentialSideEffects: potentialSideEffects,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          />
        </div>

        <FENHistory />
      </div>
      <div className={styles.debug}>
        <h1>Debug Console</h1>
        UserId: {user?.uid.slice(0, 8) + "..."} <br />
        Mode: {isNNTestMode ? "NN TEST MODE" : gameState.mode}{" "}
        {!isNNTestMode && gameState.mode === Mode.AI
          ? ZodiacEngineService.zodiacEngine.isUsingNeuralNetwork()
            ? "- NN-MCTS AI Mode"
            : "- Standard MCTS AI Mode"
          : ""}
        <br />
        Game Id: {gameId ? gameId!.slice(0, 8) + "..." : "No Game Id"}
        <br />
        <button onClick={copyGameIdToClipboard}>
          {copied ? "Copied!" : "Copy Game Id"}
        </button>
        <br />
        
        {testingResults && (
          <>
            Last Test Result: {testingResults.success ? "Success" : "Failed"}{" "}
            <br />
            {testingResults.success ? (
              <>
                Move: {testingResults.moveDetails.from} →{" "}
                {testingResults.moveDetails.to} <br />
                Piece: {testingResults.moveDetails.piece} <br />
                Capture: {testingResults.moveDetails.isCapture
                  ? "Yes"
                  : "No"}{" "}
                <br />
              </>
            ) : (
              <>
                Error: {testingResults.error} <br />
              </>
            )}
          </>
        )}
        State: {gameState.state} <br />
        Whose Turn: {gameState.isLightTurn ? "Light" : "Dark"} <br />
        This player: {isLightPlayer() ? "Light" : "Dark"} <br />
        Turn count: {gameState.turnCount} <br />
        AI Thinking: {isAIThinking ? "Yes" : "No"} <br />
        {gameState.mode === Mode.AI && (
          <>
            AI Heuristic: {heuristicType} <br />
          </>
        )}
        <br />
        {gameState.mode === Mode.AI && <AIStats aiStats={aiStats} />}
        <br />
        Light King:
        {gameState.lightKing || "Not Set"}
        <br />
        Dark King:
        {/* Hide dark king information in AI mode when not in NN test mode */}
        {gameState.mode === Mode.AI &&
        !isNNTestMode &&
        !window.location.pathname.includes("aitraining")
          ? "????"
          : gameState.darkKing || "Not Set"}
      </div>
    </div>
  );
}

export default BoardContainer;
