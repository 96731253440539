import React, { FC, useEffect, useState } from 'react';
import { Mode, State } from '../../services/zodiacEngine/types';

interface AIThinkingProps {
  isVisible: boolean;
  mode: Mode;
  state: State;
  isDeepThinking?: boolean; // Keeping parameter for backward compatibility
}

const AIThinking: FC<AIThinkingProps> = ({ isVisible, mode, state, isDeepThinking = false }) => {
  const [dots, setDots] = useState('.');
  
  // Animate the dots for loading effect
  useEffect(() => {
    if (!isVisible) return;
    
    const interval = setInterval(() => {
      setDots(prev => prev.length >= 3 ? '.' : prev + '.');
    }, 500);
    
    return () => clearInterval(interval);
  }, [isVisible]);

  if (!isVisible || mode !== Mode.AI || state !== State.Playing) {
    return null;
  }

  const styles: React.CSSProperties = {
    position: 'fixed',
    top: '20px',  // Position at top instead of center
    right: '20px', // Position at right
    padding: '10px 15px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    borderRadius: '4px',
    zIndex: 1000,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  };

  return (
    <div style={styles}>
      <span 
        role="img" 
        aria-label="thinking" 
        style={{ marginRight: '8px' }}
      >
        🤔
      </span>
      AI thinking{dots}
    </div>
  );
};

export default AIThinking; 