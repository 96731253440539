import { animalPieceTypes, directions } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class VirusMovement extends BaseMovement implements PieceMovement {
  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const twoSquaresAway = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };

      if (this.onBoard(boardState, twoSquaresAway)) {
        const targetSquare = boardState[twoSquaresAway.row][twoSquaresAway.col];
        const targetPiece = targetSquare.Pieces[0];

        if (targetPiece && animalPieceTypes.includes(targetPiece.type)) {
          const move = new Move(twoSquaresAway, MoveType.infect, targetPiece);
          possibleMoves.push(move);
        }
      } else {
        const oneSquareAway = {
          col: position.col + direction[0],
          row: position.row + direction[1],
        };

        if (this.onBoard(boardState, oneSquareAway)) {
          const targetSquare = boardState[oneSquareAway.row][oneSquareAway.col];
          const targetPiece = targetSquare.Pieces[0];

          if (targetPiece && animalPieceTypes.includes(targetPiece.type)) {
            const move = new Move(oneSquareAway, MoveType.infect, targetPiece);
            possibleMoves.push(move);
          }
        }
      }
    }

    return possibleMoves;
  }
}
