import { IMove, Position, GameState, MoveType } from "../types";
import { ZodiacEngine } from "../zodiacEngine";
import { HeuristicInterface } from "./HeuristicInterface";

/**
 * Random heuristic implementation
 * This simply assigns random values to moves
 */
export class RandomHeuristic implements HeuristicInterface {
  name = "Random";

  /**
   * Policy filter for Random heuristic
   * For the random heuristic, we only do basic filtering of problematic move types
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param legalMoves Array of legal moves to filter
   * @returns Filtered array of legal moves that pass the policy
   */
  policyFilter(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    legalMoves: IMove[]
  ): IMove[] {
    // For random heuristic, we only filter out problematic move types
    return legalMoves.filter((move) => {
      // Filter out rotate moves which are not implemented correctly
      if (move.moveType === MoveType.rotate) {
        return false;
      }

      // Skip goat faint moves for the AI (dark player)
      if (move.moveType === MoveType.faint && !gameState.isLightTurn) {
        return false;
      }

      return true;
    });
  }

  /**
   * Evaluates a move by returning a random value
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param move The potential move to evaluate
   * @returns Random score between 0 and 1
   */
  evaluateMove(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    move: IMove
  ): number {
    // Simply return a random value
    return Math.random();
  }
}
