import { IMove, Position, GameState, MoveType } from "../types";
import { ZodiacEngine } from "../zodiacEngine";
import { HeuristicInterface } from "./HeuristicInterface";

/**
 * Virus heuristic implementation
 * Focuses on aggressive capture-oriented play that prioritizes removing pieces
 * from the board, including sacrificing own pieces for tactical advantage.
 * Models the behavior of the virus piece - infectious, aggressive, and destructive.
 */
export class VirusHeuristic implements HeuristicInterface {
  name = "Virus";
  private debugging: boolean = true; // Always enable debugging for lane move blocking

  /**
   * Enable or disable debug logging
   * @param debug Whether to enable debug logging
   */
  setDebugging(debug: boolean): void {
    this.debugging = debug;
  }

  /**
   * Policy filter for Virus heuristic
   * Prioritizes moves that lead to captures or position pieces for future captures
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param legalMoves Array of legal moves to filter
   * @returns Filtered array of legal moves that pass the policy
   */
  policyFilter(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    legalMoves: IMove[]
  ): IMove[] {
    // ****************************************************
    // COMPLETE REWRITE WITH WHITELIST APPROACH
    // Instead of filtering out bad moves, we'll explicitly
    // build an array of allowed moves from scratch
    // ****************************************************

    console.log(
      `VIRUS HEURISTIC PROCESSING ${legalMoves.length} LEGAL MOVES FOR POSITION [${fromPosition.row},${fromPosition.col}]`
    );

    // Create a new array to hold our approved moves only
    const allowedMoves: IMove[] = [];

    // Get the piece we're moving
    const movingPiece = this.getPieceAt(gameState, fromPosition);
    if (!movingPiece) {
      console.error("No piece found at position for Virus heuristic");
      return [];
    }

    // ABSOLUTE PRIORITY: Block ALL lane moves for Rhinos first
    if (movingPiece.type === "Rhino") {
      console.log("🦏 RHINO DETECTED - APPLYING STRICT MOVEMENT RESTRICTIONS");

      // First filter explicitly to remove all lane moves
      legalMoves = legalMoves.filter((move) => {
        if (move.moveType === MoveType.lane) {
          console.log(`🚫 BLOCKED: Lane move type for Rhino [strict block]`);
          return false;
        }

        // Also block any horizontal (left-right) movement for Rhinos
        const rowDiff = Math.abs(move.position.row - fromPosition.row);
        const colDiff = Math.abs(move.position.col - fromPosition.col);

        if (rowDiff === 0 && colDiff > 0) {
          console.log(
            `🚫 BLOCKED: Horizontal movement for Rhino [strict block]`
          );
          return false;
        }

        return true;
      });
    }

    // Examine each legal move and only add ones that pass ALL checks
    for (const move of legalMoves) {
      // STRICT BLOCKING: Never allow lane moves under any circumstances
      if (move.moveType === MoveType.lane) {
        console.log(
          `🚫 BANNED MOVE TYPE: Lane move from [${fromPosition.row},${fromPosition.col}] to [${move.position.row},${move.position.col}]`
        );
        continue; // Skip this move entirely
      }

      // Block rotate moves
      if (move.moveType === MoveType.rotate) {
        console.log(`🚫 BANNED MOVE TYPE: Rotate move`);
        continue;
      }

      // Block capturing own trees
      if (
        move.toPiece !== null &&
        move.toPiece.isLight === gameState.isLightTurn &&
        move.toPiece.type === "Tree"
      ) {
        console.log(`🚫 BANNED MOVE: Capturing own Tree`);
        continue;
      }

      // Block any moves where Rhino uses lane-like abilities (long straight moves)
      if (movingPiece.type === "Rhino") {
        const rowDiff = Math.abs(move.position.row - fromPosition.row);
        const colDiff = Math.abs(move.position.col - fromPosition.col);

        // EXTREMELY STRICT BLOCKING FOR RHINOS:
        // 1. Block ANY sideways movement for Rhinos (even normal ones)
        if (rowDiff === 0 && colDiff === 1) {
          console.log(
            `🚫 BANNED MOVE: Rhino sideways move to [${move.position.row},${move.position.col}] - these are ALWAYS lane moves`
          );
          continue;
        }

        // 2. Block any Rhino move that's more than 1 square away
        if (rowDiff > 1 || colDiff > 1) {
          console.log(
            `🚫 BANNED MOVE: Rhino long-distance move to [${move.position.row},${move.position.col}]`
          );
          continue;
        }
      }

      // Block Frog moves that might let it jump into trees
      if (movingPiece.type === "Frog") {
        // Search in a 2-square radius for any of our trees
        let mightInteractWithTree = false;

        // Check the entire board for trees that belong to us
        for (let r = 0; r < gameState.board.length; r++) {
          for (let c = 0; c < gameState.board[r].length; c++) {
            const piecesHere = gameState.board[r][c].Pieces;

            // If we find our own tree
            if (
              piecesHere.some(
                (p) => p.isLight === gameState.isLightTurn && p.type === "Tree"
              )
            ) {
              // Calculate distance from proposed move to this tree
              const distToTree =
                Math.abs(move.position.row - r) +
                Math.abs(move.position.col - c);

              // If the move would position the frog near our tree, it might be able to jump into it
              if (distToTree <= 2) {
                mightInteractWithTree = true;
                break;
              }
            }
          }

          if (mightInteractWithTree) break;
        }

        if (mightInteractWithTree) {
          console.log(
            `🚫 BANNED MOVE: Frog move to [${move.position.row},${move.position.col}] - might let it jump into our tree`
          );
          continue;
        }
      }

      // Block any moves that involve tree interactions through side effects
      let hasTreeInteraction = false;

      if (move.sideEffects && move.sideEffects.length > 0) {
        for (const effect of move.sideEffects) {
          const targetRow = effect.position.row;
          const targetCol = effect.position.col;

          // Check if this position is valid
          if (
            targetRow >= 0 &&
            targetRow < gameState.board.length &&
            targetCol >= 0 &&
            targetCol < gameState.board[0].length
          ) {
            // Check all pieces at this position
            const piecesAtTarget = gameState.board[targetRow][targetCol].Pieces;

            // If any piece is our own tree, ban the move
            if (
              piecesAtTarget.some(
                (p) => p.isLight === gameState.isLightTurn && p.type === "Tree"
              )
            ) {
              console.log(
                `🚫 BANNED MOVE: Side effect interacts with own Tree`
              );
              hasTreeInteraction = true;
              break;
            }
          }
        }

        if (hasTreeInteraction) {
          continue; // Skip this move
        }
      }

      // Block ANY special move types that might allow tree interactions
      if (
        move.moveType !== MoveType.regular &&
        move.moveType !== MoveType.capture
      ) {
        // Check for any trees belonging to us
        const hasOwnTrees = this.playerHasTreesOnBoard(gameState);

        if (hasOwnTrees) {
          // Block ANY non-standard move types to be extra safe
          console.log(
            `🚫 BANNED MOVE TYPE: ${move.moveType} move - might interact with trees`
          );
          continue;
        }
      }

      // If the move passed all our strict checks, add it to allowed moves
      allowedMoves.push(move);
    }

    console.log(
      `VIRUS HEURISTIC APPROVED ${allowedMoves.length} OF ${legalMoves.length} MOVES`
    );

    // If no moves are allowed after strict filtering, return empty array
    if (allowedMoves.length === 0) {
      return [];
    }

    // From here, apply the regular prioritization logic on the allowed moves only

    // Early game strategy (first 5 turns) - prioritize developing different pieces forward
    if (gameState.turnCount < 5) {
      // Check if this piece has moved before
      if (movingPiece && movingPiece.hasMoved) {
        // If we have a piece that hasn't moved yet somewhere else, deprioritize this piece
        if (this.existsUnmovedPiece(gameState)) {
          // If captures are available, still allow them
          const captureMoves = allowedMoves.filter(
            (move) => move.toPiece !== null
          );
          if (captureMoves.length > 0) {
            return captureMoves;
          }

          // Otherwise return no moves for this piece, to force checking other pieces
          return [];
        }
      }

      // In early game, identify forward-moving non-capture moves
      const forwardMoves = this.getForwardMoves(
        gameState,
        fromPosition,
        allowedMoves
      );

      // If we have forward moves, prioritize them
      if (forwardMoves.length > 0) {
        console.log(
          `VIRUS HEURISTIC: Using ${forwardMoves.length} forward development moves for early game`
        );
        return forwardMoves;
      }
    }

    // Identify capture moves
    const captureMoves = allowedMoves.filter((move) => move.toPiece !== null);

    // If there are capture moves, prioritize them
    if (captureMoves.length > 0) {
      console.log(
        `VIRUS HEURISTIC: Found ${captureMoves.length} capture moves`
      );
      return captureMoves;
    }

    // If no captures available, look for moves toward enemies
    const enemyPositions = this.getEnemyPositions(gameState);

    if (enemyPositions.length > 0) {
      // Find nearest enemy
      const nearestEnemy = this.findNearestEnemyPosition(
        fromPosition,
        enemyPositions
      );

      // Score moves by how much closer they get to enemy
      const scoredMoves = allowedMoves.map((move) => {
        const currentDistance = this.calculateManhattanDistance(
          fromPosition,
          nearestEnemy
        );
        const newDistance = this.calculateManhattanDistance(
          move.position,
          nearestEnemy
        );
        const directionScore = currentDistance - newDistance;

        return { move, directionScore };
      });

      // Filter to only moves that advance toward enemies
      const movesTowardEnemies = scoredMoves.filter(
        (item) => item.directionScore > 0
      );

      if (movesTowardEnemies.length > 0) {
        // Sort by how much closer they get
        movesTowardEnemies.sort((a, b) => b.directionScore - a.directionScore);
        console.log(
          `VIRUS HEURISTIC: Using ${movesTowardEnemies.length} moves toward enemies`
        );
        return movesTowardEnemies.map((item) => item.move);
      }

      // No advancing moves, take the least retreating ones
      scoredMoves.sort((a, b) => b.directionScore - a.directionScore);
      const bestCount = Math.max(3, Math.floor(scoredMoves.length / 2));
      const bestMoves = scoredMoves
        .slice(0, bestCount)
        .map((item) => item.move);

      console.log(
        `VIRUS HEURISTIC: Using ${bestMoves.length} least-retreating moves`
      );
      return bestMoves;
    }

    // If we get here, return all allowed moves
    return allowedMoves;
  }

  /**
   * Find the position of the nearest enemy piece
   */
  private findNearestEnemyPosition(
    position: Position,
    enemyPositions: Position[]
  ): Position {
    if (enemyPositions.length === 0) {
      // This shouldn't happen as this function should only be called when there are enemy positions
      return { row: 3, col: 3 }; // Default to center if no enemies (shouldn't occur)
    }

    let nearestPosition = enemyPositions[0];
    let shortestDistance = this.calculateManhattanDistance(
      position,
      enemyPositions[0]
    );

    for (let i = 1; i < enemyPositions.length; i++) {
      const distance = this.calculateManhattanDistance(
        position,
        enemyPositions[i]
      );
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestPosition = enemyPositions[i];
      }
    }

    return nearestPosition;
  }

  /**
   * Calculate Manhattan distance between two positions
   */
  private calculateManhattanDistance(pos1: Position, pos2: Position): number {
    return Math.abs(pos1.row - pos2.row) + Math.abs(pos1.col - pos2.col);
  }

  /**
   * Evaluates a move with strong preference for captures and aggressive positioning
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param move The potential move to evaluate
   * @returns Score based on capture potential and board position
   */
  evaluateMove(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    move: IMove
  ): number {
    // Base score starts with some randomization to avoid deterministic behavior
    let score = 50 + Math.random() * 25;

    // Early game strategy (first 5 turns) - focus on development
    if (gameState.turnCount < 5) {
      // Get the piece we're moving
      const piece = this.getPieceAt(gameState, fromPosition);

      // Bonus for moving a piece that hasn't moved yet (developing new pieces)
      if (piece && !piece.hasMoved) {
        score += 300;
      }

      // Bonus for forward movement in early game
      const rowDifference = move.position.row - fromPosition.row;
      const isForward = gameState.isLightTurn
        ? rowDifference < 0 // Light moves up (decreasing row)
        : rowDifference > 0; // Dark moves down (increasing row)

      if (isForward) {
        score += 200;

        // Extra bonus for moving further forward
        const forwardDistance = Math.abs(rowDifference);
        score += forwardDistance * 50;
      }
    }

    // CAPTURE EVALUATION - Extremely high priority
    if (move.toPiece !== null) {
      // Massive bonus for any capture
      score += 1000;

      // Extra value for capturing enemy pieces vs own pieces
      // Still positive for capturing own pieces (virus-like behavior) except trees
      const isEnemyPiece = move.toPiece.isLight !== gameState.isLightTurn;

      if (isEnemyPiece) {
        score += 500;

        // All captures are equally valuable to the virus
        // It doesn't discriminate between piece types
        score += 200; // Fixed bonus for any enemy capture
      } else {
        // Don't encourage capturing own pieces as much
        score += 50;
      }
    } else {
      // POSITIONAL EVALUATION - For non-capture moves

      // Calculate how the move positions relative to enemy pieces
      const enemyPositions = this.getEnemyPositions(gameState);

      if (enemyPositions.length > 0) {
        // Get nearest enemy position
        const nearestEnemyPosition = this.findNearestEnemyPosition(
          fromPosition,
          enemyPositions
        );

        // Calculate current distance to nearest enemy
        const currentDistance = this.calculateManhattanDistance(
          fromPosition,
          nearestEnemyPosition
        );

        // Calculate new distance after move
        const newDistance = this.calculateManhattanDistance(
          move.position,
          nearestEnemyPosition
        );

        // Calculate how much closer this move gets us to the enemy
        const distanceImprovement = currentDistance - newDistance;

        // Strongly reward moves that get closer to enemies
        score += distanceImprovement * 200;

        // Additional bonus for moves that get very close to enemies
        if (newDistance <= 2) {
          score += (3 - newDistance) * 300; // Huge bonus for getting adjacent to enemies
        }

        // Aggressive central control bonus
        const centralityScore = this.calculateCentralityScore(move.position);
        score += centralityScore * 20;
      }
    }

    // Calculate if this move enables future captures
    const futureCapturePotential = this.evaluateFutureCapturePotential(
      engine,
      gameState,
      move.position
    );
    score += futureCapturePotential * 100;

    // Slightly prefer moves that sacrifice pieces if they lead to better position
    // This creates the virus-like behavior of not caring about self-preservation
    const exposureScore = this.evaluateExposureAfterMove(
      engine,
      gameState,
      fromPosition,
      move.position
    );
    score += exposureScore * 10;

    if (this.debugging) {
      console.log(
        `VirusHeuristic: Evaluated move from [${fromPosition.row},${fromPosition.col}] to [${move.position.row},${move.position.col}] - score: ${score}`
      );
    }

    return score;
  }

  /**
   * Get positions of all enemy pieces on the board
   */
  private getEnemyPositions(gameState: GameState): Position[] {
    const enemyPositions: Position[] = [];
    const isLightTurn = gameState.isLightTurn;

    for (let row = 0; row < gameState.board.length; row++) {
      for (let col = 0; col < gameState.board[row].length; col++) {
        const pieces = gameState.board[row][col].Pieces;

        // Check if any pieces belong to the enemy
        if (pieces.some((piece) => piece.isLight !== isLightTurn)) {
          enemyPositions.push({ row, col });
        }
      }
    }

    return enemyPositions;
  }

  /**
   * Calculate a score based on proximity to enemy pieces
   * Higher score means closer to enemies (better for virus strategy)
   */
  private calculateProximityScore(
    position: Position,
    enemyPositions: Position[]
  ): number {
    if (enemyPositions.length === 0) return 0;

    // Calculate distance to each enemy piece
    const distances = enemyPositions.map((enemyPos) => {
      const rowDist = Math.abs(position.row - enemyPos.row);
      const colDist = Math.abs(position.col - enemyPos.col);
      return rowDist + colDist; // Manhattan distance
    });

    // Find the minimum distance to any enemy piece
    const minDistance = Math.min(...distances);

    // Convert to a score where smaller distances = higher scores
    // A piece next to an enemy (distance 1) gets maximum score
    return Math.max(0, 10 - minDistance);
  }

  /**
   * Calculate a score based on how central the position is
   * Center control is valuable for the virus to maximize capture opportunities
   */
  private calculateCentralityScore(position: Position): number {
    // Distance from center of the board (assuming 8x8 board)
    const centerRow = 3.5; // Between rows 3 and 4
    const centerCol = 3.5; // Between columns 3 and 4

    const rowDistance = Math.abs(position.row - centerRow);
    const colDistance = Math.abs(position.col - centerCol);

    // Max distance from center is ~5 (corner to center), so normalize to 0-10 range
    return 10 - (rowDistance + colDistance) * 1.2;
  }

  /**
   * Evaluate the potential for future captures from this position
   * Returns a score based on how many potential capture targets are nearby
   */
  private evaluateFutureCapturePotential(
    engine: ZodiacEngine,
    gameState: GameState,
    position: Position
  ): number {
    // Simple approximation: count enemy pieces in a 3x3 area around position
    let potentialTargets = 0;
    const isLightTurn = gameState.isLightTurn;

    for (let rowOffset = -2; rowOffset <= 2; rowOffset++) {
      for (let colOffset = -2; colOffset <= 2; colOffset++) {
        const row = position.row + rowOffset;
        const col = position.col + colOffset;

        // Skip if out of bounds
        if (
          row < 0 ||
          row >= gameState.board.length ||
          col < 0 ||
          col >= gameState.board[0].length
        ) {
          continue;
        }

        // Count enemy pieces
        const pieces = gameState.board[row][col].Pieces;
        potentialTargets += pieces.filter(
          (p) => p.isLight !== isLightTurn
        ).length;
      }
    }

    return potentialTargets;
  }

  /**
   * Evaluate whether a move exposes the piece to capture
   * For Virus, this can be desirable as a sacrifice to enable better captures
   */
  private evaluateExposureAfterMove(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    toPosition: Position
  ): number {
    // This is a simplified approximation that encourages "risky" moves
    // Real implementation would require deeper game state analysis

    // Check if the piece is moving toward enemy territory
    const isLightTurn = gameState.isLightTurn;
    const movingTowardEnemy = isLightTurn
      ? toPosition.row < fromPosition.row // Light pieces moving up
      : toPosition.row > fromPosition.row; // Dark pieces moving down

    // Check if the move is to a more exposed position (away from edges)
    const edgeDistanceBefore = Math.min(
      fromPosition.row,
      7 - fromPosition.row,
      fromPosition.col,
      7 - fromPosition.col
    );

    const edgeDistanceAfter = Math.min(
      toPosition.row,
      7 - toPosition.row,
      toPosition.col,
      7 - toPosition.col
    );

    // More exposed = higher score for Virus (embrace risk)
    const exposureChange = edgeDistanceAfter - edgeDistanceBefore;

    // Combine factors - virus likes moving toward enemies and toward center
    return (movingTowardEnemy ? 5 : 0) + exposureChange;
  }

  /**
   * Get a piece at a specific position
   */
  private getPieceAt(gameState: GameState, position: Position): any {
    const pieces = gameState.board[position.row][position.col].Pieces;
    return pieces.length > 0 ? pieces[0] : null;
  }

  /**
   * Check if there are any unmoved pieces of the current player
   */
  private existsUnmovedPiece(gameState: GameState): boolean {
    const isLightTurn = gameState.isLightTurn;

    for (let row = 0; row < gameState.board.length; row++) {
      for (let col = 0; col < gameState.board[row].length; col++) {
        const pieces = gameState.board[row][col].Pieces;

        // Check for unmoved pieces of the current player
        if (
          pieces.some(
            (piece) => piece.isLight === isLightTurn && !piece.hasMoved
          )
        ) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Get moves that develop pieces forward for early game
   */
  private getForwardMoves(
    gameState: GameState,
    fromPosition: Position,
    moves: IMove[]
  ): IMove[] {
    const isLightTurn = gameState.isLightTurn;

    // For light pieces, "forward" means decreasing row (moving up)
    // For dark pieces, "forward" means increasing row (moving down)
    return moves.filter((move) => {
      // Calculate row difference to determine forward movement
      const rowDifference = move.position.row - fromPosition.row;

      // Forward movement - must be strictly forward (not sideways)
      const isForward = isLightTurn
        ? rowDifference < 0 // Light moves up (decreasing row)
        : rowDifference > 0; // Dark moves down (increasing row)

      // Early game prefers non-capture forward moves to develop position
      return isForward && move.toPiece === null;
    });
  }

  /**
   * Check if the current player has trees on the board
   */
  private playerHasTreesOnBoard(gameState: GameState): boolean {
    const isLightTurn = gameState.isLightTurn;

    for (let row = 0; row < gameState.board.length; row++) {
      for (let col = 0; col < gameState.board[row].length; col++) {
        const pieces = gameState.board[row][col].Pieces;

        // Check for trees belonging to the current player
        if (
          pieces.some(
            (piece) => piece.isLight === isLightTurn && piece.type === "Tree"
          )
        ) {
          return true;
        }
      }
    }

    return false;
  }
}
