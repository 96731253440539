import { allPieceTypes, directions } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  ISquare,
  MoveType,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class BearMovement extends BaseMovement implements PieceMovement {
  // All pieces
  capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    fromPosition: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const toPosition = {
        col: fromPosition.col + direction[0],
        row: fromPosition.row + direction[1],
      };

      // Short circuit if new position is off board
      if (!this.onBoard(boardState, toPosition)) continue;

      const toSquare = boardState[toPosition.row][toPosition.col];
      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      // Check if the target square has multiple pieces (potentially a tree housing another piece)
      if (toSquare.Pieces.length > 1) {
        // Check if one of the pieces is a tree
        const hasTree = toSquare.Pieces.some((p) => p.type === PieceType.Tree);

        if (hasTree) {
          // Find the non-tree piece in the square
          const housedPiece = toSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );

          if (housedPiece) {
            if (housedPiece.isLight === piece.isLight) {
              // If the housed piece is friendly, skip this move
              continue;
            } else {
              // If the housed piece is an enemy, create a capture move
              possibleMoves.push(
                new Move(toPosition, MoveType.capture, housedPiece)
              );
              continue;
            }
          }
        }
      }

      if (squareInfo.basicSquareInfo) {
        var move = this.getMove(
          fromPosition,
          toPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }else {squareInfo.housedTreeSquareInfo}
    }
    return possibleMoves;
  }
}
