import React, { useContext, useEffect, useState, useRef } from 'react';
import { GameContext } from '../Providers/GameContext';
import { ZodiacFEN } from '../../services/zodiacEngine/neat/ZodiacFEN';
import styles from './FENHistory.module.css';
import { Mode, MoveType, Position, State } from '../../services/zodiacEngine/types';
import ZodiacEngineService from '../ZodiacEngineService';

interface MoveEntry {
  fen: string;
  turnNumber: number;
  isLightMove: boolean;
  simplified: string;
  moveType?: MoveType;
  fromPosition?: Position;
  toPosition?: Position;
  pieceType?: string;
  isPreviousGameResult?: boolean;
}

// Mapping of board positions to algebraic notation (A1, B2, etc.)
const getAlgebraicNotation = (position: Position): string => {
  const col = String.fromCharCode(65 + position.col); // A, B, C, D, E
  const row = position.row + 1; // 1-8
  return `${col}${row}`;
};

const FENHistory: React.FC = () => {
  const { gameState, isNNTestMode } = useContext(GameContext)!;
  const [moveHistory, setMoveHistory] = useState<MoveEntry[]>([]);
  const [lastTurnCount, setLastTurnCount] = useState<number>(0);
  const [lastMode, setLastMode] = useState<Mode | null>(null);
  const [lastGameId, setLastGameId] = useState<string | null>(null);
  const [selectedMove, setSelectedMove] = useState<number | null>(null);
  const [lastKnownPositions, setLastKnownPositions] = useState<Map<string, Position>>(new Map());
  const [previousGameLastMove, setPreviousGameLastMove] = useState<string | null>(null);
  const [previousGameWinner, setPreviousGameWinner] = useState<string | null>(null);
  const scrollBoxRef = useRef<HTMLDivElement>(null);

  // Function to mask king information in FEN strings
  const maskKingsInFEN = (fen: string): string => {
    // If not in AI mode or NN test mode, return the original FEN
    if (gameState.mode !== Mode.AI && !isNNTestMode) {
      return fen;
    }

    // Handle FEN turn indicator at the beginning
    let processedFen = fen;
    let turnIndicator = "";

    // If the FEN starts with 'd', it's Dark's turn - preserve this indicator
    if (fen.startsWith("d")) {
      turnIndicator = "d";
      processedFen = fen.substring(1);
    }

    // Remove king markers from the FEN
    const maskedFen = processedFen
      .replace(/([A-Za-z])K/g, "$1")
      .replace(/([A-Za-z])k/g, "$1");

    // Return with turn indicator if it was present
    return turnIndicator + maskedFen;
  };

  // Get displayable FEN (masked if in AI/NN mode)
  const getDisplayFEN = (fen: string): string => {
    // Mask kings in FEN for both AI mode and NN test mode
    if (gameState.mode === Mode.AI || isNNTestMode) {
      return maskKingsInFEN(fen);
    }
    return fen;
  };

  // Record the previous game winner and last move when a game ends in NN test mode
  useEffect(() => {
    if (isNNTestMode && gameState.state === State.PostGame) {
      // Save the winner (if there is one)
      if (gameState.lightWon !== undefined) {
        const winner = gameState.lightWon ? 'Light' : 'Dark';
        setPreviousGameWinner(winner);
      } else {
        setPreviousGameWinner('No winner');
      }
      
      // Save the last move description if there are any moves in the history
      if (moveHistory.length > 1) { // More than just the initial position
        const lastMove = moveHistory[moveHistory.length - 1];
        setPreviousGameLastMove(lastMove.simplified);
      }
    }
  }, [gameState.state, isNNTestMode, gameState.lightWon, moveHistory]);

  // Reset history when the game ID changes or mode changes
  useEffect(() => {
    const currentGameId = ZodiacEngineService.zodiacEngine.getGameId() || null;

    // Check if mode changed, game ID changed, or a game was reset (turnCount = 0 with a different lastTurnCount)
    if (
      lastMode !== gameState.mode ||
      (currentGameId && lastGameId !== currentGameId) ||
      (gameState.turnCount === 0 && lastTurnCount > 0) ||
      (gameState.mode === Mode.AI && gameState.turnCount === 0)
    ) {
      // Add this condition to force reset for AI games
      const initialFen = ZodiacFEN.encode(gameState);
      
      console.log('Resetting FEN history - Mode change or new game detected');
      
      // Build the new history
      const newHistory: MoveEntry[] = [];
      
      // Add previous game result entry at position -1 if in NN test mode and we have a result
      if (isNNTestMode && previousGameWinner && previousGameLastMove) {
        newHistory.push({
          fen: '',
          turnNumber: -1,
          isLightMove: true,
          simplified: `${previousGameWinner} wins: ${previousGameLastMove}`,
          isPreviousGameResult: true
        });
      }
      
      // Add initial position
      newHistory.push({ 
        fen: initialFen, 
        turnNumber: 0,
        isLightMove: true, 
        simplified: gameState.mode === Mode.AI ? 'AI Game Initial Position' : 'Initial position'
      });
      
      setMoveHistory(newHistory);
      setLastTurnCount(gameState.turnCount);
      setLastMode(gameState.mode);
      setLastGameId(currentGameId); // currentGameId is now guaranteed to be string or null
      setSelectedMove(null);

      // Reset known positions
      setLastKnownPositions(new Map());
      updatePiecePositions(gameState.board);
    }
  }, [gameState.mode, gameState, lastTurnCount, lastMode, lastGameId, gameState.turnCount, isNNTestMode, previousGameWinner, previousGameLastMove]);

  // Tracking all piece positions to detect moves
  const updatePiecePositions = (board: any[][]) => {
    const newPositions = new Map<string, Position>();

    // Scan the board for all pieces
    for (let row = 0; row < board.length; row++) {
      for (let col = 0; col < board[row].length; col++) {
        const square = board[row][col];
        if (square && square.Pieces) {
          for (const piece of square.Pieces) {
            // Create a unique ID for this piece (type + light/dark + other properties)
            const pieceId = `${piece.type}_${
              piece.isLight ? "light" : "dark"
            }_${piece.isKing ? "king" : ""}`;
            newPositions.set(pieceId, { row, col });
          }
        }
      }
    }

    setLastKnownPositions(newPositions);
  };

  // Try to determine the move that was made by comparing current and previous board states
  const determineMoveDetails = (): {
    pieceType: string;
    fromPosition?: Position;
    toPosition?: Position;
    moveType?: MoveType;
  } => {
    const newPositions = new Map<string, Position>();
    let movedPieceType = "";
    let fromPos: Position | undefined;
    let toPos: Position | undefined;
    let moveType: MoveType | undefined;

    // Scan the current board for all pieces
    for (let row = 0; row < gameState.board.length; row++) {
      for (let col = 0; col < gameState.board[row].length; col++) {
        const square = gameState.board[row][col];
        if (square && square.Pieces) {
          for (const piece of square.Pieces) {
            // Create a unique ID for this piece
            const pieceId = `${piece.type}_${
              piece.isLight ? "light" : "dark"
            }_${piece.isKing ? "king" : ""}`;
            newPositions.set(pieceId, { row, col });

            // Check if this piece has moved from its last known position
            if (lastKnownPositions.has(pieceId)) {
              const oldPos = lastKnownPositions.get(pieceId)!;

              // If the position is different, this is the piece that moved
              if (oldPos.row !== row || oldPos.col !== col) {
                movedPieceType = piece.type;
                fromPos = oldPos;
                toPos = { row, col };

                // We can also try to determine the move type based on the piece state
                if (piece.isEntangled) {
                  //moveType = MoveType.simpleentangle;
                } else if (piece.isInfected) {
                  moveType = MoveType.infect;
                } else if (piece.isFainted) {
                  moveType = MoveType.faint;
                } else {
                  // Default to regular move
                  moveType = MoveType.regular;
                }
              }
            }
          }
        }
      }
    }

    // Update known positions for next turn
    setLastKnownPositions(newPositions);

    return {
      pieceType: movedPieceType,
      fromPosition: fromPos,
      toPosition: toPos,
      moveType,
    };
  };

  // Add the current game state to history whenever the turn changes
  useEffect(() => {
    if (gameState.turnCount > lastTurnCount) {
      const currentFen = ZodiacFEN.encode(gameState);

      // Determine who just moved to create this board state
      // If isLightTurn is false, that means Light just moved and it's now Dark's turn
      const wasLightMove = !gameState.isLightTurn;

      // Try to determine what move was made
      const { pieceType, fromPosition, toPosition, moveType } =
        determineMoveDetails();

      // Create a detailed move description
      const simplified = createDetailedMoveDescription(
        wasLightMove,
        pieceType,
        fromPosition,
        toPosition,
        moveType
      );

      setMoveHistory((prev) => [
        ...prev,
        {
          fen: currentFen,
          turnNumber: gameState.turnCount,
          isLightMove: wasLightMove,
          simplified,
          moveType,
          fromPosition,
          toPosition,
          pieceType,
        },
      ]);
      setLastTurnCount(gameState.turnCount);
    }
  }, [gameState.turnCount, lastTurnCount, moveHistory.length]);

  // Add initial state when the component mounts
  useEffect(() => {
    if (moveHistory.length === 0) {
      const initialFen = ZodiacFEN.encode(gameState);
      const currentGameId =
        ZodiacEngineService.zodiacEngine.getGameId() || null;

      setMoveHistory([
        {
          fen: initialFen,
          turnNumber: 0,
          isLightMove: true,
          simplified: "Initial position",
        },
      ]);
      setLastMode(gameState.mode);
      setLastGameId(currentGameId); // currentGameId is now guaranteed to be string or null

      // Initialize the piece positions tracking
      updatePiecePositions(gameState.board);
    }
  }, []);

  // Scroll to the bottom when new moves are added
  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
    }
  }, [moveHistory]);

  // Create a detailed description of the move based on move type
  const createDetailedMoveDescription = (
    isLightMove: boolean,
    pieceType: string,
    fromPosition?: Position,
    toPosition?: Position,
    moveType?: MoveType
  ): string => {
    const playerColor = isLightMove ? "Light" : "Dark";

    if (!pieceType || !fromPosition || !toPosition) {
      return `${playerColor} moved`;
    }

    // Get algebraic notation for positions
    const fromSquare = getAlgebraicNotation(fromPosition);
    const toSquare = getAlgebraicNotation(toPosition);

    // Different descriptions based on move type
    switch (moveType) {
      case MoveType.regular:
        return `${playerColor} ${pieceType} moved to ${toSquare}`;
      case MoveType.capture:
        return `${playerColor} ${pieceType} captured on ${toSquare}`;
      case MoveType.traverse:
        return `${playerColor} ${pieceType} traversed to ${toSquare}`;
      case MoveType.leap:
        return `${playerColor} ${pieceType} leaped to ${toSquare}`;
      //case MoveType.simpleentangle:
      case MoveType.moveentangle:
      case MoveType.splitentangle:
        return `${playerColor} ${pieceType} entangled on ${toSquare}`;
      case MoveType.infect:
        return `${playerColor} ${pieceType} infected on ${toSquare}`;
      case MoveType.faint:
        return `${playerColor} ${pieceType} fainted at ${toSquare}`;
      case MoveType.house:
        return `${playerColor} ${pieceType} housed at ${toSquare}`;
      case MoveType.trample:
        return `${playerColor} ${pieceType} trampled to ${toSquare}`;
      case MoveType.ink:
        return `${playerColor} ${pieceType} inked at ${toSquare}`;
      case MoveType.rotate:
        return `${playerColor} ${pieceType} rotated at ${toSquare}`;
      case MoveType.swing:
        return `${playerColor} ${pieceType} swung to ${toSquare}`;
      case MoveType.lane:
        return `${playerColor} ${pieceType} laned to ${toSquare}`;
      default:
        return `${playerColor} ${pieceType} moved from ${fromSquare} to ${toSquare}`;
    }
  };

  // Legacy simplified description (as fallback)
  const createSimplifiedDescription = (
    fen: string,
    isLightMove: boolean
  ): string => {
    const pieceSymbols = {
      B: "Bear",
      b: "Bear",
      C: "Crane",
      c: "Crane",
      F: "Frog",
      f: "Frog",
      G: "Goat",
      g: "Goat",
      M: "Monkey",
      m: "Monkey",
      U: "Fungi",
      u: "Fungi",
      O: "Octopus",
      o: "Octopus",
      P: "Penguin",
      p: "Penguin",
      R: "Rhino",
      r: "Rhino",
      S: "Snake",
      s: "Snake",
      T: "Tree",
      t: "Tree",
      W: "Wolf",
      w: "Wolf",
    };

    // Remove the turn indicator if present
    const cleanFen = fen.startsWith("d") ? fen.substring(1) : fen;

    // Extract pieces from FEN
    const pieceMatches = cleanFen.match(/[BCFGMUOPRSTWbcfgmuoprstw]/g);
    const pieceTypes = pieceMatches
      ? Array.from(new Set(pieceMatches)).map(
          (p) => pieceSymbols[p as keyof typeof pieceSymbols]
        )
      : [];

    // Simple description based on pieces present
    const playerColor = isLightMove ? "Light" : "Dark";

    if (pieceTypes.length > 0) {
      return `${playerColor} moved${
        pieceTypes.length < 3 ? " " + pieceTypes.join("/") : ""
      }`;
    }

    return `${playerColor} move`;
  };

  const handleMoveClick = (index: number) => {
    setSelectedMove(index === selectedMove ? null : index);
  };

  return (
    <div className={styles.fenHistoryContainer}>
      <h3>Move History</h3>
      <div ref={scrollBoxRef} className={styles.scrollBox}>
        {moveHistory.map((move, index) => (
          <div 
            key={index} 
            className={`${styles.fenEntry} ${selectedMove === index ? styles.selected : ''} ${move.isPreviousGameResult ? styles.previousGame : ''}`}
            onClick={() => handleMoveClick(index)}
          >
            <span className={styles.moveNumber}>{move.turnNumber}</span>
            <div className={styles.moveContent}>
              <div className={styles.simplified}>{move.simplified}</div>
              {!move.isPreviousGameResult && <div className={styles.fenNotation}>{getDisplayFEN(move.fen)}</div>}
            </div>
            {selectedMove === index && !move.isPreviousGameResult && (
              <div className={styles.moveDetails}>
                <div className={styles.detailsHeader}>FEN Details</div>
                <div className={styles.detailsContent}>
                  {getDisplayFEN(move.fen)}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FENHistory;
