import { allPieceTypes, directions } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  ISquare,
  IMove,
  MoveType,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class MonkeyMovement extends BaseMovement implements PieceMovement {
  private readonly capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Regular movement and capture
    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    possibleMoves.push(...regularMoves);

    // Jumping movement
    const jumpMoves = this.getValidJumpMoves(piece, position, boardState);
    possibleMoves.push(...jumpMoves);

    // Interaction with Tree piece
    const treeMoves = this.getValidTreeMoves(piece, position, boardState);
    possibleMoves.push(...treeMoves);

    // Debug logging
    console.log(
      `Monkey at ${position.row},${position.col} has ${possibleMoves.length} possible moves`
    );

    return possibleMoves;
  }

  private getValidRegularMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const destinationPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, destinationPosition)) {
        continue;
      }

      const destinationSquare =
        boardState[destinationPosition.row][destinationPosition.col];

      // Check if the target square has multiple pieces (potentially a tree housing another piece)
      if (destinationSquare.Pieces.length > 1) {
        // Check if one of the pieces is a tree
        const hasTree = destinationSquare.Pieces.some(
          (p) => p.type === PieceType.Tree
        );

        if (hasTree) {
          // Find the non-tree piece in the square
          const housedPiece = destinationSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );

          if (housedPiece) {
            if (housedPiece.isLight === piece.isLight) {
              // If the housed piece is friendly, skip this move
              continue;
            } else {
              // If the housed piece is an enemy, create a capture move
              possibleMoves.push(
                new Move(destinationPosition, MoveType.capture, housedPiece)
              );
              continue;
            }
          }
        }
      }

      const squareInfo = this.getSquareInfo(destinationSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          destinationPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private getValidJumpMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // ========== SINGLE JUMP ==========
    for (const direction of directions) {
      const piecePosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };
      const endPosition = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };

      if (
        this.onBoard(boardState, piecePosition) &&
        this.onBoard(boardState, endPosition) &&
        this.isValidJumpPiece(
          boardState[piecePosition.row][piecePosition.col],
          piece.isLight
        )
      ) {
        const endSquare = boardState[endPosition.row][endPosition.col];

        // Check if the destination contains a tree with a housed piece
        const treePiece = endSquare.Pieces.find(
          (p) => p.type === PieceType.Tree
        );
        if (treePiece && endSquare.Pieces.length > 1) {
          // Find the housed piece
          const housedPiece = endSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );
          if (
            housedPiece &&
            housedPiece.isLight === piece.isLight &&
            housedPiece.type !== PieceType.Fungi
          ) {
            // Skip if there's a friendly non-fungi piece in the tree
            continue;
          }
        }

        const endSquareInfo = this.getSquareInfo(endSquare, piece.isLight);

        if (endSquareInfo.basicSquareInfo) {
          const occupantPiece = endSquareInfo.basicSquareInfo.piece || null;

          // Handle landing in a tree specially - use house move instead of leap
          if (occupantPiece && occupantPiece.type === PieceType.Tree) {
            // Empty tree or tree with capturable piece
            if (
              endSquare.Pieces.length === 1 ||
              (endSquare.Pieces.length > 1 &&
                endSquare.Pieces.some(
                  (p) =>
                    p.type !== PieceType.Tree && p.isLight !== piece.isLight
                ))
            ) {
              possibleMoves.push(
                new Move(endPosition, MoveType.house, occupantPiece)
              );
            }
          }
          // Regular leap landing
          else if (!occupantPiece || occupantPiece.isLight !== piece.isLight) {
            possibleMoves.push(
              new Move(endPosition, MoveType.leap, occupantPiece)
            );
          }
        }
      }
    }

    // ========== DOUBLE JUMP ==========
    for (const direction of directions) {
      const piecePosition1 = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };
      const emptyPosition = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };
      const piecePosition2 = {
        col: position.col + direction[0] * 3,
        row: position.row + direction[1] * 3,
      };
      const endPosition = {
        col: position.col + direction[0] * 4,
        row: position.row + direction[1] * 4,
      };

      if (
        this.onBoard(boardState, piecePosition1) &&
        this.onBoard(boardState, emptyPosition) &&
        this.onBoard(boardState, piecePosition2) &&
        this.onBoard(boardState, endPosition) &&
        this.isValidJumpPiece(
          boardState[piecePosition1.row][piecePosition1.col],
          piece.isLight
        ) &&
        this.isEmptySquare(boardState[emptyPosition.row][emptyPosition.col]) &&
        this.isValidJumpPiece(
          boardState[piecePosition2.row][piecePosition2.col],
          piece.isLight
        )
      ) {
        const endSquare = boardState[endPosition.row][endPosition.col];

        // Check if the destination contains a tree with a housed piece
        const treePiece = endSquare.Pieces.find(
          (p) => p.type === PieceType.Tree
        );
        if (treePiece && endSquare.Pieces.length > 1) {
          // Find the housed piece
          const housedPiece = endSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );
          if (
            housedPiece &&
            housedPiece.isLight === piece.isLight &&
            housedPiece.type !== PieceType.Fungi
          ) {
            // Skip if there's a friendly non-fungi piece in the tree
            continue;
          }
        }

        const endSquareInfo = this.getSquareInfo(endSquare, piece.isLight);

        if (endSquareInfo.basicSquareInfo) {
          const occupantPiece = endSquareInfo.basicSquareInfo.piece || null;

          // Handle landing in a tree specially - use house move instead of leap
          if (occupantPiece && occupantPiece.type === PieceType.Tree) {
            // Empty tree or tree with capturable piece
            if (
              endSquare.Pieces.length === 1 ||
              (endSquare.Pieces.length > 1 &&
                endSquare.Pieces.some(
                  (p) =>
                    p.type !== PieceType.Tree && p.isLight !== piece.isLight
                ))
            ) {
              possibleMoves.push(
                new Move(endPosition, MoveType.house, occupantPiece)
              );
            }
          }
          // Regular leap landing
          else if (!occupantPiece || occupantPiece.isLight !== piece.isLight) {
            possibleMoves.push(
              new Move(endPosition, MoveType.leap, occupantPiece)
            );
          }
        }
      }
    }

    // ========== TRIPLE JUMP ==========
    for (const direction of directions) {
      // Jump over three pieces with empty spaces between them
      // This creates a pattern of: [piece]-[empty]-[piece]-[empty]-[piece]-[landing]
      const firstJumpedPiecePos = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };
      const firstEmptyPos = {
        col: position.col + direction[0] * 2,
        row: position.row + direction[1] * 2,
      };
      const secondJumpedPiecePos = {
        col: position.col + direction[0] * 3,
        row: position.row + direction[1] * 3,
      };
      const secondEmptyPos = {
        col: position.col + direction[0] * 4,
        row: position.row + direction[1] * 4,
      };
      const thirdJumpedPiecePos = {
        col: position.col + direction[0] * 5,
        row: position.row + direction[1] * 5,
      };
      const landingPos = {
        col: position.col + direction[0] * 6,
        row: position.row + direction[1] * 6,
      };

      // Check all positions are on the board
      if (
        !this.onBoard(boardState, firstJumpedPiecePos) ||
        !this.onBoard(boardState, firstEmptyPos) ||
        !this.onBoard(boardState, secondJumpedPiecePos) ||
        !this.onBoard(boardState, secondEmptyPos) ||
        !this.onBoard(boardState, thirdJumpedPiecePos) ||
        !this.onBoard(boardState, landingPos)
      ) {
        continue; // Skip if any position is off the board
      }

      // Get the squares at each position
      const firstJumpedPieceSquare =
        boardState[firstJumpedPiecePos.row][firstJumpedPiecePos.col];
      const firstEmptySquare = boardState[firstEmptyPos.row][firstEmptyPos.col];
      const secondJumpedPieceSquare =
        boardState[secondJumpedPiecePos.row][secondJumpedPiecePos.col];
      const secondEmptySquare =
        boardState[secondEmptyPos.row][secondEmptyPos.col];
      const thirdJumpedPieceSquare =
        boardState[thirdJumpedPiecePos.row][thirdJumpedPiecePos.col];
      const landingSquare = boardState[landingPos.row][landingPos.col];

      // Check if the jumped pieces are valid for jumping over
      if (
        !this.isValidJumpPiece(firstJumpedPieceSquare, piece.isLight) ||
        !this.isEmptySquare(firstEmptySquare) ||
        !this.isValidJumpPiece(secondJumpedPieceSquare, piece.isLight) ||
        !this.isEmptySquare(secondEmptySquare) ||
        !this.isValidJumpPiece(thirdJumpedPieceSquare, piece.isLight)
      ) {
        continue; // Skip if any piece is invalid for jumping over
      }

      // Check if the landing square has a tree with a friendly piece
      const treePiece = landingSquare.Pieces.find(
        (p) => p.type === PieceType.Tree
      );
      if (treePiece && landingSquare.Pieces.length > 1) {
        // Find any non-tree piece on the landing square
        const housedPiece = landingSquare.Pieces.find(
          (p) => p.type !== PieceType.Tree
        );
        if (
          housedPiece &&
          housedPiece.isLight === piece.isLight &&
          housedPiece.type !== PieceType.Fungi
        ) {
          // Skip if there's a friendly non-fungi piece in the tree
          continue;
        }
      }

      // Get info about the landing square
      const landingSquareInfo = this.getSquareInfo(
        landingSquare,
        piece.isLight
      );
      if (!landingSquareInfo.basicSquareInfo) continue;

      const landingPiece = landingSquareInfo.basicSquareInfo.piece;

      // Handle landing in a tree
      if (landingPiece && landingPiece.type === PieceType.Tree) {
        // Tree landing is valid if the tree is empty or has a capturable enemy piece
        if (
          landingSquare.Pieces.length === 1 ||
          (landingSquare.Pieces.length > 1 &&
            landingSquare.Pieces.some(
              (p) => p.type !== PieceType.Tree && p.isLight !== piece.isLight
            ))
        ) {
          possibleMoves.push(
            new Move(landingPos, MoveType.house, landingPiece)
          );
        }
      }
      // Handle landing on empty square or enemy piece
      else if (!landingPiece || landingPiece.isLight !== piece.isLight) {
        possibleMoves.push(
          new Move(landingPos, MoveType.leap, landingPiece || null)
        );
      }
    }

    return possibleMoves;
  }

  private isValidJumpPiece(square: ISquare, isLight: boolean): boolean {
    // If we have a tree on this square, it's always valid to jump over
    const hasTrepiece = square.Pieces.some((p) => p.type === PieceType.Tree);
    if (hasTrepiece) {
      return true;
    }

    const squareInfo = this.getSquareInfo(square, isLight);

    if (squareInfo.basicSquareInfo?.piece) {
      const { piece } = squareInfo.basicSquareInfo;
      // Only prevent jumping over Viruses and fainted Goats
      return piece.type !== PieceType.Virus && !this.isFaintedGoat(piece);
    }

    return false;
  }

  private isFaintedGoat(piece: IPiece | null): boolean {
    return piece?.type === PieceType.Goat && piece?.isFainted === true;
  }

  private isEmptySquare(square: ISquare): boolean {
    return square.Pieces.length === 0;
  }

  private getValidTreeMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];
    const currentSquare = boardState[position.row][position.col];
    const currentSquareInfo = this.getSquareInfo(currentSquare, piece.isLight);

    if (!currentSquareInfo.housedTreeSquareInfo) {
      for (const direction of directions) {
        const destinationPosition = {
          col: position.col + direction[0],
          row: position.row + direction[1],
        };
        if (!this.onBoard(boardState, destinationPosition)) continue;
        const destinationSquare =
          boardState[destinationPosition.row][destinationPosition.col];

        // Check if destination has a tree
        const treePiece = destinationSquare.Pieces.find(
          (p) => p.type === PieceType.Tree
        );
        if (!treePiece) continue;

        // Check if tree already has a housed piece
        if (treePiece.isHousing || destinationSquare.Pieces.length > 1) {
          // Find any non-tree piece on this square
          const housedPiece = destinationSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );
          if (!housedPiece) continue;

          // Special case: Monkey can move into a tree with friendly fungi to consume it
          if (
            housedPiece.type === PieceType.Fungi &&
            housedPiece.isLight === piece.isLight
          ) {
            possibleMoves.push(
              new Move(destinationPosition, MoveType.house, treePiece)
            );
            continue;
          }

          // If it's a friendly piece (other than fungi), can't move there
          if (housedPiece.isLight === piece.isLight) {
            continue;
          }

          // If it's an enemy piece and we can capture it
          if (
            housedPiece.isLight !== piece.isLight &&
            this.capturablePieceTypes.includes(housedPiece.type)
          ) {
            possibleMoves.push(
              new Move(destinationPosition, MoveType.house, treePiece)
            );
          }
          continue;
        }

        // Empty tree - monkey can only house in friendly trees
        if (treePiece.isLight === piece.isLight) {
          possibleMoves.push(
            new Move(destinationPosition, MoveType.house, treePiece)
          );
        }
      }
    }
    return possibleMoves;
  }

  private getJumpPath(
    start: Position,
    end: Position,
    boardState: ISquare[][]
  ): ISquare[] {
    const path: ISquare[] = [];
    const dx = Math.sign(end.col - start.col);
    const dy = Math.sign(end.row - start.row);
    let { col, row } = start;

    while (col !== end.col || row !== end.row) {
      col += dx;
      row += dy;
      if (
        col < 0 ||
        col >= boardState[0].length ||
        row < 0 ||
        row >= boardState.length
      )
        break;
      path.push(boardState[row][col]);
    }
    return path;
  }
}
