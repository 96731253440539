import { IMove, Position, GameState } from "../types";
import { ZodiacEngine } from "../zodiacEngine";

/**
 * Interface for move evaluation heuristics
 * Each heuristic implementation provides a way to evaluate potential moves
 */
export interface HeuristicInterface {
  /**
   * Name of the heuristic
   */
  name: string;

  /**
   * Filters legal moves according to a policy before evaluation
   * This allows pruning undesirable moves early to make the MCTS more efficient
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param legalMoves Array of legal moves to filter
   * @returns Filtered array of legal moves that pass the policy
   */
  policyFilter(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    legalMoves: IMove[]
  ): IMove[];

  /**
   * Evaluates a move and returns a score
   * Higher scores indicate better moves according to the heuristic
   *
   * @param engine The ZodiacEngine instance
   * @param gameState Current game state
   * @param fromPosition Position of the piece to move
   * @param move The potential move to evaluate
   * @returns Score for this move (higher is better)
   */
  evaluateMove(
    engine: ZodiacEngine,
    gameState: GameState,
    fromPosition: Position,
    move: IMove
  ): number;
}

/**
 * Enum of available heuristics
 */
export enum HeuristicType {
  Random = "Random",
  Rhino = "Rhino",
  Virus = "Virus",
}

/**
 * Factory to get heuristic by type
 */
export function getHeuristic(type: HeuristicType): HeuristicInterface {
  switch (type) {
    case HeuristicType.Random:
      return new (require("./RandomHeuristic").RandomHeuristic)();
    case HeuristicType.Rhino:
      return new (require("./RhinoHeuristic").RhinoHeuristic)();
    case HeuristicType.Virus:
      return new (require("./VirusHeuristic").VirusHeuristic)();
    default:
      return new (require("./RandomHeuristic").RandomHeuristic)();
  }
}
