import {
  allPieceTypes,
  directions,
  largePieceTypes,
  predatorPieceTypes,
} from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class CraneMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes.filter(
    (piece) =>
      !largePieceTypes.includes(piece) && !predatorPieceTypes.includes(piece)
  );

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];
    const movementRange = 2;

    for (const direction of directions) {
      for (let i = 1; i <= movementRange; i++) {
        const toPosition = {
          col: position.col + direction[0] * i,
          row: position.row + direction[1] * i,
        };

        if (!this.onBoard(boardState, toPosition)) break;

        const toSquare = boardState[toPosition.row][toPosition.col];

        // Check if the target square has multiple pieces (potentially a tree housing another piece)
        if (toSquare.Pieces.length > 1) {
          // Check if one of the pieces is a tree
          const hasTree = toSquare.Pieces.some(
            (p) => p.type === PieceType.Tree
          );

          if (hasTree) {
            // Find the non-tree piece in the square
            const housedPiece = toSquare.Pieces.find(
              (p) => p.type !== PieceType.Tree
            );

            if (housedPiece) {
              if (housedPiece.isLight === piece.isLight) {
                // If the housed piece is friendly, skip this move
                continue;
              } else if (this.capturablePieceTypes.includes(housedPiece.type)) {
                // If the housed piece is an enemy and capturable by crane, create a capture move
                possibleMoves.push(
                  new Move(toPosition, MoveType.capture, housedPiece)
                );
                continue;
              } else {
                // If the housed piece is not capturable (like a bear), skip
                continue;
              }
            }
          }
        }

        const squareInfo = this.getSquareInfo(toSquare, piece.isLight);
        if (squareInfo.basicSquareInfo) {
          var move = this.getMove(
            position,
            toPosition,
            squareInfo.basicSquareInfo,
            piece,
            this.capturablePieceTypes
          );

          if (move) {
            possibleMoves.push(move);
            console.log("Generated move:", move);
          } else if (
            squareInfo.basicSquareInfo.piece &&
            squareInfo.basicSquareInfo.piece.type === PieceType.Tree &&
            squareInfo.basicSquareInfo.piece.isLight !== piece.isLight
          ) {
            var houseMove = new Move(
              toPosition,
              MoveType.house,
              squareInfo.basicSquareInfo.piece
            );
            possibleMoves.push(houseMove);
            console.log("Generated move (house):", houseMove);
          }
        }
      }
    }

    return possibleMoves;
  }
}
