import { ZodiacEngine } from "../services/zodiacEngine/zodiacEngine";
import { PieceType } from "../services/zodiacEngine/types";

/**
 * Singleton service to ensure consistent access to ZodiacEngine
 */
class ZodiacEngineService {
  static instance: ZodiacEngineService;
  public zodiacEngine!: ZodiacEngine;
  private storageInitialized = false;

  constructor() {
    if (ZodiacEngineService.instance) {
      return ZodiacEngineService.instance;
    }
    this.zodiacEngine = new ZodiacEngine();
    // Disable verbose logging by default to reduce console noise
    this.zodiacEngine.setVerboseLogging(false);
    ZodiacEngineService.instance = this;
  }

  /**
   * Convert a FEN character to its corresponding PieceType
   * @param fenChar FEN character (uppercase for light, lowercase for dark)
   * @returns The corresponding PieceType or undefined if not found
   */
  getPieceTypeFromFENChar(fenChar: string): PieceType | undefined {
    const upperChar = fenChar.toUpperCase();

    switch (upperChar) {
      case "B":
        return PieceType.Bear;
      case "C":
        return PieceType.Crane;
      case "F":
        return PieceType.Frog;
      case "G":
        return PieceType.Goat;
      case "M":
        return PieceType.Monkey;
      case "U":
        return PieceType.Fungi;
      case "O":
        return PieceType.Octopus;
      case "P":
        return PieceType.Penguin;
      case "R":
        return PieceType.Rhino;
      case "S":
        return PieceType.Snake;
      case "T":
        return PieceType.Tree;
      case "W":
        return PieceType.Wolf;
      case "V":
        return PieceType.Virus;
      default:
        return undefined;
    }
  }
}

const engineService = new ZodiacEngineService();
export default engineService;
