import { GameStateManager } from "./Managers/GameStateManager";
import { MovementManager } from "./Managers/MovementManager";
import { EventManager } from "./Managers/EventManager";
import {
  GameState,
  State,
  PieceType,
  MoveType,
  ISideEffect,
  Mode,
} from "./types";
import { Piece } from "./Piece";
import { IMove, IPiece, Position } from "./types";
import { AIController } from "./AIController";
import { allClimbingPieces } from "./const";
import { HeuristicType } from "./heuristics";

// Simple replacement enum for NNTestingMode since the original is removed
export enum NNTestingMode {
  Off = 0,
  ValueOnly = 1,
  PolicyOnly = 2,
  Full = 3,
}

export type GameStateListener = () => void;

export class ZodiacEngine {
  private eventManager: EventManager;
  private gameStateManager: GameStateManager;
  private movementManager: MovementManager;
  public aiController: AIController | null = null;
  public neatAIController: null = null;
  private verboseLogging: boolean = false;
  private useNeuralNetwork: boolean = false; // Default to not using neural network since it's removed

  constructor() {
    this.gameStateManager = new GameStateManager();
    this.movementManager = new MovementManager(this.gameStateManager);
    this.eventManager = new EventManager(this.gameStateManager);
  }

  // Game state and game modes
  getGameState(): GameState {
    return { ...this.gameStateManager.getGameState() };
  }

  async switchToLiveMove(playerId: string): Promise<string> {
    return await this.eventManager.setupLiveMode(playerId);
  }

  switchToDevMove() {
    this.eventManager.setupDevMode();
  }

  /**
   * Switches to AI mode and initializes the AI controller
   * @param playerId The ID of the human player
   * @param lightKing The type of king for the light player
   * @param darkKing The type of king for the dark player
   * @param useNeuralNetwork Whether to use the neural network enhanced AI (default: false)
   */
  switchToAIMode(
    playerId: string,
    lightKing: PieceType,
    darkKing: PieceType,
    useNeuralNetwork: boolean = false
  ) {
    // Reset the game first
    this.gameStateManager.resetGame();

    // Force empty the board to avoid any leftover state
    this.gameStateManager.emptyBoard();

    // Set it to AI mode
    this.gameStateManager.setMode(Mode.AI);

    // Set the light player
    this.gameStateManager.setLightPlayer(playerId);

    // Set the dark player as AI
    this.gameStateManager.setDarkPlayer("AI");

    // Set both kings
    const gameState = this.gameStateManager.getGameState();
    gameState.lightKing = lightKing;
    gameState.darkKing = darkKing;

    // Skip directly to Playing state
    gameState.state = State.Playing;

    // Place 5 random pieces on each side (including kings)
    this.placeRandomStartingPieces(lightKing, darkKing);

    // Store whether to use neural network
    this.useNeuralNetwork = false; // Always false because neural network has been removed

    // Initialize standard AI controller
    if (!this.aiController) {
      this.aiController = new AIController(this);
      this.aiController.setSearchIterations(500);
      console.log("AI controller initialized during switchToAIMode");
    }

    // Just notify local listeners - no remote updates
    this.eventManager.notifyLocalListeners();
  }

  placeRandomStartingPieces(lightKing: PieceType, darkKing: PieceType) {
    // Create array of potential piece types (excluding Penguin, Octopus, and Virus)
    const eligiblePieces = [
      PieceType.Bear,
      PieceType.Crane,
      PieceType.Frog,
      PieceType.Fungi,
      PieceType.Goat,
      PieceType.Monkey,
      PieceType.Rhino,
      PieceType.Snake,
      PieceType.Tree,
      PieceType.Wolf,
    ];

    // Light pieces (row 0)
    const lightPositions = this.getRandomPositions(5, 0);

    // Track which pieces are already placed for light team
    const lightPiecesUsed: PieceType[] = [lightKing];

    // Choose a random position for the light king
    const lightKingPosition =
      lightPositions[Math.floor(Math.random() * lightPositions.length)];
    const lightKingPiece = new Piece(lightKing, true);
    lightKingPiece.isKing = true;
    this.gameStateManager.placePiece(lightKingPiece, lightKingPosition);

    // Place 4 more random pieces for light
    let lightPiecesPlaced = 1; // King is already placed
    for (const position of lightPositions) {
      // Skip the king's position
      if (
        position.row === lightKingPosition.row &&
        position.col === lightKingPosition.col
      ) {
        continue;
      }

      if (lightPiecesPlaced < 5) {
        // Get random piece type that hasn't been used yet
        const availablePieces = eligiblePieces.filter(
          (piece) => !lightPiecesUsed.includes(piece)
        );

        // If we've used all available pieces, we can't place any more
        if (availablePieces.length === 0) break;

        const randomPiece =
          availablePieces[Math.floor(Math.random() * availablePieces.length)];
        this.gameStateManager.placePiece(
          new Piece(randomPiece, true),
          position
        );

        // Track the piece as used
        lightPiecesUsed.push(randomPiece);
        lightPiecesPlaced++;
      }
    }

    // Dark pieces (row 7)
    const darkPositions = this.getRandomPositions(5, 7);

    // Track which pieces are already placed for dark team
    const darkPiecesUsed: PieceType[] = [darkKing];

    // Choose a random position for the dark king
    const darkKingPosition =
      darkPositions[Math.floor(Math.random() * darkPositions.length)];
    const darkKingPiece = new Piece(darkKing, false);
    darkKingPiece.isKing = true;
    this.gameStateManager.placePiece(darkKingPiece, darkKingPosition);

    // Place 4 more random pieces for dark
    let darkPiecesPlaced = 1; // King is already placed
    for (const position of darkPositions) {
      // Skip the king's position
      if (
        position.row === darkKingPosition.row &&
        position.col === darkKingPosition.col
      ) {
        continue;
      }

      if (darkPiecesPlaced < 5) {
        // Get random piece type that hasn't been used yet
        const availablePieces = eligiblePieces.filter(
          (piece) => !darkPiecesUsed.includes(piece)
        );

        // If we've used all available pieces, we can't place any more
        if (availablePieces.length === 0) break;

        const randomPiece =
          availablePieces[Math.floor(Math.random() * availablePieces.length)];
        this.gameStateManager.placePiece(
          new Piece(randomPiece, false),
          position
        );

        // Track the piece as used
        darkPiecesUsed.push(randomPiece);
        darkPiecesPlaced++;
      }
    }
  }

  getRandomPositions(count: number, row: number): Position[] {
    // Get 'count' random positions in the specified row
    const positions: Position[] = [];
    // The board is 5 columns (0-4), not 8
    const cols = [0, 1, 2, 3, 4];

    // Shuffle columns randomly
    for (let i = cols.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [cols[i], cols[j]] = [cols[j], cols[i]]; // Swap elements
    }

    // Take the first 'count' columns (or all if count > cols.length)
    const numToTake = Math.min(count, cols.length);
    for (let i = 0; i < numToTake; i++) {
      positions.push({ row, col: cols[i] });
    }

    return positions;
  }

  // Event Handling
  subscribeToGameStateChanges(listener: GameStateListener): void {
    this.eventManager.subscribeToGameStateChanges(listener);
  }

  unsubscribeToGameStateChanges(listener: GameStateListener): void {
    this.eventManager.unsubscribeToGameStateChanges(listener);
  }

  ungracefulTermination() {
    if (this.eventManager.getGameId()) {
      this.gameStateManager.getGameState().state = State.Terminated;
      console.log(this.eventManager.getGameId());
      console.log("Ungraceful termination");
    }
  }

  getGameId(): string | undefined {
    return this.eventManager.getGameId();
  }

  joinGame(gameId: string, playerId: string) {
    this.eventManager.joinGame(gameId, playerId);
  }

  // Piece getting
  placePiece(piece: IPiece, position: Position): void {
    // In dev mode, set hasMoved to true for Frogs if they're not on their correct starting rank
    if (
      this.gameStateManager.getGameState().mode === Mode.Dev &&
      piece.type === PieceType.Frog
    ) {
      // Light frogs should be on rank 0, dark frogs should be on rank 7
      const correctRank = piece.isLight ? 0 : 7;
      if (position.row !== correctRank) {
        piece.hasMoved = true;
      }
    }

    // Check if the target square already has pieces
    const currentPieces = this.gameStateManager.getPiecesAt(position);
    if (currentPieces.length > 0) {
      // Check if one of the pieces is a tree
      const hasTree = currentPieces.some((p) => p.type === PieceType.Tree);

      if (hasTree) {
        // If there's already a tree with a piece in it, don't allow placing another piece
        if (currentPieces.length > 1) {
          console.log(
            "Cannot place piece in a tree that already has a piece in it"
          );
          return;
        }

        // Check if the piece being placed can climb trees
        if (!allClimbingPieces.includes(piece.type)) {
          console.log(`${piece.type} cannot climb trees`);
          return;
        }
      }
    }

    this.gameStateManager.placePiece(piece, position);
    this.eventManager.notifyLocalListeners();
  }

  getPiecesAt(treePosition: { row: number; col: number }): IPiece[] {
    return this.gameStateManager.getPiecesAt(treePosition);
  }

  setKing(pieceType: PieceType, isLight: boolean) {
    var gameState = this.gameStateManager.getGameState();
    var text = isLight ? "light" : "dark";
    if (gameState.state !== State.KingSelection)
      throw Error(`The game state is incorrect to set a ${text} king`);

    if ((isLight && gameState.lightKing) || (!isLight && gameState.darkKing))
      throw Error(`The ${text} king is already set`);

    if (isLight) gameState.lightKing = pieceType;
    else gameState.darkKing = pieceType;

    if (gameState.lightKing && gameState.darkKing)
      this.gameStateManager.nextGameState();

    this.eventManager.notifyLocalListeners();
    this.eventManager.sendUpdateToRemote();
  }

  // Special method for AI mode to set kings directly without state validation
  setKingsForAI(lightKing: PieceType, darkKing: PieceType) {
    var gameState = this.gameStateManager.getGameState();

    // Set kings
    gameState.lightKing = lightKing;
    gameState.darkKing = darkKing;

    // Make sure we're in the Playing state
    gameState.state = State.Playing;
    gameState.mode = Mode.AI;

    this.eventManager.notifyLocalListeners();
    this.eventManager.sendUpdateToRemote();
  }

  setStartingPiece(pieceType: PieceType, isLight: boolean, col: number) {
    this.gameStateManager.setStartingPiece(pieceType, isLight, col);
    this.eventManager.sendUpdateToRemote();
    this.eventManager.notifyLocalListeners();
  }

  // Movement Handling
  getValidMoves(position: Position, piece: IPiece): IMove[] {
    return this.movementManager.getValidMoves(position, piece);
  }

  makeMove(
    fromPosition: Position,
    toPosition: Position,
    piece: IPiece,
    toPiece: IPiece | null,
    moveType: MoveType,
    sideEffects: ISideEffect[]
  ): boolean {
    const moveMade = this.movementManager.makeMove(
      fromPosition,
      toPosition,
      piece,
      toPiece,
      moveType,
      sideEffects
    );
    if (moveMade) {
      // Notify listeners and send updates if necessary
      this.eventManager.notifyLocalListeners();
      this.eventManager.sendUpdateToRemote();

      this.runPostTurn();

      this.checkGameEnd();
    }
    return moveMade;
  }

  checkGameEnd() {
    if (this.gameStateManager.getGameState().state === State.PostGame) {
      // Already game over, nothing more to do
      return;
    }

    if (this.gameStateManager.hasGameEnded()) {
      this.gameStateManager.getGameState().state = State.PostGame;
      const winningColor = this.gameStateManager.getGameState().lightWon
        ? "Light"
        : "Dark";
      // Keep this log since it's actual game outcome
      console.log(`Game Over! Winner: ${winningColor}`);
      this.gameStateManager.nextGameState();
      this.eventManager.notifyLocalListeners();
      this.eventManager.sendUpdateToRemote();
    } else {
      // Change turns for both Live and AI modes
      if (this.verboseLogging) {
        console.log("Changing turns in checkGameEnd");
      }
      this.changeTurns();
    }
  }

  runPostTurn() {
    var pieces = this.gameStateManager.getAllPieces();
    pieces.forEach((piece) => {
      // piece.effects.forEach((effect) => {
      //   // if (effect instanceof ITurnCountEffect) {
      //   // }
      // });
    });
    // here is where we can run post turn effects eg
    // disabling a poisoned state
    //
  }

  changeTurns() {
    this.gameStateManager.changeTurns();
    this.eventManager.notifyLocalListeners();
    this.eventManager.sendUpdateToRemote();
  }

  /**
   * Makes a move using the AI controller
   * @returns Whether a move was successfully made
   */
  async makeAIMove(): Promise<boolean> {
    // Log debug info
    console.log(
      "makeAIMove: AI turn, isLightTurn =",
      this.gameStateManager.getGameState().isLightTurn
    );
    console.log("makeAIMove: aiController =", !!this.aiController);
    console.log("makeAIMove: neatAIController =", !!this.neatAIController);

    // Force use standard AI controller
    this.useNeuralNetwork = false;

    // Use the standard AI controller
    if (!this.aiController) {
      this.aiController = new AIController(this);
      this.aiController.setSearchIterations(1000);
      console.log("AI controller initialized during makeAIMove");
    }

    try {
      // Have the AI make a move
      const moveResult = await this.aiController.makeMove();
      return moveResult;
    } catch (error) {
      console.error("Error during AI move:", error);
      return false;
    }
  }

  /**
   * Sets whether to use the Neural Network enhanced AI
   * @param useNN Whether to use neural network
   */
  setUseNeuralNetwork(useNN: boolean): void {
    this.useNeuralNetwork = useNN;
    if (this.verboseLogging) {
      console.log(
        `AI type set to: ${useNN ? "Neural Network" : "Standard MCTS"}`
      );
    }
  }

  /**
   * Gets whether the Neural Network enhanced AI is being used
   * @returns Whether neural network is being used
   */
  isUsingNeuralNetwork(): boolean {
    return this.useNeuralNetwork;
  }

  /**
   * Checks if we're in NN test mode
   * This is a combination of using neural network and being in AI mode
   * @returns true if in NN test mode
   */
  isInNNTestMode(): boolean {
    return false;
  }

  /**
   * Sets the number of search iterations for the AI
   * @param iterations Number of iterations to perform during search
   */
  setAISearchIterations(iterations: number): void {
    // Neural network functionality has been removed
    // Only use standard AI controller
    if (!this.aiController) {
      this.aiController = new AIController(this);
    }
    this.aiController.setSearchIterations(iterations);
  }

  /**
   * Sets the exploration parameter for the AI's MCTS algorithm
   * Higher values (>1.0) favor exploration of new moves
   * Lower values (<1.0) favor exploitation of known good moves
   *
   * @param parameter The exploration parameter value (default is Math.sqrt(2) ≈ 1.414)
   */
  setAIExplorationParameter(parameter: number): void {
    // Neural network functionality has been removed
    // Only use standard AI controller
    if (!this.aiController) {
      this.aiController = new AIController(this);
    }
    this.aiController.setExplorationParameter(parameter);
  }

  /**
   * Get the AI controller
   */
  getAIController() {
    return this.aiController;
  }

  /**
   * Sets verbose logging for all components
   * @param isVerbose Whether to enable verbose logging
   */
  setVerboseLogging(isVerbose: boolean): void {
    this.verboseLogging = isVerbose;

    // Set verbose logging for standard AI controller
    if (this.aiController) {
      this.aiController.setVerboseLogging(isVerbose);
    }
  }

  /**
   * Get the AI stats from the current controller
   */
  getAIStats() {
    return this.aiController?.getLastMoveStats() || null;
  }

  /**
   * Creates a new piece of the specified type and places it on the board
   * @param pieceType The type of piece to create
   * @param isLight Whether the piece belongs to the light player
   * @param position The position to place the piece at
   * @returns The created piece
   */
  createPiece(pieceType: string, isLight: boolean, position: Position): IPiece {
    // Convert string type to PieceType enum if needed
    const type = this.getPieceTypeFromString(pieceType);

    // Create the new piece
    const piece = new Piece(type, isLight);

    // Place it on the board
    this.gameStateManager.placePiece(piece, position);

    return piece;
  }

  /**
   * Converts a string representation of a piece type to the PieceType enum value
   * @param pieceType String representation of the piece type
   * @returns The corresponding PieceType enum value
   */
  private getPieceTypeFromString(pieceType: string): PieceType {
    switch (pieceType) {
      case "Bear":
        return PieceType.Bear;
      case "Crane":
        return PieceType.Crane;
      case "Frog":
        return PieceType.Frog;
      case "Goat":
        return PieceType.Goat;
      case "Monkey":
        return PieceType.Monkey;
      case "Fungi":
        return PieceType.Fungi;
      case "Octopus":
        return PieceType.Octopus;
      case "Penguin":
        return PieceType.Penguin;
      case "Rhino":
        return PieceType.Rhino;
      case "Snake":
        return PieceType.Snake;
      case "Tree":
        return PieceType.Tree;
      case "Wolf":
        return PieceType.Wolf;
      case "Virus":
        return PieceType.Virus;
      default:
        throw new Error(`Unknown piece type: ${pieceType}`);
    }
  }

  /**
   * Resets the game and starts a new NN test game with random kings
   * Used for autoplay between rounds
   */
  resetAndStartNewNNTest(): void {
    // Get all eligible king types (excluding certain types)
    const eligibleKings = [
      PieceType.Bear,
      PieceType.Crane,
      PieceType.Frog,
      PieceType.Goat,
      PieceType.Monkey,
      PieceType.Fungi,
      PieceType.Rhino,
      PieceType.Snake,
      PieceType.Tree,
      PieceType.Wolf,
    ];

    // Select random kings
    const randomLightKingIndex = Math.floor(
      Math.random() * eligibleKings.length
    );
    const lightKing = eligibleKings[randomLightKingIndex];

    const randomDarkKingIndex = Math.floor(
      Math.random() * eligibleKings.length
    );
    const darkKing = eligibleKings[randomDarkKingIndex];

    console.log(
      "Auto-restarting NN Test mode with kings:",
      lightKing,
      darkKing
    );

    // Call switchToAIMode
    this.switchToAIMode(
      this.getGameState().lightPlayer || "player",
      lightKing,
      darkKing,
      false // Don't use neural network since it's removed
    );
  }

  /**
   * Gets all pieces for the current player's turn
   * Used primarily for debugging AI move issues
   * @returns Array of pieces that belong to the current player
   */
  getPiecesForCurrentPlayer(): { type: PieceType; position: Position }[] {
    const gameState = this.gameStateManager.getGameState();
    const isLightTurn = gameState.isLightTurn;
    const result: { type: PieceType; position: Position }[] = [];

    // Iterate through the board and find all pieces for the current player
    for (let row = 0; row < gameState.board.length; row++) {
      for (let col = 0; col < gameState.board[row].length; col++) {
        const square = gameState.board[row][col];
        if (square.Pieces && square.Pieces.length > 0) {
          const piece = square.Pieces[0]; // Get the top piece
          if (piece.isLight === isLightTurn) {
            result.push({
              type: piece.type,
              position: { row, col },
            });
          }
        }
      }
    }

    return result;
  }

  /**
   * Sets the heuristic for the AI
   * @param type The type of heuristic to use
   */
  setAIHeuristic(type: HeuristicType): void {
    if (!this.aiController) {
      this.aiController = new AIController(this);
    }
    this.aiController.setHeuristic(type);
  }

  /**
   * Gets the current AI heuristic
   * @returns The current heuristic type
   */
  getAIHeuristic(): HeuristicType {
    if (!this.aiController) {
      return HeuristicType.Random;
    }
    return this.aiController.getHeuristicType();
  }

  /**
   * Enable or disable debug mode for the current AI heuristic
   * @param enable Whether to enable debugging
   */
  setAIHeuristicDebug(enable: boolean): void {
    if (!this.aiController) {
      return;
    }
    this.aiController.setHeuristicDebug(enable);
  }
}
