import { UUID } from "crypto";
import { allPieceTypes, directions } from "../const";
import { Move } from "../Move";
import { SideEffect } from "../SideEffect";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
  ISideEffect,
  Effect,
} from "../types";
import { BaseMovement } from "./BaseMovement";

export class FungiMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes.filter(
    (pieceType) => pieceType !== PieceType.Fungi
  );

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    for (const direction of directions) {
      const newPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, newPosition)) {
        continue;
      }

      const newSquare = boardState[newPosition.row][newPosition.col];
      if (!newSquare) {
        continue;
      }

      // Check if the target square has multiple pieces (potentially a tree housing another piece)
      if (newSquare.Pieces.length > 1) {
        // Check if one of the pieces is a tree
        const hasTree = newSquare.Pieces.some((p) => p.type === PieceType.Tree);

        if (hasTree) {
          // Find the non-tree piece in the square
          const housedPiece = newSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );

          if (housedPiece) {
            if (housedPiece.isLight === piece.isLight) {
              // If the housed piece is friendly, skip this move
              continue;
            } else {
              // If the housed piece is an enemy, create a capture move
              possibleMoves.push(
                new Move(newPosition, MoveType.capture, housedPiece)
              );
              continue;
            }
          }
        }
      }

      const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          newPosition,
          squareInfo.basicSquareInfo,
          piece,
          this.capturablePieceTypes
        );

        if (move) {
          // Add side effects for adjacent squares
          const sideEffects = this.calculateSideEffects(
            move.id,
            newPosition,
            boardState
          );
          move.sideEffects = sideEffects;
          possibleMoves.push(move);
        } else if (
          squareInfo.basicSquareInfo.isAlly &&
          squareInfo.basicSquareInfo.piece?.type === PieceType.Tree
        ) {
          // House move
          const houseMove: Move = new Move(
            newPosition,
            MoveType.house,
            squareInfo.basicSquareInfo.piece
          );

          // Add side effects for house move
          houseMove.addSideEffects(
            this.calculateSideEffects(houseMove.id, newPosition, boardState)
          );

          possibleMoves.push(houseMove);
        }
      }
    }

    return possibleMoves;
  }

  // Helper method to calculate side effects
  private calculateSideEffects(
    moveId: UUID,
    newPosition: Position,
    boardState: ISquare[][]
  ): ISideEffect[] {
    const sideEffects: ISideEffect[] = [];
    const adjacentDirections = [
      [-1, -1],
      [0, -1],
      [1, -1],
      [-1, 0],
      [1, 0],
      [-1, 1],
      [0, 1],
      [1, 1],
    ];

    for (const dir of adjacentDirections) {
      const adjacentPosition = {
        col: newPosition.col + dir[0],
        row: newPosition.row + dir[1],
      };
      if (this.onBoard(boardState, adjacentPosition)) {
        const adjacentSquare =
          boardState[adjacentPosition.row][adjacentPosition.col];
        const adjacentPiece = adjacentSquare.Pieces[0];
        if (adjacentPiece && adjacentPiece.type === PieceType.Tree) {
          sideEffects.push(
            new SideEffect(
              moveId,
              adjacentPosition,
              adjacentSquare.Pieces,
              Effect.meadow
            )
          );
        }
      }
    }

    return sideEffects;
  }
}
