import { UUID } from "crypto";
import {
  Effect,
  IMove,
  IPiece,
  MoveType,
  Position,
  ISideEffect,
} from "./types";
import { SideEffect } from "./SideEffect";

export class Move implements IMove {
  id: UUID;
  position: Position;
  toPiece: IPiece | null;
  moveType: MoveType;
  sideEffects: ISideEffect[];

  constructor(
    position: Position,
    moveType: MoveType,
    toPiece: IPiece | null = null
  ) {
    this.id = crypto.randomUUID() as UUID;
    this.position = position;
    this.toPiece = toPiece;
    this.moveType = moveType;
    this.sideEffects = [];
  }

  addSideEffect(position: Position, pieces: IPiece[], effect: Effect) {
    this.sideEffects.push(new SideEffect(this.id, position, pieces, effect));
  }

  addSideEffects(sideEffects: ISideEffect[]) {
    this.sideEffects.push(...sideEffects);
  }
}
