import { allPieceTypes } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

const directions: number[][] = [
  [-2, -2], // Top Left
  [-2, 2], // Top Right
  [2, -2], // Bottom Left
  [2, 2], // Bottom Right
];

export class FrogMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    if (!piece.hasLilyJumped) {
      this.handleLilyJump(piece, position, boardState, possibleMoves);
    }

    this.handleDiagonalJumps(piece, position, boardState, possibleMoves);
    this.handleWallClimb(piece, position, boardState, possibleMoves);

    return possibleMoves;
  }

  private handleLilyJump(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][],
    possibleMoves: IMove[]
  ) {
    // Determine the starting row based on the piece's color
    const startingRow = piece.isLight ? 0 : 7;

    // Check if the Frog is on its starting row
    if (position.row !== startingRow || piece.hasMoved) {
      return;
    }

    // Determine valid rows based on the piece's color
    const validRows = piece.isLight ? [0, 1, 2] : [7, 6, 5];

    for (let col = 0; col < boardState[0].length; col++) {
      for (const row of validRows) {
        const potentialPosition = { col, row };

        // Check if position is on the board before accessing
        if (!this.onBoard(boardState, potentialPosition)) {
          continue;
        }

        const targetSquare = boardState[row][col];

        // Ensure targetSquare exists before accessing its properties
        if (!targetSquare) {
          continue;
        }

        // Check if the target square has multiple pieces (potentially a tree housing another piece)
        if (targetSquare.Pieces && targetSquare.Pieces.length > 1) {
          // Check if one of the pieces is a tree
          const hasTree = targetSquare.Pieces.some(
            (p) => p.type === PieceType.Tree
          );

          if (hasTree) {
            // Find the non-tree piece in the square
            const housedPiece = targetSquare.Pieces.find(
              (p) => p.type !== PieceType.Tree
            );

            if (housedPiece) {
              if (housedPiece.isLight === piece.isLight) {
                // If the housed piece is friendly, skip this move
                continue;
              } else {
                // If the housed piece is an enemy, create a capture move
                possibleMoves.push(
                  new Move(potentialPosition, MoveType.capture, housedPiece)
                );
                continue;
              }
            }
          }
        }

        // Check if it's a friendly tree that the frog can climb
        const isFriendlyTree =
          targetSquare.Pieces &&
          targetSquare.Pieces.length === 1 &&
          targetSquare.Pieces[0].type === PieceType.Tree &&
          targetSquare.Pieces[0].isLight === piece.isLight;

        // Check if the target square is within the board and not occupied by an allied non-tree piece
        if (
          !targetSquare.Pieces ||
          targetSquare.Pieces.length === 0 ||
          !this.isAlly(piece, targetSquare.Pieces[0]) ||
          isFriendlyTree
        ) {
          const isEnemyTree =
            targetSquare.Pieces &&
            targetSquare.Pieces[0]?.type === PieceType.Tree &&
            !this.isAlly(piece, targetSquare.Pieces[0]);

          const isRegularMoveToTree = this.isRegularMove(
            piece,
            position,
            potentialPosition,
            boardState
          );

          const squareInfo = this.getSquareInfo(targetSquare, piece.isLight);
          if (squareInfo.basicSquareInfo) {
            // For friendly trees, create a house move
            if (isFriendlyTree) {
              possibleMoves.push(
                new Move(
                  potentialPosition,
                  MoveType.house,
                  targetSquare.Pieces[0]
                )
              );
            } else {
              // For other valid moves
              var move = this.getMove(
                position,
                potentialPosition,
                squareInfo.basicSquareInfo,
                piece,
                this.capturablePieceTypes
              );

              if (move) {
                possibleMoves.push(move);
              }

              // For enemy trees, add a house move if needed
              if (
                isEnemyTree &&
                !isRegularMoveToTree &&
                squareInfo.basicSquareInfo.isEnemyOccupied &&
                squareInfo.basicSquareInfo.piece?.type === PieceType.Tree
              ) {
                possibleMoves.push(
                  new Move(
                    potentialPosition,
                    MoveType.house,
                    squareInfo.basicSquareInfo.piece
                  )
                );
              }
            }
          }
        }
      }
    }
  }

  private handleDiagonalJumps(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][],
    possibleMoves: IMove[]
  ) {
    for (const direction of directions) {
      // Check if the regular position (2 squares away diagonally) is within bounds
      const regularPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      // Check if the backup position (1 square away diagonally) is within bounds
      const backupPosition = {
        col: position.col + Math.floor(direction[0] / 2),
        row: position.row + Math.floor(direction[1] / 2),
      };

      // Check if the regular position is on the board
      const isRegularPositionOnBoard = this.onBoard(
        boardState,
        regularPosition
      );

      if (isRegularPositionOnBoard) {
        const regularSquare =
          boardState[regularPosition.row][regularPosition.col];

        // Ensure regularSquare exists before accessing its properties
        if (!regularSquare) {
          continue;
        }

        // Check if the target square has multiple pieces (potentially a tree housing another piece)
        if (regularSquare.Pieces && regularSquare.Pieces.length > 1) {
          // Check if one of the pieces is a tree
          const hasTree = regularSquare.Pieces.some(
            (p) => p.type === PieceType.Tree
          );

          if (hasTree) {
            // Find the non-tree piece in the square
            const housedPiece = regularSquare.Pieces.find(
              (p) => p.type !== PieceType.Tree
            );

            if (housedPiece) {
              if (housedPiece.isLight === piece.isLight) {
                // If the housed piece is friendly, skip this regular position
                continue;
              } else {
                // If the housed piece is an enemy, create a capture move
                possibleMoves.push(
                  new Move(regularPosition, MoveType.capture, housedPiece)
                );
                continue;
              }
            }
          }
        }

        // Check if the regular square is occupied by a friendly piece
        const isRegularSquareOccupiedByFriendly =
          regularSquare.Pieces && regularSquare.Pieces[0]
            ? this.isAlly(piece, regularSquare.Pieces[0]) &&
              regularSquare.Pieces[0].type !== PieceType.Tree
            : false;

        // If the regular square is not occupied by a friendly piece and not blocked by an enemy Rhino in front, add it as a valid move
        if (!isRegularSquareOccupiedByFriendly) {
          const squareInfo = this.getSquareInfo(regularSquare, piece.isLight);
          if (squareInfo.basicSquareInfo) {
            var thisMove = this.getMove(
              position,
              regularPosition,
              squareInfo.basicSquareInfo,
              piece,
              this.capturablePieceTypes
            );

            if (thisMove) {
              possibleMoves.push(thisMove);
            }
          }
        }
      }

      // Check the backup position only if the regular position is blocked or off the board
      if (
        !isRegularPositionOnBoard ||
        (isRegularPositionOnBoard &&
          !possibleMoves.some((move) => move.position === regularPosition))
      ) {
        const isBackupPositionOnBoard = this.onBoard(
          boardState,
          backupPosition
        );

        if (isBackupPositionOnBoard) {
          const backupSquare =
            boardState[backupPosition.row][backupPosition.col];

          // Ensure backupSquare exists before accessing its properties
          if (!backupSquare) {
            continue;
          }

          // Check if the backup square has multiple pieces (potentially a tree housing another piece)
          if (backupSquare.Pieces && backupSquare.Pieces.length > 1) {
            // Check if one of the pieces is a tree
            const hasTree = backupSquare.Pieces.some(
              (p) => p.type === PieceType.Tree
            );

            if (hasTree) {
              // Find the non-tree piece in the square
              const housedPiece = backupSquare.Pieces.find(
                (p) => p.type !== PieceType.Tree
              );

              if (housedPiece) {
                if (housedPiece.isLight === piece.isLight) {
                  // If the housed piece is friendly, skip this backup position
                  continue;
                } else {
                  // If the housed piece is an enemy, create a capture move
                  possibleMoves.push(
                    new Move(backupPosition, MoveType.capture, housedPiece)
                  );
                  continue;
                }
              }
            }
          }

          // Check if the backup square is occupied by a friendly piece
          const isBackupSquareOccupiedByFriendly =
            backupSquare.Pieces &&
            backupSquare.Pieces[0] &&
            this.isAlly(piece, backupSquare.Pieces[0]);

          // If the backup square is not occupied by a friendly piece and not blocked by an enemy Rhino in front, add it as a valid move
          if (!isBackupSquareOccupiedByFriendly) {
            const squareInfo = this.getSquareInfo(backupSquare, piece.isLight);
            if (squareInfo.basicSquareInfo) {
              var thatMove = this.getMove(
                position,
                backupPosition,
                squareInfo.basicSquareInfo,
                piece,
                this.capturablePieceTypes
              );

              if (thatMove) {
                possibleMoves.push(thatMove);
              }
            }
          }
        }
      }
    }
  }

  private handleWallClimb(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][],
    possibleMoves: IMove[]
  ) {
    if (position.col === 0 || position.col === 4) {
      for (let i = -1; i <= 1; i += 2) {
        const potentialPosition = { col: position.col, row: position.row + i };

        if (this.onBoard(boardState, potentialPosition)) {
          const targetSquare =
            boardState[potentialPosition.row][potentialPosition.col];

          // Ensure targetSquare exists before accessing its properties
          if (!targetSquare) {
            continue;
          }

          // Check if the target square has multiple pieces (potentially a tree housing another piece)
          if (targetSquare.Pieces && targetSquare.Pieces.length > 1) {
            // Check if one of the pieces is a tree
            const hasTree = targetSquare.Pieces.some(
              (p) => p.type === PieceType.Tree
            );

            if (hasTree) {
              // Find the non-tree piece in the square
              const housedPiece = targetSquare.Pieces.find(
                (p) => p.type !== PieceType.Tree
              );

              if (housedPiece) {
                if (housedPiece.isLight === piece.isLight) {
                  // If the housed piece is friendly, skip this move
                  continue;
                } else {
                  // If the housed piece is an enemy, create a capture move
                  possibleMoves.push(
                    new Move(potentialPosition, MoveType.capture, housedPiece)
                  );
                  continue;
                }
              }
            }
          }

          const squareInfo = this.getSquareInfo(targetSquare, piece.isLight);
          if (squareInfo.basicSquareInfo) {
            var move = this.getMove(
              position,
              potentialPosition,
              squareInfo.basicSquareInfo,
              piece,
              this.capturablePieceTypes
            );

            if (move) {
              possibleMoves.push(move);
            }
          }
        }
      }
    }
  }

  private isAlly(piece1: IPiece, piece2: IPiece): boolean {
    return piece1.isLight === piece2.isLight;
  }

  private isRegularMove(
    piece: IPiece,
    startPosition: Position,
    endPosition: Position,
    boardState: ISquare[][]
  ): boolean {
    const dx = Math.abs(endPosition.col - startPosition.col);
    const dy = Math.abs(endPosition.row - startPosition.row);

    // Regular diagonal moves (1 or 2 squares) or wall climb moves (1 square up/down)
    return (
      ((dx === 1 || dx === 2) && (dy === 1 || dy === 2)) || // Diagonal jumps
      (dx === 0 &&
        dy === 1 &&
        (startPosition.col === 0 || startPosition.col === 4)) // Wall climbs
    );
  }
}
