/**
 * ZodiacFEN - Forsyth-Edwards Notation adapted for Zodiac game
 * Handles serialization and deserialization of game states
 */
import { GameState, PieceType, Position } from "../types";
import { ZodiacEngine } from "../zodiacEngine";

export class ZodiacFEN {
  /**
   * Converts the current game state to a FEN-style string
   * @param gameState The game state to encode
   * @returns FEN string representation of the board
   */
  static encode(gameState: GameState): string {
    let fenString = "";

    // Add turn indicator at the beginning if not first turn
    if (gameState.turnCount > 0) {
      if (!gameState.isLightTurn) {
        fenString = "d" + fenString;
      }
    }

    // Process the board state column by column (instead of row by row)
    // Starting from the bottom left (0,0) and moving up first
    const numCols = gameState.board[0].length; // Should be 5
    const numRows = gameState.board.length; // Should be 8

    // For each column
    for (let col = 0; col < numCols; col++) {
      if (col !== 0) {
        // Add separator between columns
        fenString += "/";
      }

      let emptyCount = 0;

      // For each row in this column, moving bottom to top
      for (let row = numRows - 1; row >= 0; row--) {
        const square = gameState.board[row][col];
        const pieces = square.Pieces;

        // If square is empty, increment counter
        if (pieces.length === 0) {
          emptyCount++;
          continue;
        }

        // If we had empty squares before this piece, add the count
        if (emptyCount > 0) {
          fenString += emptyCount;
          emptyCount = 0;
        }

        // Encode all pieces on this square with "+" between them
        const pieceStrings = [];
        for (const piece of pieces) {
          pieceStrings.push(this.encodePiece(piece));
        }

        // Join pieces with "+" to indicate they share the same square
        fenString += pieceStrings.join("+");
      }

      // If column ends with empty squares, add the count
      if (emptyCount > 0) {
        fenString += emptyCount;
      }
    }

    return fenString;
  }

  /**
   * Encodes a single piece with all its attributes
   * @param piece The piece to encode
   * @returns String representation of the piece
   */
  private static encodePiece(piece: any): string {
    let pieceString = "";

    // Get base piece character
    const pieceChar = this.getPieceChar(piece.type);

    // Set case based on piece ownership
    // Uppercase for light, lowercase for dark
    pieceString += piece.isLight
      ? pieceChar.toUpperCase()
      : pieceChar.toLowerCase();

    // Add orientation for directional pieces like Penguin
    if (piece.type === PieceType.Penguin) {
      pieceString += this.getOrientationChar(piece.orientation);
    }

    // Add special ability markers
    pieceString += this.encodeSpecialAbilities(piece);

    return pieceString;
  }

  /**
   * Gets the character representation for a piece type
   * @param pieceType The type of the piece
   * @returns Character representation
   */
  private static getPieceChar(pieceType: string): string {
    switch (pieceType) {
      case "Bear":
        return "B";
      case "Crane":
        return "C";
      case "Frog":
        return "F";
      case "Goat":
        return "G";
      case "Monkey":
        return "M";
      case "Fungi":
        return "U";
      case "Octopus":
        return "O";
      case "Penguin":
        return "P";
      case "Rhino":
        return "R";
      case "Snake":
        return "S";
      case "Tree":
        return "T";
      case "Wolf":
        return "W";
      case "Virus":
        return "V";
      default:
        return "x"; // Unknown piece
    }
  }

  /**
   * Gets the character for orientation (for directional pieces)
   * @param orientation The piece orientation in degrees
   * @returns Character representation of orientation
   */
  private static getOrientationChar(orientation: number): string {
    if (
      (orientation >= 0 && orientation <= 22) ||
      (orientation >= 338 && orientation <= 360)
    ) {
      return "^"; // Up
    }
    if (orientation >= 23 && orientation <= 67) {
      return "^>"; // Up-right
    }
    if (orientation >= 68 && orientation <= 112) {
      return ">"; // Right
    }
    if (orientation >= 113 && orientation <= 157) {
      return "_>"; // Down-right
    }
    if (orientation >= 158 && orientation <= 202) {
      return "_"; // Down
    }
    if (orientation >= 203 && orientation <= 247) {
      return "_<"; // Down-left
    }
    if (orientation >= 248 && orientation <= 292) {
      return "<"; // Left
    }
    if (orientation >= 293 && orientation <= 337) {
      return "^<"; // Up-left
    }
    return "^"; // Default to Up
  }

  /**
   * Encodes special abilities and states of the piece
   * @param piece The piece to encode
   * @returns String representation of special abilities
   */
  private static encodeSpecialAbilities(piece: any): string {
    let special = "";

    // Entanglement
    if (piece.isEntangled) {
      special += "@";
    }

    // Poisoned
    if (piece.isPoisoned) {
      special += "!";
    }

    // Meadowed
    if (piece.isMeadowed) {
      special += "#";
    }

    // Zombified
    if (piece.isZombified) {
      special += "z";
    }

    // Special single-use abilities
    if (
      (piece.type === PieceType.Frog && !piece.hasLilyJumped) ||
      (piece.type === PieceType.Rhino && !piece.hasCharged) ||
      (piece.type === PieceType.Snake && !piece.hasShed)
    ) {
      special += "*";
    }

    // Special state for specific pieces
    if (
      (piece.type === PieceType.Wolf && piece.isLone) ||
      (piece.type === PieceType.Octopus && piece.isInfected) ||
      (piece.type === PieceType.Goat && piece.isFainted) ||
      (piece.type === PieceType.Monkey && piece.isRiding)
    ) {
      special += "$";
    }

    // King status
    if (piece.isKing) {
      special += piece.isLight ? "K" : "k";
    }

    return special;
  }

  /**
   * Decodes a FEN string into a game state
   * @param fenString The FEN string to decode
   * @param engine Reference to the game engine
   * @returns Decoded game state
   */
  static decode(fenString: string, engine: ZodiacEngine): GameState {
    const gameState = engine.getGameState(); // Get a clean game state

    // Check if it's dark's turn
    const isDarkTurn = fenString.startsWith("d");
    if (isDarkTurn) {
      fenString = fenString.substring(1);
      gameState.isLightTurn = false;
    } else {
      gameState.isLightTurn = true;
    }

    // Split into columns
    const columns = fenString.split("/");
    const numRows = 8; // Board height
    const numCols = columns.length; // Should be 5

    // Process each column
    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
      let rowIndex = numRows - 1; // Start at the bottom (row 7)
      let i = 0;

      while (i < columns[colIndex].length && rowIndex >= 0) {
        const char = columns[colIndex][i];

        // If it's a number, it represents empty squares
        if (!isNaN(parseInt(char))) {
          const emptyCount = parseInt(char);
          rowIndex -= emptyCount; // Move up the required number of rows
          i++;
          continue;
        }

        // Collect all characters for this square until next number, '+', or end
        let fullSquareContent = "";
        let j = i;

        while (
          j < columns[colIndex].length &&
          isNaN(parseInt(columns[colIndex][j])) &&
          columns[colIndex][j] !== "/"
        ) {
          fullSquareContent += columns[colIndex][j];
          j++;
        }

        // Split by '+' to get individual pieces
        const piecesOnSquare = fullSquareContent.split("+");

        // Place each piece on the board
        for (const pieceString of piecesOnSquare) {
          if (pieceString.length > 0) {
            this.placePiece(
              pieceString,
              { row: rowIndex, col: colIndex },
              gameState,
              engine
            );
          }
        }

        rowIndex--; // Move to the next row up
        i = j;
      }
    }

    return gameState;
  }

  /**
   * Places a piece on the board based on its FEN representation
   * @param pieceString The FEN representation of the piece
   * @param position Position to place the piece
   * @param gameState Current game state
   * @param engine Reference to the game engine
   */
  private static placePiece(
    pieceString: string,
    position: Position,
    gameState: GameState,
    engine: ZodiacEngine
  ): void {
    if (pieceString.length === 0) return;

    // Extract the base piece character
    const baseChar = pieceString[0];
    const pieceType = this.getPieceTypeFromChar(baseChar);
    const isLight = baseChar === baseChar.toUpperCase();

    // Create the piece using the engine's methods
    const piece = engine.createPiece(pieceType, isLight, position);

    // Apply special states based on the rest of the string
    if (piece) {
      for (let i = 1; i < pieceString.length; i++) {
        const specialChar = pieceString[i];

        // Apply special attributes based on the character
        switch (specialChar) {
          case "@": // Entangled
            piece.isEntangled = true;
            break;
          case "!": // Poisoned
            piece.isPoisoned = true;
            break;
          case "#": // Meadowed
            piece.isMeadowed = true;
            break;
          case "z": // Zombified
            piece.isZombified = true;
            break;
          case "i": // Infected
            piece.isInfected = true;
            break;
          case "r": // Riding
            piece.isRiding = true;
            break;
          case "h": // Housing
            piece.isHousing = true;
            break;
          case "l": // Lone
            piece.isLone = true;
            break;
          case "d": // Dormant
            piece.isDormant = true;
            break;
          case "$": // Special state
            if (piece.type === PieceType.Wolf) {
              piece.isLone = true;
            } else if (piece.type === PieceType.Octopus) {
              piece.isInfected = true;
            } else if (piece.type === PieceType.Goat) {
              piece.isFainted = true;
            } else if (piece.type === PieceType.Monkey) {
              piece.isRiding = true; // Placeholder for swing when implemented
            }
            break;
          case "K": // Light king
          case "k": // Dark king
            piece.isKing = true;
            break;
          case "*": // Special single-use ability
            if (piece.type === PieceType.Frog) {
              piece.hasLilyJumped = false;
            } else if (piece.type === PieceType.Rhino) {
              piece.hasCharged = false;
            } else if (piece.type === PieceType.Snake) {
              piece.hasShed = false;
            }
            break;
          // Direction indicators for Penguin
          case "^": // Up
            if (piece.type === PieceType.Penguin) {
              piece.direction = "up";
            }
            break;
          case "_": // Down
            if (piece.type === PieceType.Penguin) {
              piece.direction = "down";
            }
            break;
          case "<": // Left
            if (piece.type === PieceType.Penguin) {
              piece.direction = "left";
            }
            break;
          case ">": // Right
            if (piece.type === PieceType.Penguin) {
              piece.direction = "right";
            }
            break;
          // Directional combinations
          case "^<": // Up-left
            if (piece.type === PieceType.Penguin) {
              piece.direction = "up-left";
            }
            break;
          case "^>": // Up-right
            if (piece.type === PieceType.Penguin) {
              piece.direction = "up-right";
            }
            break;
          case "_<": // Down-left
            if (piece.type === PieceType.Penguin) {
              piece.direction = "down-left";
            }
            break;
          case "_>": // Down-right
            if (piece.type === PieceType.Penguin) {
              piece.direction = "down-right";
            }
            break;
        }
      }
    }
  }

  /**
   * Gets the piece type from its character representation
   * @param char The character representing the piece
   * @returns The piece type
   */
  private static getPieceTypeFromChar(char: string): string {
    const lowerChar = char.toLowerCase();
    switch (lowerChar) {
      case "b":
        return "Bear";
      case "c":
        return "Crane";
      case "f":
        return "Frog";
      case "g":
        return "Goat";
      case "m":
        return "Monkey";
      case "u":
        return "Fungi";
      case "o":
        return "Octopus";
      case "p":
        return "Penguin";
      case "r":
        return "Rhino";
      case "s":
        return "Snake";
      case "t":
        return "Tree";
      case "w":
        return "Wolf";
      case "v":
        return "Virus";
      default:
        return "Unknown";
    }
  }

  /**
   * Checks if the given FEN string represents a valid game state
   * @param fenString The FEN string to validate
   * @returns True if valid, false otherwise
   */
  static validate(fenString: string): boolean {
    // Perform basic validation of the FEN string structure
    if (!fenString) return false;

    // Remove turn indicator if present
    if (fenString.startsWith("d")) {
      fenString = fenString.substring(1);
    }

    // Split into columns
    const columns = fenString.split("/");
    const numRows = 8; // Board height
    const numCols = columns.length; // Should be 5

    // Check if we have the expected number of columns
    if (numCols === 0) return false;

    // Validate each column
    for (let colIndex = 0; colIndex < columns.length; colIndex++) {
      let rowCount = 0;

      for (let i = 0; i < columns[colIndex].length; i++) {
        const char = columns[colIndex][i];

        // If it's a number, it represents empty squares
        if (!isNaN(parseInt(char))) {
          rowCount += parseInt(char);
        } else if (char === "+") {
          // '+' doesn't increase row count, it just connects pieces on the same square
          continue;
        } else {
          // If it's not already counted as part of a stack and not a '+'
          // It's a piece or special character
          if (i === 0 || columns[colIndex][i - 1] !== "+") {
            rowCount++;
          }

          // Skip special characters that don't represent new pieces
          while (
            i + 1 < columns[colIndex].length &&
            this.isSpecialChar(columns[colIndex][i + 1])
          ) {
            i++;
          }
        }
      }

      // Each column should have exactly 8 rows
      if (rowCount !== 8) return false;
    }

    return true;
  }

  /**
   * Checks if the character is a special character rather than a new piece
   * @param char The character to check
   * @returns True if it's a special character, false otherwise
   */
  private static isSpecialChar(char: string): boolean {
    const specialChars = [
      // Direction indicators
      "^", // Up
      "_", // Down
      "<", // Left
      ">", // Right
      "<^", // Up-left
      "^>", // Up-right
      "<_", // Down-left
      "_>", // Down-right

      // Status effects
      "@", // Entangled
      "!", // Poisoned
      "#", // Meadowed
      "z", // Zombified
      "r", // Riding
      "l", // Lone
      "*", // Special single-use ability (Lily pad Frog / Mega Rhino / Snake Shed)
      "$", // Special state - (Lone wolf / Shadowed Octopus / Fainted Goat / High Monkey)
      "K", // Light king
      "k", // Dark king
    ];
    return specialChars.includes(char) || char === "+";
  }

  /**
   * Converts the current game state to a FEN-style string
   * with the opponent's king information hidden based on perspective
   * @param gameState The game state to encode
   * @param currentPlayerIsLight Whether the current player is light
   * @returns FEN string with hidden king information
   */
  static encodeWithHiddenKings(
    gameState: GameState,
    currentPlayerIsLight: boolean
  ): string {
    let fenString = "";

    // Add turn indicator at the beginning if not first turn
    if (gameState.turnCount > 0) {
      if (!gameState.isLightTurn) {
        fenString = "d" + fenString;
      }
    }

    // Process the board state column by column (instead of row by row)
    // Starting from the bottom left (0,0) and moving up first
    const numCols = gameState.board[0].length; // Should be 5
    const numRows = gameState.board.length; // Should be 8

    // For each column
    for (let col = 0; col < numCols; col++) {
      if (col !== 0) {
        // Add separator between columns
        fenString += "/";
      }

      let emptyCount = 0;

      // For each row in this column, moving bottom to top
      for (let row = numRows - 1; row >= 0; row--) {
        const square = gameState.board[row][col];
        const pieces = square.Pieces;

        // If square is empty, increment counter
        if (pieces.length === 0) {
          emptyCount++;
          continue;
        }

        // If we had empty squares before this piece, add the count
        if (emptyCount > 0) {
          fenString += emptyCount;
          emptyCount = 0;
        }

        // Encode all pieces on this square with "+" between them
        const pieceStrings = [];
        for (const piece of pieces) {
          // Hide king information for opponent pieces
          const isOpponentPiece = piece.isLight !== currentPlayerIsLight;

          if (isOpponentPiece) {
            // For opponent pieces, don't encode king status
            pieceStrings.push(this.encodePieceWithoutKing(piece));
          } else {
            // For current player's pieces, include king status
            pieceStrings.push(this.encodePiece(piece));
          }
        }

        // Join pieces with "+" to indicate they share the same square
        fenString += pieceStrings.join("+");
      }

      // If column ends with empty squares, add the count
      if (emptyCount > 0) {
        fenString += emptyCount;
      }
    }

    return fenString;
  }

  /**
   * Encodes a piece without including king status
   * @param piece The piece to encode
   * @returns String representation of the piece without king status
   */
  private static encodePieceWithoutKing(piece: any): string {
    let pieceString = "";

    // Get base piece character
    const pieceChar = this.getPieceChar(piece.type);

    // Set case based on piece ownership
    // Uppercase for light, lowercase for dark
    pieceString += piece.isLight
      ? pieceChar.toUpperCase()
      : pieceChar.toLowerCase();

    // Add orientation for directional pieces like Penguin
    if (piece.type === PieceType.Penguin) {
      pieceString += this.getOrientationChar(piece.orientation);
    }

    // Add special ability markers EXCEPT king status
    pieceString += this.encodeSpecialAbilitiesWithoutKing(piece);

    return pieceString;
  }

  /**
   * Encodes special abilities excluding king status
   * @param piece The piece to encode
   * @returns String representation of special abilities without king status
   */
  private static encodeSpecialAbilitiesWithoutKing(piece: any): string {
    let special = "";

    // Entanglement
    if (piece.isEntangled) {
      special += "@";
    }

    // Poisoned
    if (piece.isPoisoned) {
      special += "!";
    }

    // Meadowed
    if (piece.isMeadowed) {
      special += "#";
    }

    // Zombified
    if (piece.isZombified) {
      special += "z";
    }

    // Special single-use abilities
    if (
      (piece.type === PieceType.Frog && !piece.hasLilyJumped) ||
      (piece.type === PieceType.Rhino && !piece.hasCharged) ||
      (piece.type === PieceType.Snake && !piece.hasShed)
    ) {
      special += "*";
    }

    // Special state for specific pieces
    if (
      (piece.type === PieceType.Wolf && piece.isLone) ||
      (piece.type === PieceType.Octopus && piece.isInfected) ||
      (piece.type === PieceType.Goat && piece.isFainted) ||
      (piece.type === PieceType.Monkey && piece.isRiding)
    ) {
      special += "$";
    }

    return special;
  }

  /**
   * Reveals hidden king information in a gameplay FEN
   * @param fenString The gameplay FEN with hidden king information
   * @param lightKingType The piece type of the light king
   * @param darkKingType The piece type of the dark king
   * @returns FEN string with all king information revealed
   */
  static revealKingsInFEN(
    fenString: string,
    lightKingType: string,
    darkKingType: string
  ): string {
    // Parse the FEN and add king markers to the appropriate pieces
    let result = fenString;

    // Get the character representations for the kings
    const lightKingChar = this.getPieceChar(lightKingType).toUpperCase();
    const darkKingChar = this.getPieceChar(darkKingType).toLowerCase();

    // Add king markers to all matching pieces
    // For light king (uppercase)
    result = result.replace(
      new RegExp(lightKingChar + "(?![kK])", "g"),
      lightKingChar + "K"
    );

    // For dark king (lowercase)
    result = result.replace(
      new RegExp(darkKingChar + "(?![kK])", "g"),
      darkKingChar + "k"
    );

    return result;
  }
}
