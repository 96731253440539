import {
  GameState,
  IMove,
  IPiece,
  ISquare,
  Position,
  MoveType,
  ISideEffect,
  Effect,
  PieceType,
} from "./types";
import { GameStateManager } from "./Managers/GameStateManager";
import { MovementManager } from "./Managers/MovementManager";
import { Piece } from "./Piece";
import { ZodiacEngine } from "./zodiacEngine";
import { AIStats } from "./AIController";
import { HeuristicInterface } from "./heuristics";

/**
 * Monte Carlo Tree Search implementation for the Zodiac game
 *
 * MCTS is a heuristic search algorithm for making decisions in a game.
 * It builds a search tree by simulating random games from the current state
 * and using the results to guide the search towards promising moves.
 *
 * The algorithm consists of four main steps:
 * 1. Selection: Starting from the root, select the most promising nodes until reaching a leaf node
 * 2. Expansion: If the leaf node is not terminal, add one or more child nodes
 * 3. Simulation: Perform a random simulation from the new node to get a result
 * 4. Backpropagation: Update the node statistics based on the simulation result
 */
export class MCTS {
  private rootNode: MCTSNode;
  private explorationParameter: number = Math.sqrt(2);
  private verboseLogging: boolean = false;
  private nodeVisitsByDepth: Map<number, number> = new Map<number, number>();
  private totalNodesVisited: number = 0;
  private heuristic: HeuristicInterface | null = null;

  /**
   * Creates a new MCTS instance
   * @param gameEngine Reference to the main game engine
   */
  constructor(private gameEngine: ZodiacEngine) {
    this.rootNode = new MCTSNode(null, null, null, gameEngine.getGameState());
  }

  /**
   * Set the heuristic to use for evaluating moves
   * @param heuristic The heuristic to use
   */
  setHeuristic(heuristic: HeuristicInterface): void {
    this.heuristic = heuristic;
    if (this.verboseLogging) {
      console.log(`MCTS: Using heuristic ${heuristic.name}`);
    }
  }

  /**
   * Collects and returns statistics about nodes visited at each depth
   * @returns Statistics object with nodes by depth and total nodes visited
   */
  getNodeStatistics(): {
    nodesByDepth: Map<number, number>;
    totalNodesVisited: number;
  } {
    return {
      nodesByDepth: new Map(this.nodeVisitsByDepth),
      totalNodesVisited: this.totalNodesVisited,
    };
  }

  /**
   * Reset node statistics
   */
  resetNodeStatistics(): void {
    this.nodeVisitsByDepth.clear();
    this.totalNodesVisited = 0;
  }

  /**
   * Track a node visit at a specific depth
   * @param depth The depth of the node in the tree
   */
  private trackNodeVisit(depth: number): void {
    // Increment the count for this depth
    const currentCount = this.nodeVisitsByDepth.get(depth) || 0;
    this.nodeVisitsByDepth.set(depth, currentCount + 1);

    // Increment total nodes visited
    this.totalNodesVisited++;
  }

  /**
   * Set whether to use verbose logging
   * @param isVerbose Whether to enable verbose logging
   */
  setVerboseLogging(isVerbose: boolean): void {
    this.verboseLogging = isVerbose;
  }

  /**
   * Sets the exploration parameter used in the UCB1 formula
   * @param parameter The exploration parameter value (higher values favor exploration)
   */
  setExplorationParameter(parameter: number): void {
    if (parameter <= 0) {
      console.warn(
        "Exploration parameter must be positive, using default value instead"
      );
      this.explorationParameter = Math.sqrt(2);
    } else {
      this.explorationParameter = parameter;
      if (this.verboseLogging) {
        console.log(`MCTS exploration parameter set to: ${parameter}`);
      }
    }
  }

  /**
   * Runs the MCTS algorithm for a specified number of iterations
   * @param iterations Number of MCTS iterations to run
   * @returns The best move found
   */
  findBestMove(
    iterations: number
  ): { fromPosition: Position; move: IMove } | null {
    // Reset stats before starting a new search
    this.resetNodeStatistics();

    console.log("MCTS: Starting findBestMove with", iterations, "iterations");

    // Create a fresh root node for the current game state
    this.rootNode = new MCTSNode(
      null,
      null,
      null,
      this.gameEngine.getGameState()
    );

    // Set timeout for search to prevent excessive computation
    const startTime = Date.now();
    const timeLimit = 10000; // 10 seconds maximum search time (increased from 6000)
    let timeoutReached = false;

    // Run MCTS for the specified number of iterations or until timeout
    for (let i = 0; i < iterations && !timeoutReached; i++) {
      // Check if we've exceeded our time limit
      if (i % 50 === 0 && Date.now() - startTime > timeLimit) {
        if (this.verboseLogging) {
          console.log(`MCTS search timeout reached after ${i} iterations`);
        }
        console.log(`MCTS: Search timeout reached after ${i} iterations`);
        timeoutReached = true;
        break;
      }

      // Phase 1: Selection - traverse the tree to find the most promising leaf node
      let selectedNode = this.selection(this.rootNode, 0);

      // Phase 2: Expansion - add child nodes to the selected node
      if (!selectedNode.isTerminal()) {
        selectedNode = this.expansion(selectedNode);
      }

      // Phase 3: Simulation - perform a random playout from the selected node
      const result = this.simulation(selectedNode);

      // Phase 4: Backpropagation - update the statistics for all nodes in the path
      this.backpropagation(selectedNode, result);

      // Add debug log every 100 iterations
      if (i % 100 === 0) {
        console.log(
          `MCTS: Completed ${i} iterations. Root has ${this.rootNode.children.length} children`
        );
      }
    }

    // Return the best child move from the root
    const bestMove = this.getBestMove();
    console.log("MCTS: findBestMove completed, found:", bestMove);
    return bestMove;
  }

  /**
   * Selection phase of MCTS
   * Traverse the tree from the root to a leaf node using UCB1 formula
   * @param node The starting node
   * @param depth Current depth in the tree
   * @returns The selected leaf node
   */
  private selection(node: MCTSNode, depth: number): MCTSNode {
    // Track visit at current depth
    this.trackNodeVisit(depth);

    // Keep traversing down the tree until we reach a leaf node (a node with unexplored children)
    while (node.isFullyExpanded() && !node.isTerminal()) {
      node = node.selectBestChild(this.explorationParameter);
      // Increment depth as we go deeper
      depth++;
      // Track visit at new depth
      this.trackNodeVisit(depth);
    }
    return node;
  }

  /**
   * Expansion phase of MCTS
   * Add a new child node to the selected node
   * @param node The node to expand
   * @returns The newly created child node
   */
  private expansion(node: MCTSNode): MCTSNode {
    // Get a random unexplored move from the current node
    const unexploredMove = node.getRandomUnexploredMove();

    if (!unexploredMove) {
      return node; // No unexplored moves available
    }

    // Create a new child node for this move
    const childNode = node.addChild(
      unexploredMove.fromPosition,
      unexploredMove.move
    );

    return childNode;
  }

  /**
   * Simulation phase of MCTS
   * Perform a random playout from the current state until reaching a terminal state
   * @param node The starting node for the simulation
   * @returns The result of the simulation (1 for win, 0 for loss, 0.5 for draw)
   */
  private simulation(node: MCTSNode): number {
    // Create a clone of the game state for simulation
    const clonedState = node.cloneState();
    const gameStateManager = new GameStateManager();
    gameStateManager.setGameState(clonedState);
    const movementManager = new MovementManager(gameStateManager);

    // Get whose turn it is at the start of simulation
    const isLightTurn = clonedState.isLightTurn;

    // Set max simulation depth to avoid infinite loops
    const maxMoves = 100;
    let movesPlayed = 0;

    // Simulate moves until game ends or max depth reached
    while (!gameStateManager.hasGameEnded() && movesPlayed < maxMoves) {
      try {
        // Get all possible moves for the current player
        const allMoves = this.getAllAvailableMoves(gameStateManager);

        if (allMoves.length === 0) {
          break; // No valid moves, simulation ends
        }

        // Initialize selectedMove with a default from allMoves to avoid undefined errors
        let selectedMove: { fromPosition: Position; move: IMove };

        // Simple selection: prefer captures
        const captureMoves = allMoves.filter(
          (move) => move.move.toPiece !== null
        );

        if (captureMoves.length > 0) {
          // Choose a random capture move
          // No special handling for king captures since they should be "hidden"
          const captureIndex = Math.floor(Math.random() * captureMoves.length);
          selectedMove = captureMoves[captureIndex];
        } else {
          // If no captures, use heuristic or simple forward bias
          // For dark pieces (AI), forward means decreasing row numbers
          // For light pieces (player), forward means increasing row numbers
          const isDarkTurn = !gameStateManager.getGameState().isLightTurn;

          // Forward moves are generally better for positioning
          const forwardMoves = allMoves.filter(
            (move) =>
              isDarkTurn
                ? move.move.position.row < move.fromPosition.row // Dark moves up (decreasing row)
                : move.move.position.row > move.fromPosition.row // Light moves down (increasing row)
          );

          // If we have forward moves, use them 80% of the time
          if (forwardMoves.length > 0 && Math.random() < 0.8) {
            const forwardIndex = Math.floor(
              Math.random() * forwardMoves.length
            );
            selectedMove = forwardMoves[forwardIndex];
          } else {
            // Otherwise pick any random move
            const randomIndex = Math.floor(Math.random() * allMoves.length);
            selectedMove = allMoves[randomIndex];
          }
        }

        // Get the piece at the from position
        const pieceToMove = this.getPieceAt(
          gameStateManager,
          selectedMove.fromPosition
        );
        if (!pieceToMove) {
          if (this.verboseLogging) {
            console.error(
              "No piece found at position",
              selectedMove.fromPosition
            );
          }
          break;
        }

        // Make the selected move with all required parameters
        movementManager.makeMove(
          selectedMove.fromPosition,
          selectedMove.move.position,
          pieceToMove,
          selectedMove.move.toPiece,
          selectedMove.move.moveType,
          selectedMove.move.sideEffects
        );

        // Change turns
        gameStateManager.changeTurns();

        movesPlayed++;
      } catch (error) {
        if (this.verboseLogging) {
          console.error("Error in simulation", error);
        }
        break;
      }
    }

    // Game is terminal (king was captured) or max moves reached
    // Calculate which king is still alive and who won
    const allPieces = [];
    for (let row = 0; row < clonedState.board.length; row++) {
      for (let col = 0; col < clonedState.board[row].length; col++) {
        allPieces.push(...clonedState.board[row][col].Pieces);
      }
    }

    // Check if a king was captured
    const lightKingAlive = allPieces.some((p) => p.isKing && p.isLight);
    const darkKingAlive = allPieces.some((p) => p.isKing && !p.isLight);

    // If dark king (AI) is captured, light player wins
    if (!darkKingAlive && lightKingAlive) {
      return 0; // AI loses
    }

    // If light king (player) is captured, dark player wins
    if (!lightKingAlive && darkKingAlive) {
      return 1; // AI wins
    }

    // Evaluate material advantage
    let score = 0;
    for (const piece of allPieces) {
      const pieceValue = piece.isKing ? 10 : 1;
      score += piece.isLight ? -pieceValue : pieceValue;
    }

    // Normalize to a value between 0 and 1
    // A highly positive score is good for the AI (dark player)
    return Math.max(0, Math.min(1, 0.5 + score * 0.05));
  }

  /**
   * Backpropagation phase of MCTS
   * Update the statistics of all nodes in the path from the selected node to the root
   * @param node The starting node for backpropagation
   * @param result The result of the simulation
   */
  private backpropagation(node: MCTSNode, result: number): void {
    // Update statistics for each node in the path from the selected node to the root
    while (node !== null) {
      node.incrementVisits();
      node.updateScore(result);

      // Move to parent node - if it's null we'll exit the loop
      const parent = node.parent;
      if (parent === null) {
        break;
      }
      node = parent;
    }
  }

  /**
   * Get the best move from the root node based on visit count
   * @returns The best move found
   */
  private getBestMove(): { fromPosition: Position; move: IMove } | null {
    // If root has no children, return null
    if (this.rootNode.children.length === 0) {
      if (this.verboseLogging) {
        console.log("MCTS: No valid moves found");
      }
      console.log("MCTS: No valid moves found - root has no children");
      return null;
    }

    console.log(
      `MCTS: getBestMove - root has ${this.rootNode.children.length} children`
    );

    // Sort and select the best child
    const bestChild = this.rootNode.children.sort((a, b) => {
      // Prioritize captures, but don't distinguish king captures
      const aIsCapture = a.move?.toPiece !== null;
      const bIsCapture = b.move?.toPiece !== null;

      if (aIsCapture && !bIsCapture) return -1;
      if (!aIsCapture && bIsCapture) return 1;

      // If not captures or both are captures, use visits and score
      // Use visits as primary criteria
      if (b.visits !== a.visits) {
        return b.visits - a.visits;
      }

      // If visits are equal, use score as tiebreaker
      return b.score / b.visits - a.score / a.visits;
    })[0];

    if (!bestChild.fromPosition || !bestChild.move) {
      if (this.verboseLogging) {
        console.error("MCTS: Selected child has no move information");
      }
      console.error("MCTS: Selected child has no move information", bestChild);
      return null;
    }

    console.log(
      "MCTS: Best move selected:",
      `From: [${bestChild.fromPosition.row},${bestChild.fromPosition.col}]`,
      `To: [${bestChild.move.position.row},${bestChild.move.position.col}]`,
      `Visits: ${bestChild.visits}`,
      `Score: ${bestChild.score}`
    );

    return {
      fromPosition: bestChild.fromPosition,
      move: bestChild.move,
    };
  }

  /**
   * Get all available moves for the current player
   * @param gameStateManager The game state manager
   * @returns An array of all available moves with their positions
   */
  private getAllAvailableMoves(
    gameStateManager: GameStateManager
  ): { fromPosition: Position; move: IMove }[] {
    const allMoves: { fromPosition: Position; move: IMove }[] = [];
    const state = gameStateManager.getGameState();
    const isLightTurn = state.isLightTurn;

    // Iterate through all squares on the board
    for (let row = 0; row < state.board.length; row++) {
      for (let col = 0; col < state.board[row].length; col++) {
        const position: Position = { row, col };
        const pieces = gameStateManager.getPiecesAt(position);

        // For each piece on this square
        for (const piece of pieces) {
          // Only consider pieces of the current player
          if (piece.isLight === isLightTurn) {
            // Get valid moves for this piece
            let validMoves = piece.getValidMovesForPiece(state.board, position);

            // Apply policy filter if a heuristic is set
            if (this.heuristic) {
              // Apply policy filter to prune undesirable moves early
              validMoves = this.heuristic.policyFilter(
                this.gameEngine,
                state,
                position,
                validMoves
              );

              // ADDITIONAL SAFETY CHECK:
              // For the Virus heuristic, double-check that Rhinos never get lane moves
              if (this.heuristic.name === "Virus" && piece.type === "Rhino") {
                validMoves = validMoves.filter((move) => {
                  // Block lane moves for Rhino under Virus heuristic with absolute certainty
                  if (move.moveType === MoveType.lane) {
                    console.log(
                      "🛑 MCTS FINAL SAFETY: Blocked lane move for Rhino under Virus heuristic"
                    );
                    return false;
                  }

                  // Block any left-right (horizontal) moves for Rhinos
                  const rowDiff = Math.abs(move.position.row - position.row);
                  const colDiff = Math.abs(move.position.col - position.col);
                  if (rowDiff === 0 && colDiff > 0) {
                    console.log(
                      "🛑 MCTS FINAL SAFETY: Blocked horizontal move for Rhino under Virus heuristic"
                    );
                    return false;
                  }

                  return true;
                });
              }

              if (this.verboseLogging) {
                console.log(
                  `MCTS: Applied ${this.heuristic.name} policy filter at [${position.row},${position.col}], ${validMoves.length} moves remaining`
                );
              }
            } else {
              // If no heuristic is set, apply basic filtering
              validMoves = validMoves.filter((move) => {
                // Filter out move type 11 (not implemented correctly in the game engine)
                if (move.moveType === MoveType.rotate) {
                  return false;
                }

                // Skip goat faint moves - prevent AI from fainting goats
                if (move.moveType === MoveType.faint && !isLightTurn) {
                  return false;
                }

                // ADDITIONAL SAFETY: Always block lane moves for Rhinos
                if (piece.type === "Rhino" && move.moveType === MoveType.lane) {
                  return false;
                }

                // ADDITIONAL SAFETY: Block horizontal movement for Rhinos
                if (piece.type === "Rhino") {
                  const rowDiff = Math.abs(move.position.row - position.row);
                  const colDiff = Math.abs(move.position.col - position.col);
                  if (rowDiff === 0 && colDiff > 0) {
                    return false;
                  }
                }

                return true;
              });
            }

            // For remaining moves, apply heuristic evaluation if needed
            for (const move of validMoves) {
              // Apply heuristic evaluation if a heuristic is set
              if (this.heuristic) {
                const score = this.heuristic.evaluateMove(
                  this.gameEngine,
                  state,
                  position,
                  move
                );

                // Skip moves with extremely negative scores (Number.NEGATIVE_INFINITY)
                if (score === Number.NEGATIVE_INFINITY) {
                  if (this.verboseLogging) {
                    console.log(
                      `MCTS: Skipping move from [${position.row},${position.col}] to [${move.position.row},${move.position.col}] due to heuristic evaluation`
                    );
                  }
                  continue;
                }
              }

              allMoves.push({
                fromPosition: position,
                move: move,
              });
            }
          }
        }
      }
    }

    return allMoves;
  }

  /**
   * Helper function to get the piece at a given position
   * @param gameStateManager The game state manager
   * @param position The position to check
   * @returns The piece at the specified position, or null if none exists
   */
  private getPieceAt(
    gameStateManager: GameStateManager,
    position: Position
  ): IPiece | null {
    const pieces = gameStateManager.getPiecesAt(position);
    return pieces.length > 0 ? pieces[0] : null;
  }
}

/**
 * Represents a node in the MCTS tree
 */
class MCTSNode {
  public parent: MCTSNode | null;
  public children: MCTSNode[] = [];
  public visits: number = 0;
  public score: number = 0;
  public unexploredMoves: { fromPosition: Position; move: IMove }[] | null =
    null;

  /**
   * Creates a new MCTS node
   * @param parent Parent node
   * @param fromPosition Source position of the move that led to this node
   * @param move Move that led to this node
   * @param state Game state at this node
   */
  constructor(
    parent: MCTSNode | null,
    public fromPosition: Position | null,
    public move: IMove | null,
    public state: GameState
  ) {
    this.parent = parent;
  }

  /**
   * Checks if this node represents a terminal game state
   * @returns True if this is a terminal node
   */
  isTerminal(): boolean {
    // A node is terminal if the game is over (one of the kings is captured)
    const allPieces = this.getAllPieces();
    const lightKingExists = allPieces.some((p) => p.isKing && p.isLight);
    const darkKingExists = allPieces.some((p) => p.isKing && !p.isLight);

    return !lightKingExists || !darkKingExists;
  }

  /**
   * Checks if all possible moves from this node have been explored
   * @returns True if all possible moves have been explored
   */
  isFullyExpanded(): boolean {
    // Lazily initialize unexplored moves
    if (this.unexploredMoves === null) {
      this.unexploredMoves = this.getAllAvailableMoves();
    }

    return this.unexploredMoves.length === 0;
  }

  /**
   * Select the best child node according to the UCB1 formula
   * @param explorationParameter The exploration parameter in the UCB1 formula
   * @returns The best child node
   */
  selectBestChild(explorationParameter: number): MCTSNode {
    if (this.children.length === 0) {
      throw new Error("Cannot select child from node with no children");
    }

    let bestChild: MCTSNode | null = null;
    let bestUCB = -Infinity;

    for (const child of this.children) {
      // Calculate UCB1 value for this child
      const exploitation = child.score / child.visits;
      const exploration = Math.sqrt((2 * Math.log(this.visits)) / child.visits);
      const ucb = exploitation + explorationParameter * exploration;

      if (ucb > bestUCB) {
        bestUCB = ucb;
        bestChild = child;
      }
    }

    if (bestChild === null) {
      // This should never happen since we check for empty children array above
      // But TypeScript needs this check
      throw new Error("Failed to select a child node");
    }

    return bestChild;
  }

  /**
   * Get a random unexplored move from this node
   * @returns A random unexplored move, or null if none exists
   */
  getRandomUnexploredMove(): { fromPosition: Position; move: IMove } | null {
    // Lazily initialize unexplored moves
    if (this.unexploredMoves === null) {
      this.unexploredMoves = this.getAllAvailableMoves();
    }

    if (this.unexploredMoves.length === 0) {
      return null;
    }

    const randomIndex = Math.floor(Math.random() * this.unexploredMoves.length);
    const move = this.unexploredMoves[randomIndex];

    // Remove the selected move from unexplored moves
    this.unexploredMoves.splice(randomIndex, 1);

    return move;
  }

  /**
   * Add a child node for the given move
   * @param fromPosition Source position of the move
   * @param move The move to apply
   * @returns The newly created child node
   */
  addChild(fromPosition: Position, move: IMove): MCTSNode {
    // Clone the current state
    const clonedState = this.cloneState();

    // Apply the move to the cloned state
    const gameStateManager = new GameStateManager();
    gameStateManager.setGameState(clonedState);
    const movementManager = new MovementManager(gameStateManager);

    const pieceToMove = this.getPieceAt(fromPosition, clonedState);
    if (!pieceToMove) {
      throw new Error("No piece found at position");
    }

    movementManager.makeMove(
      fromPosition,
      move.position,
      pieceToMove,
      move.toPiece,
      move.moveType,
      move.sideEffects
    );

    // Change turns
    gameStateManager.changeTurns();

    // Create a new child node with the updated state
    const childNode = new MCTSNode(
      this,
      fromPosition,
      move,
      gameStateManager.getGameState()
    );

    // Add to children list
    this.children.push(childNode);

    return childNode;
  }

  /**
   * Create a deep clone of the current game state
   * @returns A new GameState object that is a deep clone of the current state
   */
  cloneState(): GameState {
    // Deep clone the board
    const clonedBoard: ISquare[][] = [];

    for (let row = 0; row < this.state.board.length; row++) {
      clonedBoard[row] = [];
      for (let col = 0; col < this.state.board[row].length; col++) {
        const originalSquare = this.state.board[row][col];
        const clonedSquare: ISquare = { Pieces: [] };

        // Clone each piece in the square
        for (const originalPiece of originalSquare.Pieces) {
          const clonedPiece = this.clonePiece(originalPiece);
          clonedSquare.Pieces.push(clonedPiece);
        }

        clonedBoard[row][col] = clonedSquare;
      }
    }

    // Clone the rest of the state
    return {
      mode: this.state.mode,
      board: clonedBoard,
      state: this.state.state,
      lightKing: this.state.lightKing,
      darkKing: this.state.darkKing,
      lightPlayer: this.state.lightPlayer,
      darkPlayer: this.state.darkPlayer,
      isLightTurn: this.state.isLightTurn,
      turnCount: this.state.turnCount,
      lightWon: this.state.lightWon,
    };
  }

  /**
   * Create a clone of a piece
   * @param originalPiece The piece to clone
   * @returns A new piece with the same properties
   */
  clonePiece(originalPiece: IPiece): IPiece {
    // Create a new piece with the same type and light/dark assignment
    const piece = new Piece(originalPiece.type, originalPiece.isLight);

    // Copy all properties
    piece.isKing = originalPiece.isKing;
    piece.isPoisoned = originalPiece.isPoisoned;
    piece.isZombified = originalPiece.isZombified;
    piece.isFainted = originalPiece.isFainted;
    piece.isEntangled = originalPiece.isEntangled;
    piece.isShadowed = originalPiece.isShadowed;
    piece.isInfected = originalPiece.isInfected;
    piece.direction = originalPiece.direction;
    piece.hasCharged = originalPiece.hasCharged;
    piece.hasMoved = originalPiece.hasMoved;
    piece.hasShed = originalPiece.hasShed;
    piece.hasLilyJumped = originalPiece.hasLilyJumped;
    piece.isMeadowed = originalPiece.isMeadowed;
    piece.isRiding = originalPiece.isRiding;
    piece.isHousing = originalPiece.isHousing;
    piece.isLone = originalPiece.isLone;
    piece.isDormant = originalPiece.isDormant;

    return piece;
  }

  /**
   * Increment the visit count for this node
   */
  incrementVisits(): void {
    this.visits++;
  }

  /**
   * Update the score for this node
   * @param result The result of the simulation
   */
  updateScore(result: number): void {
    this.score += result;
  }

  /**
   * Get all available moves from the current state
   * @returns An array of all available moves with their positions
   */
  private getAllAvailableMoves(): { fromPosition: Position; move: IMove }[] {
    const allMoves: { fromPosition: Position; move: IMove }[] = [];
    const isLightTurn = this.state.isLightTurn;

    // Iterate through all squares on the board
    for (let row = 0; row < this.state.board.length; row++) {
      for (let col = 0; col < this.state.board[row].length; col++) {
        const position: Position = { row, col };
        const pieces = this.state.board[row][col].Pieces;

        // For each piece on this square
        for (const piece of pieces) {
          // Only consider pieces of the current player
          if (piece.isLight === isLightTurn) {
            // Get valid moves for this piece
            let validMoves = piece.getValidMovesForPiece(
              this.state.board,
              position
            );

            // Apply basic filtering
            validMoves = validMoves.filter((move) => {
              // Filter out move type 11 (not implemented correctly in the game engine)
              if (move.moveType === MoveType.rotate) {
                return false;
              }

              // Skip goat faint moves - prevent AI from fainting goats
              if (move.moveType === MoveType.faint && !isLightTurn) {
                return false;
              }

              // ADDITIONAL SAFETY: Always block lane moves for Rhinos
              if (piece.type === "Rhino" && move.moveType === MoveType.lane) {
                return false;
              }

              // ADDITIONAL SAFETY: Block horizontal movement for Rhinos
              if (piece.type === "Rhino") {
                const rowDiff = Math.abs(move.position.row - position.row);
                const colDiff = Math.abs(move.position.col - position.col);
                if (rowDiff === 0 && colDiff > 0) {
                  return false;
                }
              }

              return true;
            });

            // Add all valid moves to the list
            for (const move of validMoves) {
              allMoves.push({
                fromPosition: position,
                move: move,
              });
            }
          }
        }
      }
    }

    return allMoves;
  }

  /**
   * Get all pieces on the board
   * @returns An array of all pieces
   */
  private getAllPieces(): IPiece[] {
    const allPieces: IPiece[] = [];

    for (let row = 0; row < this.state.board.length; row++) {
      for (let col = 0; col < this.state.board[row].length; col++) {
        allPieces.push(...this.state.board[row][col].Pieces);
      }
    }

    return allPieces;
  }

  /**
   * Helper function to get the piece at a given position
   * @param position The position to check
   * @param state The game state
   * @returns The piece at the specified position, or null if none exists
   */
  private getPieceAt(position: Position, state: GameState): IPiece | null {
    const pieces = state.board[position.row][position.col].Pieces;
    return pieces.length > 0 ? pieces[0] : null;
  }
}
