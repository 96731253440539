import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './HowToPlay.module.css';
import { PieceType } from '../../services/zodiacEngine/types';
import './font.css';

interface SectionInfo {
  id: string;
  title: string;
  icon?: string;
  isPiece?: boolean;
}

interface TutorialImage {
  src: string;
  alt: string;
  description?: string;
}

// Volume states enum
enum VolumeState {
  Off = 'off',
  Mid = 'mid',
  High = 'high'
}

function HowToPlay() {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);
  const [volumeState, setVolumeState] = useState<VolumeState>(VolumeState.Off);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [randomSkyImage, setRandomSkyImage] = useState<string>('/images/skytree.png');
  const [animationDelay, setAnimationDelay] = useState<number>(0);
  const [moveDirection, setMoveDirection] = useState<string>('up');
  const [noRotateImage, setNoRotateImage] = useState<boolean>(false);

  // List of available sky images
  const skyImages = [
    '/images/skybear.png',
    '/images/skyfrog.png',
    '/images/skygoat.png',
    '/images/skytree.png',
    '/images/skywolf.png',
    '/images/skycrane.png',
    '/images/skyrhino.png',
    '/images/skysnake.png',
    '/images/skyvirus.png',
    '/images/skymonkey.png',
    '/images/skyoctopus.png',
    '/images/skypenguin.png',
    '/images/skymushroom.png'
  ];

  // Images that should not be rotated
  const noRotateImages = [
    '/images/skywolf.png', 
    '/images/skybear.png',
    '/images/skygoat.png',
    '/images/skymonkey.png',
    '/images/skyoctopus.png',
    '/images/skypenguin.png'
  ];

  // Initialize audio and select random image on component mount
  useEffect(() => {
    // Initialize audio
    if (audioRef.current) {
      audioRef.current.volume = 0.6; // Medium volume
      // Don't autoplay the audio
    }
    
    // Select random sky image
    const randomImageIndex = Math.floor(Math.random() * skyImages.length);
    const selectedImage = skyImages[randomImageIndex];
    setRandomSkyImage(selectedImage);
    
    // Check if it's an image that should not be rotated
    setNoRotateImage(noRotateImages.includes(selectedImage));
    
    // Randomly determine if the image should move up or down
    const direction = Math.random() > 0.5 ? 'up' : 'down';
    setMoveDirection(direction);
    
    // Generate a random starting position based on direction
    // For upward movement: start between -75% and 0%
    // For downward movement: start between -150% and -75%
    let randomStartPosition;
    if (direction === 'up') {
      randomStartPosition = Math.floor(Math.random() * 75) * -1; // Between -75% and 0%
    } else {
      randomStartPosition = Math.floor(Math.random() * 75 + 75) * -1; // Between -150% and -75%
    }
    setAnimationDelay(randomStartPosition);
  }, []);

  // Function to manually set the sky image
  const setSkyImage = (imageName: string) => {
    const fullPath = `/images/${imageName}.png`;
    setRandomSkyImage(fullPath);
    setNoRotateImage(noRotateImages.includes(fullPath));
    
    // Randomly determine if the image should move up or down
    const direction = Math.random() > 0.5 ? 'up' : 'down';
    setMoveDirection(direction);
    
    // Generate a random starting position based on direction
    // For upward movement: start between -75% and 0%
    // For downward movement: start between -150% and -75%
    let randomStartPosition;
    if (direction === 'up') {
      randomStartPosition = Math.floor(Math.random() * 75) * -1; // Between -75% and 0%
    } else {
      randomStartPosition = Math.floor(Math.random() * 75 + 75) * -1; // Between -150% and -75%
    }
    setAnimationDelay(randomStartPosition);
  };

  // Function to get the appropriate CSS class for the image
  const getImageClass = () => {
    if (randomSkyImage === '/images/skyfrog.png') {
      return styles.transparentImageFrog;
    } else if (randomSkyImage === '/images/skypenguin.png') {
      return styles.transparentImagePenguin;
    } else if (randomSkyImage === '/images/skymonkey.png') {
      return styles.transparentImageMonkey;
    } else if (noRotateImages.includes(randomSkyImage)) {
      return styles.transparentImageNoRotate;
    } else {
      return styles.transparentImage;
    }
  };

  // Map of piece types to their tutorial images
  const tutorialImages: Record<string, TutorialImage[]> = {
    [PieceType.Bear]: [
      { 
        src: '/Tutorial images/bear1 copy.png', 
        alt: 'Bear tutorial 1',
        description: "Regular movement - The Bear can move one square in any direction"
      },
      { 
        src: '/Tutorial images/bear2 copy.png', 
        alt: 'Bear tutorial 2',
        description: "The Bear's ability \"Savage\" allows it to capture any piece regardless of defensive abilities"
      },
      { 
        src: '/Tutorial images/bear3 copy.png', 
        alt: 'Bear tutorial 3',
        description: "The bear is a \"large\" piece and a \"predator\" piece meaning many of the other Zodiacs cannot capture it!"
      },
    ],
    [PieceType.Crane]: [
      { 
        src: '/Tutorial images/crane1 copy.png', 
        alt: 'Crane tutorial 1',
        description: "The Crane can move 1 or 2 in any straight line for its regular movement"
      },
      { 
        src: '/Tutorial images/crane2 copy.png', 
        alt: 'Crane tutorial 2',
        description: "The Crane cannot capture any 'large' or 'predator' pieces"
      },
    ],
    [PieceType.Frog]: [
      { 
        src: '/Tutorial images/frog6 copy.png', 
        alt: 'Frog tutorial 1',
        description: "The frog can jump 2 on diagonals for its regular movement, even over other pieces!"
      },
      { 
        src: '/Tutorial images/frog7 copy.png', 
        alt: 'Frog tutorial 2',
        description: "If the frog's regular movement is blocked by a wall or a piece it cannot capture, its regular movement is shortened to 1 square diagonally"
      },
      { 
        src: '/Tutorial images/frog8 copy.png', 
        alt: 'Frog tutorial 3',
        description: "If the frog has yet to make a move in the game, it is on its 'lily pad' and can move to any of the first 3 ranks as a defensive movement"
      },
      { 
        src: '/Tutorial images/frog9 copy.png', 
        alt: 'Frog tutorial 4',
        description: "If a piece captures a frog, it becomes poisoned and cannot make any move for its next available turn"
      },
      { 
        src: '/Tutorial images/frog10 copy.png', 
        alt: 'Frog tutorial 5',
        description: "The frogs special movement is 'wall climb' if a frog is beside the edge of the board, it can also move one up or down"
      },
    ],
    [PieceType.Fungi]: [
      { 
        src: '/Tutorial images/mush1 copy.png', 
        alt: 'Fungi tutorial 1',
        description: "The Fungi can move one in any direction for its regular movement"
      },
      { 
        src: '/Tutorial images/mush2 copy.png', 
        alt: 'Fungi tutorial 2',
        description: "The fungi's ability 'meadow' prevents a tree from moving if it is within one square of the fungi and does not allow a tree to move within one square of the enemy fungi"
      },
      { 
        src: '/Tutorial images/mush3 copy.png', 
        alt: 'Fungi tutorial 3',
        description: "When captured the fungi applies a 'zombie' effect to any animal piece that captured it"
      },
      { 
        src: '/Tutorial images/mush4 copy.png', 
        alt: 'Fungi tutorial 4',
        description: "While zombified, the fungi controls the piece for the following turn and acts as the piece, meaning if the fungi was your king, you can still win by race victory or capturing the enemy king within that round"
      },
    ],
    [PieceType.Goat]: [
      { 
        src: '/Tutorial images/goat1 copy.png', 
        alt: 'Goat tutorial 1',
        description: "The goat can move one in any direction for its regular movement"
      },
      { 
        src: '/Tutorial images/goat2 copy.png', 
        alt: 'Goat tutorial 2',
        description: "The goats ability 'traverse' allows it to hop across linked pieces and then move, it cannot capture any piece on a traverse"
      },
      { 
        src: '/Tutorial images/goat3 copy.png', 
        alt: 'Goat tutorial 3',
        description: "The goat cannot capture any 'predators'"
      },
      { 
        src: '/Tutorial images/goat4.png', 
        alt: 'Goat tutorial 4',
        description: "The goat can use a special ability called 'faint' to make itself appear dead"
      },
      { 
        src: '/Tutorial images/goat5.png', 
        alt: 'Goat tutorial 5',
        description: "While fainted, the goat is unable to be captured by most pieces but cannot move if another piece is 'standing' on it (on the same square)"
      },
    ],
    [PieceType.Monkey]: [
      { 
        src: '/Tutorial images/monkey1.png', 
        alt: 'Monkey tutorial 1',
        description: "The monkey can move one in any direction for its regular movement"
      },
      { 
        src: '/Tutorial images/monkey2.png', 
        alt: 'Monkey tutorial 2',
        description: "The monkey can 'leap' over friendly and enemy pieces like a checkers piece if the formation allows it"
      },
      { 
        src: '/Tutorial images/monkey3.png', 
        alt: 'Monkey tutorial 3',
        description: "If the monkey does leap over an enemy piece it must roll a dice to not be captured! One danger point per enemy piece it leaps in a single line"
      },
      { 
        src: '/Tutorial images/monkey4.png', 
        alt: 'Monkey tutorial 4',
        description: "The monkey CAN capture a bear if it decides to gamble to attack it, on a 1-4 roll on a six sided dice, the Monkey is captured, on a 5 or 6, the Monkey captures the bear"
      },
      { 
        src: '/Tutorial images/monkey5.png', 
        alt: 'Monkey tutorial 5',
        description: "The monkey has two abilities - 'swing' and 'consume' on a swing (a leap ending over a tree) the monkey gains an extra movement, on a consume of any fungi, the monkey also gains one extra movement before its turns end"
      },
    ],
    [PieceType.Octopus]: [
      { 
        src: '/Tutorial images/octo1 copy.png', 
        alt: 'Octopus tutorial 1',
        description: "The octopus can move one in any direction for its regular movement"
      },
      { 
        src: '/Tutorial images/octo2 copy.png', 
        alt: 'Octopus tutorial 2',
        description: "The octopus may only capture on orthagonal squares"
      },
      { 
        src: '/Tutorial images/octo3 copy.png', 
        alt: 'Octopus tutorial 3',
        description: "The octopus can use its ability 'entangle' on any animal piece on diagonal squares"
      },
      { 
        src: '/Tutorial images/octo4 copy.png', 
        alt: 'Octopus tutorial 4',
        description: "The octopus may make any move AND entangle in one turn"
      },
      { 
        src: '/Tutorial images/octo5 copy.png', 
        alt: 'Octopus tutorial 5',
        description: "The octopus has an ability called 'ink' with this ability it can move to any orthagonal square and place 'shadows' on the other 3 orthagonal squares if they are not occupied or off of the board. To the opponent for the round following the 'ink' ability the shadows and real octopus are indistinguishable"
      },
    ],
    [PieceType.Penguin]: [
      { 
        src: '/Tutorial images/peng1 copy.png', 
        alt: 'Penguin tutorial 1',
        description: "The penguin has an ability called 'rotate' it is the only piece that has a set direction and may use the 'rotate' move to redirect its direction for its turn"
      },
      { 
        src: '/Tutorial images/peng2 copy.png', 
        alt: 'Penguin tutorial 2',
        description: "For the penguins regular movement 'slide' it must move to the furthest point in the direction it is facing and once there, it may use a rotate before its turns end to redirect its direction"
      },
      { 
        src: '/Tutorial images/peng4 copy.png', 
        alt: 'Penguin tutorial 3',
        description: "The penguin cannot capture any 'large' or 'predator' pieces. It can capture any other piece within one square of itself and use a rotate ability after the capture"
      },
    ],
    [PieceType.Rhino]: [
      { 
        src: '/Tutorial images/rhino1 copy.png', 
        alt: 'Rhino tutorial 1',
        description: "The rhino can move 'back left', 'back right' or two forward as part of its regular movement"
      },
      { 
        src: '/Tutorial images/rhino2 copy.png', 
        alt: 'Rhino tutorial 2',
        description: "The rhino has an ability called 'trample' where captures ALL pieces in the path of its movement"
      },
      { 
        src: '/Tutorial images/rhino3 copy.png', 
        alt: 'Rhino tutorial 3',
        description: "Once per game a rhino may perform a charge of 3 squares ahead if any animal is present in any of the 3 squares directly infront of the rhino"
      },
      { 
        src: '/Tutorial images/rhino4 copy.png', 
        alt: 'Rhino tutorial 4',
        description: "The rhino has a special ability called \"rhino defence\" where the rhino cannot be captured from 'the front'"
      },
      { 
        src: '/Tutorial images/rhino5 copy.png', 
        alt: 'Rhino tutorial 5',
        description: "The rhino has an ability called 'lane' where if it has not moved and the friendly piece directly to its left or right has not moved, it can switch places with that piece for its turn"
      },
    ],
    [PieceType.Snake]: [
      { 
        src: '/Tutorial images/snake1 copy.png', 
        alt: 'Snake tutorial 1',
        description: "The snake can move 2 squares only in orthagonal directions, including through other pieces for its regular movement"
      },
      { 
        src: '/Tutorial images/snake2 copy.png', 
        alt: 'Snake tutorial 2',
        description: "If its regular movement is blocked by a wall or a piece it cannot capture, its regular movement becomes 1"
      },
      { 
        src: '/Tutorial images/snake3 copy.png', 
        alt: 'Snake tutorial 3',
        description: "The snake has an ability called \"strike\" where if an animal is exactly 3 squares ahead of it and there are no pieces between, it can 'strike' forward and capture that animal"
      },
      { 
        src: '/Tutorial images/snake4 copy.png', 
        alt: 'Snake tutorial 4',
        description: "The snake has an ability called \"shed\" where once per game if the snake is captured, it will return to the first rank of the board, in the same column, as if it had a second life. If the first rank is not a valid square for the snake to move to, it is permanently captured"
      },
    ],
    [PieceType.Tree]: [
      { 
        src: '/Tutorial images/tree1 copy.png', 
        alt: 'Tree tutorial 1',
        description: "The tree may move one forward or unlimitedly side to side if not blocked by any other piece"
      },
      { 
        src: '/Tutorial images/tree2.png', 
        alt: 'Tree tutorial 2',
        description: "The tree has a special ability called 'house' where any piece that can 'climb' tree's may sit on top of the tree piece. If an enemy piece attacks a tree with a housed piece, the housed piece is captured and the tree houses the enemy piece"
      },
      { 
        src: '/Tutorial images/tree3.png', 
        alt: 'Tree tutorial 3',
        description: "When a piece is housed in the tree, if an enemy piece cannot climb the tree, both the tree and the housed piece are protected from capture"
      },
    ],
    [PieceType.Wolf]: [
      { 
        src: '/Tutorial images/wolf1 copy.png', 
        alt: 'Wolf tutorial 1',
        description: "The wolf moves in an L shape like a knight in chess for its regular movement"
      },
      { 
        src: '/Tutorial images/wolf2 copy.png', 
        alt: 'Wolf tutorial 2',
        description: "If the wolf is the last piece on the board* it's ability 'lone wolf' is activated and it moves in a long L instead as pictured above. *The wolf will enter lone if on a team with a fainted goat or infecting virus"
      },
    ],
    [PieceType.Virus]: [
      { 
        src: '/Tutorial images/para1 copy.png', 
        alt: 'Virus tutorial 1',
        description: "The virus may move 2 squares in any straight line for its regular movement, it does not capture but uses its ability 'infect' to cause the piece to be infected rather than captured"
      },
      { 
        src: '/Tutorial images/para2 copy.png', 
        alt: 'Virus tutorial 2',
        description: "The virus can only move to any animal piece, if blocked by a wall, the virus's regular movement is only 1 square"
      },
      { 
        src: '/Tutorial images/para3 copy.png', 
        alt: 'Virus tutorial 3',
        description: "When an animal piece is 'infected' if the virus leaves the infected piece in to another piece, the piece it left is captured and the new piece is 'infected'"
      },
      { 
        src: '/Tutorial images/para6 copy.png', 
        alt: 'Virus tutorial 4',
        description: "The virus has an ability called 'infectious' which allows the virus to complete as many valid moves in one move as it likes, capturing multiple animals in one turn"
      },
      { 
        src: '/Tutorial images/para7 copy.png', 
        alt: 'Virus tutorial 5',
        description: "While not 'infecting' the virus can be captured like any other piece, if an animal captures an infected piece, it becomes infected"
      },
    ],
  };

  const sections: SectionInfo[] = [
    { id: 'what-is-zodiac', title: 'What is?', icon: '/Icons/light1.png' },
    { id: 'setup', title: 'Setup', icon: '/Icons/abilities.png' },
    { id: 'basic-rules', title: 'Rules', icon: '/Icons/rules.png' },
    { id: PieceType.Bear, title: 'Bear', icon: '/Icons/Bear-light.png', isPiece: true },
    { id: PieceType.Crane, title: 'Crane', icon: '/Icons/Crane-light.png', isPiece: true },
    { id: PieceType.Frog, title: 'Frog', icon: '/Icons/Frog-light.png', isPiece: true },
    { id: PieceType.Fungi, title: 'Fungi', icon: '/Icons/Fungi-light.png', isPiece: true },
    { id: PieceType.Goat, title: 'Goat', icon: '/Icons/Goat-light.png', isPiece: true },
    { id: PieceType.Monkey, title: 'Monkey', icon: '/Icons/Monkey-light.png', isPiece: true },
    { id: PieceType.Octopus, title: 'Octopus', icon: '/Icons/Octopus-light.png', isPiece: true },
    { id: PieceType.Penguin, title: 'Penguin', icon: '/Icons/Penguin-light.png', isPiece: true },
    { id: PieceType.Rhino, title: 'Rhino', icon: '/Icons/Rhino-light.png', isPiece: true },
    { id: PieceType.Snake, title: 'Snake', icon: '/Icons/Snake-light.png', isPiece: true },
    { id: PieceType.Tree, title: 'Tree', icon: '/Icons/Tree-light.png', isPiece: true },
    { id: PieceType.Wolf, title: 'Wolf', icon: '/Icons/Wolf-light.png', isPiece: true },
    { id: PieceType.Virus, title: 'Virus', icon: '/Icons/Virus-light.png', isPiece: true },
  ];

  const toggleSection = (sectionId: string) => {
    if (activeSection === sectionId) {
      setActiveSection(null);
      setHoveredIcon(null);
    } else {
      setActiveSection(sectionId);
      setCurrentImageIndex(0);
      setHoveredIcon(null);
    }
  };

  const nextImage = () => {
    if (activeSection && tutorialImages[activeSection]) {
      const images = tutorialImages[activeSection];
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = () => {
    if (activeSection && tutorialImages[activeSection]) {
      const images = tutorialImages[activeSection];
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }
  };

  const renderImageCarousel = () => {
    if (!activeSection || !tutorialImages[activeSection]) return null;
    
    const images = tutorialImages[activeSection];
    if (images.length === 0) return null;

    const currentImage = images[currentImageIndex];

    return (
      <div className={styles.carouselContainer} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '0 auto',
        maxWidth: '800px'
      }}>
        <button 
          className={`${styles.carouselButton} ${styles.prevButton}`}
          onClick={prevImage}
          aria-label="Previous image"
          style={{ flex: '0 0 auto' }}
        >
          <img 
            src="/arrow.png?v=1" 
            alt="Previous" 
            className={styles.arrowLeft}
          />
        </button>
        
        <div className={styles.carouselImageContainer} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '80%',
          margin: '0 auto',
          flex: '1 1 auto'
        }}>
          <img 
            src={currentImage.src} 
            alt={currentImage.alt} 
            className={styles.carouselImage}
            style={{ 
              maxWidth: '100%', 
              maxHeight: '40vh',
              display: 'block',
              margin: '0 auto',
              objectFit: 'contain',
              opacity: 0.7
            }}
          />
        </div>
        
        <button 
          className={`${styles.carouselButton} ${styles.nextButton}`}
          onClick={nextImage}
          aria-label="Next image"
          style={{ flex: '0 0 auto' }}
        >
          <img 
            src="/arrow.png?v=1" 
            alt="Next" 
            className={styles.arrowRight}
          />
        </button>
      </div>
    );
  };

  const getCurrentImageDescription = () => {
    if (!activeSection || !tutorialImages[activeSection]) return '';
    
    const images = tutorialImages[activeSection];
    if (images.length === 0) return '';

    const currentImage = images[currentImageIndex];
    return currentImage.description || '';
  };

  // Function to cycle through volume states
  const cycleVolumeState = () => {
    if (volumeState === VolumeState.Off) {
      setVolumeState(VolumeState.Mid);
      if (audioRef.current) {
        audioRef.current.volume = 0.3; // Low volume
        audioRef.current.play().catch(error => {
          console.error("Audio playback failed:", error);
        });
      }
    } else if (volumeState === VolumeState.Mid) {
      setVolumeState(VolumeState.High);
      if (audioRef.current) {
        audioRef.current.volume = 0.6; // Medium volume
        audioRef.current.play().catch(error => {
          console.error("Audio playback failed:", error);
        });
      }
    } else {
      setVolumeState(VolumeState.Off);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  };

  // Get volume icon based on current state
  const getVolumeIcon = () => {
    switch (volumeState) {
      case VolumeState.Off:
        return '/Icons/0vol.png';
      case VolumeState.Mid:
        return '/Icons/midvol.png';
      case VolumeState.High:
        return '/Icons/highvol.png';
      default:
        return '/Icons/0vol.png';
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <img src="/images/zodiaclog.png" alt="Zodiac Logo" className={styles.logoImage} />
          <div className={styles.headerControls}>
            <button 
              className={styles.volumeButton} 
              onClick={cycleVolumeState}
              aria-label={`Volume: ${volumeState}`}
            >
              <img 
                src={getVolumeIcon()} 
                alt={`Volume: ${volumeState}`} 
                className={styles.volumeIcon} 
              />
            </button>
            <Link to="/" className={styles.backButton} style={{ marginTop: '16px' }}>BACK</Link>
          </div>
          <audio ref={audioRef} src="/zodiacfinal2.mp3" loop />
        </div>
      </div>
      
      <div className={styles.container} style={{ 
        height: '80vh', // Fixed height for consistency across all pages
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {/* Large transparent background image */}
        <div className={styles.largeBackgroundImage} style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          overflow: 'hidden'
        }}>
          <img 
            src={randomSkyImage} 
            alt="" 
            className={getImageClass()} 
            style={{ 
              top: `${animationDelay}%`, // Use the random position as a top percentage
              animationPlayState: 'running',
              animationDirection: moveDirection === 'up' ? 'normal' : 'reverse' // Set animation direction based on moveDirection
            }}
          />
        </div>
        
        {!activeSection && (
          <div className={`${styles.centerText} ${!hoveredIcon && styles.defaultCenterText}`}>
            {hoveredIcon || ""}
          </div>
        )}
        <div className={styles.content} style={{ 
          height: '100%', // Ensure content takes full height of container
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
          {activeSection ? (
            <div className={styles.mainContent} style={
              activeSection === 'what-is-zodiac' || 
              activeSection === 'setup' || 
              activeSection === 'basic-rules' ||
              sections.find(s => s.id === activeSection)?.isPiece
                ? { 
                    backgroundColor: 'transparent', 
                    boxShadow: 'none',
                    height: '70vh', // Fixed height for consistency
                    overflowY: sections.find(s => s.id === activeSection)?.isPiece ? 'visible' : 'auto', // Only add scrolling for non-piece sections
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  } 
                : {
                    height: '70vh', // Fixed height for consistency
                    overflowY: 'auto', // Add scrolling for content that exceeds the height
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
            }>
              <div className={styles.sectionContent} style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                {sections.find(s => s.id === activeSection)?.isPiece ? (
                  <div className={styles.pieceSection} style={{ 
                    position: 'relative', 
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    maxWidth: '900px', // Added max width to control size
                    margin: '0 auto', // Center the section
                    textAlign: 'center'
                  }}>
                    <div className={styles.pieceHeader} style={{ 
                      width: '100%', 
                      textAlign: 'center',
                      marginBottom: '30px' // Added more space before carousel
                    }}>
                      <h2 style={{ 
                        fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif", 
                        color: 'rgb(0, 180, 255)',
                        textShadow: '0 0 10px rgba(0, 180, 255, 0.5)',
                        width: '100%',
                        textAlign: 'center',
                        margin: '0 auto'
                      }}>{sections.find(s => s.id === activeSection)?.title}</h2>
                    </div>
                    
                    <div style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      gap: '20px', // Increased gap for better spacing
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      {renderImageCarousel()}
                      
                      <div className={styles.pieceDescription} style={{
                        position: 'static',
                        margin: '10px auto 20px auto', // Changed from -10px to 10px top margin
                        width: '80%', // Reduced from 90% to 80% for better readability
                        padding: '15px', // Increased padding
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '8px',
                        boxSizing: 'border-box',
                        maxHeight: 'none',
                        bottom: 'auto',
                        left: 'auto',
                        right: 'auto',
                        textAlign: 'center',
                        height: '120px', // Fixed height to prevent image movement
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto' // Allow scrolling if text is too long
                      }}>
                        <p style={{
                          margin: 0,
                          textAlign: 'center',
                          fontWeight: 500,
                          color: 'white',
                          fontSize: '1rem',
                          lineHeight: 1.4
                        }}>{getCurrentImageDescription()}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeSection !== 'what-is-zodiac' && 
                     activeSection !== 'setup' && 
                     activeSection !== 'basic-rules' && (
                      <h2>{sections.find(s => s.id === activeSection)?.title}</h2>
                    )}
                    {activeSection === 'about' && (
                      <div className={styles.aboutSection}>
                        <p>Zodiac is a strategic board game where each piece has unique movement patterns and abilities.</p>
                        <p>The game combines elements of chess and other classic board games with innovative mechanics.</p>
                        <p>More information about Zodiac will be added here.</p>
                      </div>
                    )}
                    {activeSection === 'what-is-zodiac' && (
                      <>
                        <h2 style={{ 
                          fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif", 
                          color: 'rgb(0, 180, 255)',
                          textShadow: '0 0 10px rgba(0, 180, 255, 0.5)',
                          textAlign: 'center',
                          width: '100%'
                        }}>What is it about?</h2>
                        <div className={styles.aboutSection} style={{ 
                          backgroundColor: 'transparent', 
                          boxShadow: 'none', 
                          padding: '40px 0',
                          fontFamily: "'Futur', sans-serif",
                          width: '80%',
                          maxWidth: '800px',
                          margin: '0 auto',
                          textAlign: 'center'
                        }}>
                          <p>Zodiac is a two player abstract strategy game based off of Chess, Poker and DotA.</p>
                          <p>It was created in 2014 as a crude version of itself called "Shadow Chess" based off its central principal of a secret king</p>
                          <p>Later in 2018 it was redesigned to involve a roster of novel pieces with unique movement patterns and abilities</p>
                          <p>Zodiac's goal is to celebrate human intuition and novel play. To drive success toward a players ability to bluff and adapt to unfamiliar situations and create a nuanced set of challenges for the ever growing strength of AI</p>
                          <p>Another goal of Zodiac is to be a game for humans! With a very large search space for play, hidden information, win obfuscation and a changing roster of pieces over time. Zodiac wants to be a player data driven game that keeps creativity mainstay and staves off AI dominance</p>
                          <p>We hope you stick around and see where this journey takes us</p>
                        </div>
                      </>
                    )}
                    {activeSection === 'setup' && (
                      <>
                        <h2 style={{ 
                          fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif", 
                          color: 'rgb(0, 180, 255)',
                          textShadow: '0 0 10px rgba(0, 180, 255, 0.5)',
                          textAlign: 'center',
                          width: '100%'
                        }}>Game Setup</h2>
                        <div className={styles.aboutSection} style={{ 
                          backgroundColor: 'transparent', 
                          boxShadow: 'none', 
                          padding: '90px 0',
                          fontFamily: "'Futur', sans-serif",
                          width: '80%',
                          maxWidth: '800px',
                          margin: '0 auto',
                          textAlign: 'center'
                        }}>
                          <p>Players play a zodiac version of paper, scissors, rock to see who goes first!</p>
                          <p>From there, select a piece to be your "King" your opponent won't know which one you have chosen.</p>
                          <p>Then pick 5 pieces to play with, they all have different abilities, cohesion, strengths and weaknesses!</p>
                          <p>Once you are both done the game will begin.</p>
                          <p>Simply click on a piece to see where it can move to or click on any unhighlighted square to deselect the piece.</p>
                          <p><strong>Good luck!</strong></p>
                        </div>
                      </>
                    )}
                    {activeSection === 'basic-rules' && (
                      <>
                        <h2 style={{ 
                          fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif", 
                          color: 'rgb(0, 180, 255)',
                          textShadow: '0 0 10px rgba(0, 180, 255, 0.5)',
                          textAlign: 'center',
                          width: '100%'
                        }}>Basic Rules</h2>
                        <div className={styles.aboutSection} style={{ 
                          backgroundColor: 'transparent', 
                          boxShadow: 'none', 
                          padding: '10px 0',
                          fontFamily: "'Futur', sans-serif",
                          width: '80%',
                          maxWidth: '800px',
                          margin: '0 auto',
                          textAlign: 'center'
                        }}>
                          <p>The game of Zodiac is played by two players on a rectangle board made up of 40 squares (5 by 8).</p>
                          
                          <h3 style={{ 
                            color: 'rgb(0, 180, 255)', 
                            marginTop: '70px',
                            fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif",
                            textAlign: 'center'
                          }}>To win:</h3>
                          <ul style={{ 
                            paddingLeft: '0', 
                            listStylePosition: 'inside',
                            listStyleType: 'none'
                          }}>
                            <li>Capture the opponents King</li>
                            <li>"Race" your King across the board</li>
                            <li>Have your opponents timer expire</li>
                            <li>Have your opponent surrender</li>
                          </ul>
                          
                          <h3 style={{ 
                            color: 'rgb(0, 180, 255)', 
                            marginTop: '20px',
                            fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif",
                            textAlign: 'center'
                          }}>A draw occurs</h3>
                          <ul style={{ 
                            paddingLeft: '0', 
                            listStylePosition: 'inside',
                            listStyleType: 'none'
                          }}>
                            <li>When a players king is still uncaptured and they cannot make any legal move, or;</li>
                            <li>The same board position is repeated three times</li>
                          </ul>

                          <div style={{
                            position: 'absolute',
                            bottom: '-20px',
                            right: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }}>
                            <a 
                              href="/Comprehensive Rule Book (1).pdf" 
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'white',
                                transition: 'all 0.3s'
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.1)';
                                e.currentTarget.style.filter = 'drop-shadow(0 0 10px rgba(0, 180, 255, 0.7))';
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                                e.currentTarget.style.filter = 'drop-shadow(0 0 5px rgba(0, 120, 255, 0.5))';
                              }}
                            >
                              <img 
                                src="/Icons/rules.png" 
                                alt="Full Rules" 
                                style={{
                                  width: '64px',
                                  height: '64px',
                                  marginBottom: '10px',
                                  filter: 'drop-shadow(0 0 5px rgba(0, 120, 255, 0.5))'
                                }}
                              />
                              <span style={{
                                fontFamily: "'LOWBATT', 'Impact', 'Arial Black', sans-serif",
                                fontSize: '1.2rem',
                                textShadow: '0 0 5px rgba(0, 180, 255, 0.5)'
                              }}>
                                Full Rules
                              </span>
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                    {activeSection !== 'about' && activeSection !== 'what-is-zodiac' && activeSection !== 'basic-rules' && activeSection !== 'setup' && (
                      <p>Information about {sections.find(s => s.id === activeSection)?.title} will be added here.</p>
                    )}
                  </>
                )}
              </div>
              <button 
                className={styles.backToIconsButton}
                onClick={() => setActiveSection(null)}
                style={{
                  position: 'absolute',
                  bottom: '0px',
                  left: '-0px',
                  transform: 'none'
                }}
              >
                BACK
              </button>
            </div>
          ) : (
            <div className={styles.iconsContainer} style={{ 
              height: '70vh', // Match the height of mainContent
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <div className={styles.pieceGrid}>
                {sections.filter(s => !s.isPiece).map(section => (
                  <div 
                    key={section.id} 
                    className={styles.pieceCard} 
                    onClick={() => toggleSection(section.id)}
                    onMouseEnter={() => setHoveredIcon(section.title.toUpperCase())}
                    onMouseLeave={() => setHoveredIcon(null)}
                  >
                    <img src={section.icon} alt={section.title} className={styles.pieceCardIcon} />
                  </div>
                ))}
                
                {sections.filter(s => s.isPiece && s.id !== PieceType.Virus).map(piece => (
                  <div 
                    key={piece.id} 
                    className={styles.pieceCard} 
                    onClick={() => toggleSection(piece.id)}
                    onMouseEnter={() => setHoveredIcon(piece.title.toUpperCase())}
                    onMouseLeave={() => setHoveredIcon(null)}
                  >
                    <img src={piece.icon} alt={piece.title} className={styles.pieceCardIcon} />
                  </div>
                ))}
                
                {sections.filter(s => s.id === PieceType.Virus).map(piece => (
                  <div 
                    key={piece.id} 
                    className={styles.pieceCard} 
                    onClick={() => toggleSection(piece.id)}
                    onMouseEnter={() => setHoveredIcon(piece.title.toUpperCase())}
                    onMouseLeave={() => setHoveredIcon(null)}
                  >
                    <img src={piece.icon} alt={piece.title} className={styles.pieceCardIcon} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HowToPlay;