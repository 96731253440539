import { UUID } from "crypto";

export interface ISquare {
  Pieces: IPiece[];
}

// TODO could we use the type system to prevent off board coords altogether
export interface Position {
  row: number;
  col: number;
}

export enum PieceType {
  Bear = "Bear",
  Crane = "Crane",
  Frog = "Frog",
  Fungi = "Fungi",
  Goat = "Goat",
  Monkey = "Monkey",
  Octopus = "Octopus",
  Penguin = "Penguin",
  Rhino = "Rhino",
  Snake = "Snake",
  Tree = "Tree",
  Wolf = "Wolf",
  Virus = "Virus",
}

export type Direction =
  | "up"
  | "up-right"
  | "right"
  | "down-right"
  | "down"
  | "down-left"
  | "left"
  | "up-left";

export interface IPiece {
  id: UUID;
  type: PieceType;
  isLight: boolean;
  isKing: boolean;
  isPoisoned: boolean;
  isZombified: boolean;
  isFainted: boolean;
  isEntangled: boolean;
  isShadowed: boolean;
  isInfected: boolean;
  direction: Direction | null;
  hasCharged: boolean;
  hasMoved: boolean;
  hasShed: boolean;
  hasLilyJumped: boolean;
  isMeadowed: boolean;
  isRiding: boolean;
  isHousing: boolean;
  isLone: boolean;
  isDormant: boolean;
  entangledPieceId?: UUID;
  getValidMovesForPiece(boardState: ISquare[][], position: Position): IMove[];
  movementStrategy: PieceMovement;
}

export interface IMove {
  id: UUID;
  position: Position;
  toPiece: IPiece | null;
  moveType: MoveType;
  sideEffects: ISideEffect[];
}

export interface ISideEffect {
  id: UUID;
  moveId: UUID;
  position: Position;
  extrapositions?: Position[];
  pieces: IPiece[];
  effect: Effect;
}

export enum Effect {
  capture = "Capture",
  meadow = "Meadow",
  entangle = "Entangle",
  housemove = "House Move",
  infect = "Infect",
  respawn = "Respawn",
}

export enum MoveType {
  regular = "Regular",
  capture = "Capture",
  //simpleentangle,
  moveentangle = "Move Entangle",
  splitentangle = "Split Entangle",
  tripleentangle = "Triple Entangle",
  ink = "Ink",
  house = "House",
  traverse = "Traverse",
  faint = "Faint",
  jumppad = "Jump Pad",
  lane = "Lane",
  kong = "Kong",
  swing = "Swing",
  rotate = "Rotate",
  trample = "Trample",
  infect = "Infect",
  leap = "Leap",
}

export interface GameState {
  mode: Mode;
  board: ISquare[][];
  state: State;
  lightKing?: PieceType;
  darkKing?: PieceType;
  lightPlayer?: string;
  darkPlayer?: string;
  isLightTurn: boolean;
  turnCount: number;
  lightWon?: boolean;
}

export enum Mode {
  Live = "Live",
  Dev = "Dev",
  AI = "AI",
}

export enum State {
  None = "None",
  Searching = "Searching",
  KingSelection = "King Selection",
  PieceSelection = "Piece Selection",
  Playing = "Playing",
  PostGame = "Post Game",
  Terminated = "Terminated",
}

export type GameStateListener = () => void;

export interface PieceMovement {
  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[];

  //canCapture(piece: IPiece, target: IPiece): boolean;
}
