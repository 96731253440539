import { allPieceTypes, animalPieceTypes } from "../const";
import {
  IPiece,
  PieceMovement,
  Position,
  PieceType,
  IMove,
  MoveType,
  ISquare,
  ISideEffect,
  Effect,
} from "../types";
import { BaseMovement } from "./BaseMovement";
import { Move } from "../Move";
import { SideEffect } from "../SideEffect";

const orthogonalDirections = [
  [-1, 0], // Up
  [0, 1], // Right
  [1, 0], // Down
  [0, -1], // Left
];

const diagonalDirections = [
  [-1, -1], // Top Left
  [-1, 1], // Top Right
  [1, 1], // Bottom Right
  [1, -1], // Bottom Left
];

export class OctopusMovement extends BaseMovement implements PieceMovement {
  capturablePieceTypes: PieceType[] = allPieceTypes;
  entanglablePieceTypes: PieceType[] = animalPieceTypes;

  getValidMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const regularMoves = this.getValidRegularMoves(piece, position, boardState);
    const entangleMoves = this.getValidEntangleMoves(
      piece,
      position,
      boardState
    );
    const inkMove = this.getValidInkMove(piece, position);

    console.log("Regular Moves:", regularMoves);
    console.log("Entangle Moves:", entangleMoves);
    console.log("Ink Move:", inkMove);

    return [...regularMoves, ...entangleMoves, ...inkMove];
  }

  private getValidRegularMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Check all surrounding squares
    for (const direction of diagonalDirections) {
      const toPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, toPosition)) continue;

      const toSquare = boardState[toPosition.row][toPosition.col];

      // Check if the target square has multiple pieces (potentially a tree housing another piece)
      if (toSquare.Pieces.length > 1) {
        // Check if one of the pieces is a tree
        const hasTree = toSquare.Pieces.some((p) => p.type === PieceType.Tree);

        if (hasTree) {
          // Find the non-tree piece in the square
          const housedPiece = toSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );

          if (housedPiece) {
            if (housedPiece.isLight === piece.isLight) {
              // If the housed piece is friendly, skip this move
              continue;
            } else {
              // If the housed piece is an enemy, create a capture move
              possibleMoves.push(
                new Move(toPosition, MoveType.capture, housedPiece)
              );
              continue;
            }
          }
        }
      }

      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo?.isEmpty) {
        const move = new Move(toPosition, MoveType.regular);

        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    // Check orthogonal squares for capturable enemy pieces
    for (const direction of orthogonalDirections) {
      const toPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, toPosition)) continue;

      const toSquare = boardState[toPosition.row][toPosition.col];

      // Check if the target square has multiple pieces (potentially a tree housing another piece)
      if (toSquare.Pieces.length > 1) {
        // Check if one of the pieces is a tree
        const hasTree = toSquare.Pieces.some((p) => p.type === PieceType.Tree);

        if (hasTree) {
          // Find the non-tree piece in the square
          const housedPiece = toSquare.Pieces.find(
            (p) => p.type !== PieceType.Tree
          );

          if (housedPiece) {
            if (housedPiece.isLight === piece.isLight) {
              // If the housed piece is friendly, skip this move
              continue;
            } else {
              // If the housed piece is an enemy, create a capture move
              possibleMoves.push(
                new Move(toPosition, MoveType.capture, housedPiece)
              );
              continue;
            }
          }
        }
      }

      const squareInfo = this.getSquareInfo(toSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = new Move(
          toPosition,
          MoveType.capture,
          squareInfo.basicSquareInfo.piece
        );
        if (move) {
          possibleMoves.push(move);
        }
      }
    }

    return possibleMoves;
  }

  private getValidEntangleMoves(
    piece: IPiece,
    position: Position,
    boardState: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Collect the four diagonal positions from the current position
    const diagonalPositions = diagonalDirections
      .map((direction) => ({
        col: position.col + direction[0],
        row: position.row + direction[1],
      }))
      .filter((newPosition) => this.onBoard(boardState, newPosition));

    // Direct entanglement (from current position)
    for (const direction of diagonalDirections) {
      const newPosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, newPosition)) continue;

      const newSquare = boardState[newPosition.row][newPosition.col];
      const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

      if (
        squareInfo.basicSquareInfo &&
        squareInfo.basicSquareInfo.piece &&
        squareInfo.basicSquareInfo.isEnemyOccupied &&
        this.entanglablePieceTypes.includes(
          squareInfo.basicSquareInfo.piece.type
        )
      ) {
        var move = new Move(newPosition, MoveType.regular, newSquare.Pieces[0]);
        move.addSideEffects(
          diagonalPositions.map((pos) => {
            const square = boardState[pos.row][pos.col];
            return new SideEffect(move.id, pos, square.Pieces, Effect.entangle);
          })
        );
        possibleMoves.push(move);
      }
    }

    // Indirect entanglement (knight's moves and 2-step diagonals)

    // Define knight's moves
    const knightMoves = [
      [2, 1],
      [1, 2],
      [-1, 2],
      [-2, 1],
      [-2, -1],
      [-1, -2],
      [1, -2],
      [2, -1],
    ];

    // Define two-step diagonal moves
    const twoStepDiagonalDirections = [
      [2, 2],
      [-2, 2],
      [2, -2],
      [-2, -2],
    ];

    // Combine knight's moves and two-step diagonals
    const allEntangleDirections = [
      ...knightMoves,
      ...twoStepDiagonalDirections,
    ];

    for (const direction of allEntangleDirections) {
      const entanglePosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, entanglePosition)) continue;

      // Determine toPosition based on the type of move
      let toPosition: Position | null = null;

      if (Math.abs(direction[0]) === 2 && Math.abs(direction[1]) === 2) {
        // Two-step diagonal moves
        toPosition = {
          col: position.col + direction[0] / 2,
          row: position.row + direction[1] / 2,
        };
      } else if (Math.abs(direction[0]) === 2 && Math.abs(direction[1]) === 1) {
        // Left or right knight moves
        if (direction[0] === -2) {
          // Left knight moves
          toPosition = {
            col: position.col - 1,
            row: position.row,
          };
        } else if (direction[0] === 2) {
          // Right knight moves
          toPosition = {
            col: position.col + 1,
            row: position.row,
          };
        }
      } else if (Math.abs(direction[0]) === 1 && Math.abs(direction[1]) === 2) {
        // Up or down knight moves
        if (direction[1] === -2) {
          // Up knight moves
          toPosition = {
            col: position.col,
            row: position.row - 1,
          };
        } else if (direction[1] === 2) {
          // Down knight moves
          toPosition = {
            col: position.col,
            row: position.row + 1,
          };
        }
      }

      // If toPosition couldn't be determined, skip this move
      if (!toPosition || !this.onBoard(boardState, toPosition)) continue;

      const toSquare = boardState[toPosition.row][toPosition.col];

      // If toSquare is occupied, skip this move
      console.log("To square", toSquare);
      if (toSquare.Pieces.length > 0) continue;

      const entangleSquare =
        boardState[entanglePosition.row][entanglePosition.col];
      const entangleSquareInfo = this.getSquareInfo(
        entangleSquare,
        piece.isLight
      );

      if (
        entangleSquareInfo.basicSquareInfo &&
        entangleSquareInfo.basicSquareInfo.piece &&
        entangleSquareInfo.basicSquareInfo.isEnemyOccupied &&
        this.entanglablePieceTypes.includes(
          entangleSquareInfo.basicSquareInfo.piece.type
        )
      ) {
        var move = new Move(
          entanglePosition,
          MoveType.moveentangle,
          entangleSquare.Pieces[0]
        );
        // Create sideEffects if needed
        const sideEffects: ISideEffect[] = [];
        sideEffects.push(
          new SideEffect(move.id, toPosition, toSquare.Pieces, Effect.entangle)
        );

        possibleMoves.push(move);
      }
    }

    // Split entanglement (2 steps in cardinal directions)
    const splitMoveDirections = [
      [0, 2], // down
      [0, -2], // up
      [2, 0], // right
      [-2, 0], // left
    ];

    for (const direction of splitMoveDirections) {
      const entanglePosition = {
        col: position.col + direction[0],
        row: position.row + direction[1],
      };

      if (!this.onBoard(boardState, entanglePosition)) continue;

      const entangleSquare =
        boardState[entanglePosition.row][entanglePosition.col];
      const entangleSquareInfo = this.getSquareInfo(
        entangleSquare,
        piece.isLight
      );

      if (
        entangleSquareInfo.basicSquareInfo &&
        entangleSquareInfo.basicSquareInfo.piece &&
        entangleSquareInfo.basicSquareInfo.isEnemyOccupied &&
        this.entanglablePieceTypes.includes(
          entangleSquareInfo.basicSquareInfo.piece.type
        )
      ) {
        // Compute midPosition
        const midPosition = {
          col: position.col + direction[0] / 2,
          row: position.row + direction[1] / 2,
        };

        if (!this.onBoard(boardState, midPosition)) continue;

        // Determine perpendicular sideEffect positions
        const sideEffectPositions: Position[] = [];

        if (direction[0] === 0) {
          // Vertical movement, side effects are to the left and right of midPosition
          const leftPosition = {
            col: midPosition.col - 1,
            row: midPosition.row,
          };
          const rightPosition = {
            col: midPosition.col + 1,
            row: midPosition.row,
          };

          if (this.onBoard(boardState, leftPosition))
            sideEffectPositions.push(leftPosition);
          if (this.onBoard(boardState, rightPosition))
            sideEffectPositions.push(rightPosition);
        } else if (direction[1] === 0) {
          // Horizontal movement, side effects are above and below midPosition
          const upPosition = {
            col: midPosition.col,
            row: midPosition.row - 1,
          };
          const downPosition = {
            col: midPosition.col,
            row: midPosition.row + 1,
          };

          if (this.onBoard(boardState, upPosition))
            sideEffectPositions.push(upPosition);
          if (this.onBoard(boardState, downPosition))
            sideEffectPositions.push(downPosition);
        }

        // Ensure we have two sideEffect positions
        if (sideEffectPositions.length < 2) continue;

        var move = new Move(
          entanglePosition,
          MoveType.splitentangle,
          entangleSquare.Pieces[0]
        );

        // Create sideEffects array
        move.addSideEffects(
          sideEffectPositions.map((sidePos) => {
            const sideSquare = boardState[sidePos.row][sidePos.col];
            return new SideEffect(
              move.id,
              sidePos,
              sideSquare.Pieces,
              Effect.entangle
            );
          })
        );

        possibleMoves.push(move);
      }
    }

    return possibleMoves;
  }

  private getValidInkMove(piece: IPiece, position: Position): IMove[] {
    return [new Move(position, MoveType.ink, piece)];
  }
}
