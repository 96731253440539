import { useState, useEffect, useRef, FC } from "react";
import styles from "./GameEndPopover.module.css";
import { Mode } from "../../services/zodiacEngine/types";

interface GameEndPopoverProps {
  isGameOver: boolean;
  hasWon: boolean;
  duration?: number;
  mode?: Mode;
  isNNTestMode?: boolean;
}

const GameEndPopover: FC<GameEndPopoverProps> = ({
  isGameOver,
  hasWon,
  duration = 2000,
  mode,
  isNNTestMode = false,
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  // Use a ref so we can clear the timeout if the user manually closes
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Don't show the popover in Dev mode or NN test mode
    if (isGameOver && mode !== Mode.Dev && !isNNTestMode) {
      setShowPopover(true);

      // Start the auto-close timer
      timerRef.current = setTimeout(() => {
        setShowPopover(false);
      }, duration);

      // Clean up when unmounting or re-running effect
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      };
    }
  }, [isGameOver, duration, mode, isNNTestMode]);

  // Manually close the popover
  const handleClose = () => {
    setShowPopover(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  if (!showPopover) return null;

  return (
    <div className={styles.popover}>
      <button className={styles.closeButton} onClick={handleClose}>
        &times;
      </button>
      <p style={{ margin: 0 }}>{hasWon ? "You win!" : "You Lost :("}</p>
    </div>
  );
};

export default GameEndPopover;
