import { allPieceTypes, largePieceTypes, predatorPieceTypes } from "../const";
import { Move } from "../Move";
import {
  IPiece,
  PieceMovement,
  Position,
  Direction,
  IMove,
  MoveType,
  ISquare,
} from "../types";
import { BaseMovement } from "./BaseMovement";

const invertedDirections: [number, number][] = [
  [1, -1], // up-left
  [1, 0], // up
  [1, 1], // up-right
  [0, -1], // left
  [0, 1], // right
  [-1, -1], // down-left
  [-1, 0], // down
  [-1, 1], // down-right
];

export class PenguinMovement extends BaseMovement implements PieceMovement {
  private directionToIndex: Record<Direction, number> = {
    "up-left": 0,
    up: 1,
    "up-right": 2,
    left: 3,
    right: 4,
    "down-left": 5,
    down: 6,
    "down-right": 7,
  };

  getValidMoves(
    piece: IPiece,
    position: Position,
    board: ISquare[][]
  ): IMove[] {
    const possibleMoves: IMove[] = [];

    // Get the furthest square in the direction the Penguin is facing
    const direction = piece.direction;
    if (direction) {
      this.getValidSlideMove(piece, position, board, possibleMoves, direction);
    }

    // Get any pieces within 1 square that the Penguin can capture
    this.getValidCaptureMoves(piece, position, board, possibleMoves);

    // Add the rotate move
    this.addRotateMove(piece, position, possibleMoves);

    return possibleMoves;
  }

  private getValidSlideMove(
    piece: IPiece,
    position: Position,
    board: ISquare[][],
    possibleMoves: IMove[],
    direction: Direction
  ) {
    const directionIndex = this.directionToIndex[direction];
    const [rowDiff, colDiff] = invertedDirections[directionIndex];
    let newPosition = { ...position };

    while (true) {
      const nextPosition = {
        col: newPosition.col + colDiff,
        row: newPosition.row + rowDiff,
      };

      if (!this.onBoard(board, nextPosition)) {
        break;
      }

      const nextSquare = board[nextPosition.row][nextPosition.col];
      const squareInfo = this.getSquareInfo(nextSquare, piece.isLight);

      if (squareInfo.basicSquareInfo?.piece) {
        break;
      }

      newPosition = nextPosition;
    }

    // Add the slide move to the possible moves array
    const toSquare = board[newPosition.row][newPosition.col];
    const toSquareInfo = this.getSquareInfo(toSquare, piece.isLight);

    if (toSquareInfo.basicSquareInfo) {
      const move = this.getMove(
        position,
        newPosition,
        toSquareInfo.basicSquareInfo,
        piece,
        [] // Penguin can't capture during slide move
      );

      if (move) {
        possibleMoves.push(move);
      }
    }
  }

  private getValidCaptureMoves(
    piece: IPiece,
    position: Position,
    board: ISquare[][],
    possibleMoves: IMove[]
  ) {
    const capturablePieceTypes = allPieceTypes.filter(
      (pieceType) =>
        !largePieceTypes.includes(pieceType) &&
        !predatorPieceTypes.includes(pieceType)
    );

    for (const [rowDiff, colDiff] of invertedDirections) {
      const newPosition = {
        col: position.col + colDiff,
        row: position.row + rowDiff,
      };
      if (!this.onBoard(board, newPosition)) continue;

      const newSquare = board[newPosition.row][newPosition.col];
      const squareInfo = this.getSquareInfo(newSquare, piece.isLight);

      if (squareInfo.basicSquareInfo) {
        const move = this.getMove(
          position,
          newPosition,
          squareInfo.basicSquareInfo,
          piece,
          capturablePieceTypes
        );

        if (move && move.moveType === MoveType.capture) {
          possibleMoves.push(move);
        }
      }
    }
  }

  private addRotateMove(
    piece: IPiece,
    position: Position,
    possibleMoves: IMove[]
  ) {
    if (!piece.isEntangled) {
      possibleMoves.push(new Move(position, MoveType.rotate, piece));
    }
    console.log("Square Info:", possibleMoves);
  }
}
