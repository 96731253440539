import { useState, useEffect, useRef, FC } from "react";
import styles from "./TurnPopover.module.css";
import { Mode, State } from "../../services/zodiacEngine/types";

interface TurnPopoverProps {
  isThisPlayersTurn: boolean;
  mode: Mode;
  state: State;
  duration?: number;
}

const TurnPopover: FC<TurnPopoverProps> = ({
  isThisPlayersTurn,
  mode,
  state,
  duration = 500,
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  // Use a ref so we can clear the timeout if the user manually closes
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const turnChangeStates = [State.PieceSelection, State.Playing];

  useEffect(() => {
    if (
      isThisPlayersTurn &&
      (mode === Mode.Live || mode === Mode.AI) &&
      turnChangeStates.includes(state)
    ) {
      setShowPopover(true);

      // Start the auto-close timer
      timerRef.current = setTimeout(() => {
        setShowPopover(false);
      }, duration);

      // Clean up when unmounting or re-running effect
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
      };
    }
  }, [isThisPlayersTurn, duration, mode, state]);

  // Manually close the popover
  const handleClose = () => {
    setShowPopover(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  if (!showPopover) return null;

  return (
    <div className={styles.popover}>
      {/* Close button */}
      <button className={styles.closeButton} onClick={handleClose}>
        &times;
      </button>
      <p style={{ margin: 0 }}>
        {mode === Mode.AI ? "AI finished! Your turn!" : "Your turn!"}
      </p>
    </div>
  );
};

export default TurnPopover;
